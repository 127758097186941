@-webkit-keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
      touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  -ms-touch-action: none;
      touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
    no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
    no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  -ms-flex: 0 1 auto;
      flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}
.ril__builtinButton:hover {
  opacity: 1;
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__outerAnimating {
  -webkit-animation-name: closeWindow;
          animation-name: closeWindow;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.css.map */
/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
  width: 100%;
}

.responsiveTable td .tdBefore {
  display: none;
}

@media screen and (max-width: 40em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #333;
  }

  .responsiveTable tbody tr {
    border: 1px solid #000;
    padding: .25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: 50% !important;
    text-align: left !important;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left !important;
    font-weight: 600;
  }
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year-container {
  margin: 0.4rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.react-datepicker__year-container-text {
  display: inline-block;
  cursor: pointer;
  flex: 1 0 30%;
  width: 12px;
  padding: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\D7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
          filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E"; }

.fa-abacus:before {
  content: "\F640"; }

.fa-accessible-icon:before {
  content: "\F368"; }

.fa-accusoft:before {
  content: "\F369"; }

.fa-acorn:before {
  content: "\F6AE"; }

.fa-acquisitions-incorporated:before {
  content: "\F6AF"; }

.fa-ad:before {
  content: "\F641"; }

.fa-address-book:before {
  content: "\F2B9"; }

.fa-address-card:before {
  content: "\F2BB"; }

.fa-adjust:before {
  content: "\F042"; }

.fa-adn:before {
  content: "\F170"; }

.fa-adversal:before {
  content: "\F36A"; }

.fa-affiliatetheme:before {
  content: "\F36B"; }

.fa-air-conditioner:before {
  content: "\F8F4"; }

.fa-air-freshener:before {
  content: "\F5D0"; }

.fa-airbnb:before {
  content: "\F834"; }

.fa-alarm-clock:before {
  content: "\F34E"; }

.fa-alarm-exclamation:before {
  content: "\F843"; }

.fa-alarm-plus:before {
  content: "\F844"; }

.fa-alarm-snooze:before {
  content: "\F845"; }

.fa-album:before {
  content: "\F89F"; }

.fa-album-collection:before {
  content: "\F8A0"; }

.fa-algolia:before {
  content: "\F36C"; }

.fa-alicorn:before {
  content: "\F6B0"; }

.fa-alien:before {
  content: "\F8F5"; }

.fa-alien-monster:before {
  content: "\F8F6"; }

.fa-align-center:before {
  content: "\F037"; }

.fa-align-justify:before {
  content: "\F039"; }

.fa-align-left:before {
  content: "\F036"; }

.fa-align-right:before {
  content: "\F038"; }

.fa-align-slash:before {
  content: "\F846"; }

.fa-alipay:before {
  content: "\F642"; }

.fa-allergies:before {
  content: "\F461"; }

.fa-amazon:before {
  content: "\F270"; }

.fa-amazon-pay:before {
  content: "\F42C"; }

.fa-ambulance:before {
  content: "\F0F9"; }

.fa-american-sign-language-interpreting:before {
  content: "\F2A3"; }

.fa-amilia:before {
  content: "\F36D"; }

.fa-amp-guitar:before {
  content: "\F8A1"; }

.fa-analytics:before {
  content: "\F643"; }

.fa-anchor:before {
  content: "\F13D"; }

.fa-android:before {
  content: "\F17B"; }

.fa-angel:before {
  content: "\F779"; }

.fa-angellist:before {
  content: "\F209"; }

.fa-angle-double-down:before {
  content: "\F103"; }

.fa-angle-double-left:before {
  content: "\F100"; }

.fa-angle-double-right:before {
  content: "\F101"; }

.fa-angle-double-up:before {
  content: "\F102"; }

.fa-angle-down:before {
  content: "\F107"; }

.fa-angle-left:before {
  content: "\F104"; }

.fa-angle-right:before {
  content: "\F105"; }

.fa-angle-up:before {
  content: "\F106"; }

.fa-angry:before {
  content: "\F556"; }

.fa-angrycreative:before {
  content: "\F36E"; }

.fa-angular:before {
  content: "\F420"; }

.fa-ankh:before {
  content: "\F644"; }

.fa-app-store:before {
  content: "\F36F"; }

.fa-app-store-ios:before {
  content: "\F370"; }

.fa-apper:before {
  content: "\F371"; }

.fa-apple:before {
  content: "\F179"; }

.fa-apple-alt:before {
  content: "\F5D1"; }

.fa-apple-crate:before {
  content: "\F6B1"; }

.fa-apple-pay:before {
  content: "\F415"; }

.fa-archive:before {
  content: "\F187"; }

.fa-archway:before {
  content: "\F557"; }

.fa-arrow-alt-circle-down:before {
  content: "\F358"; }

.fa-arrow-alt-circle-left:before {
  content: "\F359"; }

.fa-arrow-alt-circle-right:before {
  content: "\F35A"; }

.fa-arrow-alt-circle-up:before {
  content: "\F35B"; }

.fa-arrow-alt-down:before {
  content: "\F354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\F346"; }

.fa-arrow-alt-from-left:before {
  content: "\F347"; }

.fa-arrow-alt-from-right:before {
  content: "\F348"; }

.fa-arrow-alt-from-top:before {
  content: "\F349"; }

.fa-arrow-alt-left:before {
  content: "\F355"; }

.fa-arrow-alt-right:before {
  content: "\F356"; }

.fa-arrow-alt-square-down:before {
  content: "\F350"; }

.fa-arrow-alt-square-left:before {
  content: "\F351"; }

.fa-arrow-alt-square-right:before {
  content: "\F352"; }

.fa-arrow-alt-square-up:before {
  content: "\F353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\F34A"; }

.fa-arrow-alt-to-left:before {
  content: "\F34B"; }

.fa-arrow-alt-to-right:before {
  content: "\F34C"; }

.fa-arrow-alt-to-top:before {
  content: "\F34D"; }

.fa-arrow-alt-up:before {
  content: "\F357"; }

.fa-arrow-circle-down:before {
  content: "\F0AB"; }

.fa-arrow-circle-left:before {
  content: "\F0A8"; }

.fa-arrow-circle-right:before {
  content: "\F0A9"; }

.fa-arrow-circle-up:before {
  content: "\F0AA"; }

.fa-arrow-down:before {
  content: "\F063"; }

.fa-arrow-from-bottom:before {
  content: "\F342"; }

.fa-arrow-from-left:before {
  content: "\F343"; }

.fa-arrow-from-right:before {
  content: "\F344"; }

.fa-arrow-from-top:before {
  content: "\F345"; }

.fa-arrow-left:before {
  content: "\F060"; }

.fa-arrow-right:before {
  content: "\F061"; }

.fa-arrow-square-down:before {
  content: "\F339"; }

.fa-arrow-square-left:before {
  content: "\F33A"; }

.fa-arrow-square-right:before {
  content: "\F33B"; }

.fa-arrow-square-up:before {
  content: "\F33C"; }

.fa-arrow-to-bottom:before {
  content: "\F33D"; }

.fa-arrow-to-left:before {
  content: "\F33E"; }

.fa-arrow-to-right:before {
  content: "\F340"; }

.fa-arrow-to-top:before {
  content: "\F341"; }

.fa-arrow-up:before {
  content: "\F062"; }

.fa-arrows:before {
  content: "\F047"; }

.fa-arrows-alt:before {
  content: "\F0B2"; }

.fa-arrows-alt-h:before {
  content: "\F337"; }

.fa-arrows-alt-v:before {
  content: "\F338"; }

.fa-arrows-h:before {
  content: "\F07E"; }

.fa-arrows-v:before {
  content: "\F07D"; }

.fa-artstation:before {
  content: "\F77A"; }

.fa-assistive-listening-systems:before {
  content: "\F2A2"; }

.fa-asterisk:before {
  content: "\F069"; }

.fa-asymmetrik:before {
  content: "\F372"; }

.fa-at:before {
  content: "\F1FA"; }

.fa-atlas:before {
  content: "\F558"; }

.fa-atlassian:before {
  content: "\F77B"; }

.fa-atom:before {
  content: "\F5D2"; }

.fa-atom-alt:before {
  content: "\F5D3"; }

.fa-audible:before {
  content: "\F373"; }

.fa-audio-description:before {
  content: "\F29E"; }

.fa-autoprefixer:before {
  content: "\F41C"; }

.fa-avianex:before {
  content: "\F374"; }

.fa-aviato:before {
  content: "\F421"; }

.fa-award:before {
  content: "\F559"; }

.fa-aws:before {
  content: "\F375"; }

.fa-axe:before {
  content: "\F6B2"; }

.fa-axe-battle:before {
  content: "\F6B3"; }

.fa-baby:before {
  content: "\F77C"; }

.fa-baby-carriage:before {
  content: "\F77D"; }

.fa-backpack:before {
  content: "\F5D4"; }

.fa-backspace:before {
  content: "\F55A"; }

.fa-backward:before {
  content: "\F04A"; }

.fa-bacon:before {
  content: "\F7E5"; }

.fa-bacteria:before {
  content: "\E059"; }

.fa-bacterium:before {
  content: "\E05A"; }

.fa-badge:before {
  content: "\F335"; }

.fa-badge-check:before {
  content: "\F336"; }

.fa-badge-dollar:before {
  content: "\F645"; }

.fa-badge-percent:before {
  content: "\F646"; }

.fa-badge-sheriff:before {
  content: "\F8A2"; }

.fa-badger-honey:before {
  content: "\F6B4"; }

.fa-bags-shopping:before {
  content: "\F847"; }

.fa-bahai:before {
  content: "\F666"; }

.fa-balance-scale:before {
  content: "\F24E"; }

.fa-balance-scale-left:before {
  content: "\F515"; }

.fa-balance-scale-right:before {
  content: "\F516"; }

.fa-ball-pile:before {
  content: "\F77E"; }

.fa-ballot:before {
  content: "\F732"; }

.fa-ballot-check:before {
  content: "\F733"; }

.fa-ban:before {
  content: "\F05E"; }

.fa-band-aid:before {
  content: "\F462"; }

.fa-bandcamp:before {
  content: "\F2D5"; }

.fa-banjo:before {
  content: "\F8A3"; }

.fa-barcode:before {
  content: "\F02A"; }

.fa-barcode-alt:before {
  content: "\F463"; }

.fa-barcode-read:before {
  content: "\F464"; }

.fa-barcode-scan:before {
  content: "\F465"; }

.fa-bars:before {
  content: "\F0C9"; }

.fa-baseball:before {
  content: "\F432"; }

.fa-baseball-ball:before {
  content: "\F433"; }

.fa-basketball-ball:before {
  content: "\F434"; }

.fa-basketball-hoop:before {
  content: "\F435"; }

.fa-bat:before {
  content: "\F6B5"; }

.fa-bath:before {
  content: "\F2CD"; }

.fa-battery-bolt:before {
  content: "\F376"; }

.fa-battery-empty:before {
  content: "\F244"; }

.fa-battery-full:before {
  content: "\F240"; }

.fa-battery-half:before {
  content: "\F242"; }

.fa-battery-quarter:before {
  content: "\F243"; }

.fa-battery-slash:before {
  content: "\F377"; }

.fa-battery-three-quarters:before {
  content: "\F241"; }

.fa-battle-net:before {
  content: "\F835"; }

.fa-bed:before {
  content: "\F236"; }

.fa-bed-alt:before {
  content: "\F8F7"; }

.fa-bed-bunk:before {
  content: "\F8F8"; }

.fa-bed-empty:before {
  content: "\F8F9"; }

.fa-beer:before {
  content: "\F0FC"; }

.fa-behance:before {
  content: "\F1B4"; }

.fa-behance-square:before {
  content: "\F1B5"; }

.fa-bell:before {
  content: "\F0F3"; }

.fa-bell-exclamation:before {
  content: "\F848"; }

.fa-bell-on:before {
  content: "\F8FA"; }

.fa-bell-plus:before {
  content: "\F849"; }

.fa-bell-school:before {
  content: "\F5D5"; }

.fa-bell-school-slash:before {
  content: "\F5D6"; }

.fa-bell-slash:before {
  content: "\F1F6"; }

.fa-bells:before {
  content: "\F77F"; }

.fa-betamax:before {
  content: "\F8A4"; }

.fa-bezier-curve:before {
  content: "\F55B"; }

.fa-bible:before {
  content: "\F647"; }

.fa-bicycle:before {
  content: "\F206"; }

.fa-biking:before {
  content: "\F84A"; }

.fa-biking-mountain:before {
  content: "\F84B"; }

.fa-bimobject:before {
  content: "\F378"; }

.fa-binoculars:before {
  content: "\F1E5"; }

.fa-biohazard:before {
  content: "\F780"; }

.fa-birthday-cake:before {
  content: "\F1FD"; }

.fa-bitbucket:before {
  content: "\F171"; }

.fa-bitcoin:before {
  content: "\F379"; }

.fa-bity:before {
  content: "\F37A"; }

.fa-black-tie:before {
  content: "\F27E"; }

.fa-blackberry:before {
  content: "\F37B"; }

.fa-blanket:before {
  content: "\F498"; }

.fa-blender:before {
  content: "\F517"; }

.fa-blender-phone:before {
  content: "\F6B6"; }

.fa-blind:before {
  content: "\F29D"; }

.fa-blinds:before {
  content: "\F8FB"; }

.fa-blinds-open:before {
  content: "\F8FC"; }

.fa-blinds-raised:before {
  content: "\F8FD"; }

.fa-blog:before {
  content: "\F781"; }

.fa-blogger:before {
  content: "\F37C"; }

.fa-blogger-b:before {
  content: "\F37D"; }

.fa-bluetooth:before {
  content: "\F293"; }

.fa-bluetooth-b:before {
  content: "\F294"; }

.fa-bold:before {
  content: "\F032"; }

.fa-bolt:before {
  content: "\F0E7"; }

.fa-bomb:before {
  content: "\F1E2"; }

.fa-bone:before {
  content: "\F5D7"; }

.fa-bone-break:before {
  content: "\F5D8"; }

.fa-bong:before {
  content: "\F55C"; }

.fa-book:before {
  content: "\F02D"; }

.fa-book-alt:before {
  content: "\F5D9"; }

.fa-book-dead:before {
  content: "\F6B7"; }

.fa-book-heart:before {
  content: "\F499"; }

.fa-book-medical:before {
  content: "\F7E6"; }

.fa-book-open:before {
  content: "\F518"; }

.fa-book-reader:before {
  content: "\F5DA"; }

.fa-book-spells:before {
  content: "\F6B8"; }

.fa-book-user:before {
  content: "\F7E7"; }

.fa-bookmark:before {
  content: "\F02E"; }

.fa-books:before {
  content: "\F5DB"; }

.fa-books-medical:before {
  content: "\F7E8"; }

.fa-boombox:before {
  content: "\F8A5"; }

.fa-boot:before {
  content: "\F782"; }

.fa-booth-curtain:before {
  content: "\F734"; }

.fa-bootstrap:before {
  content: "\F836"; }

.fa-border-all:before {
  content: "\F84C"; }

.fa-border-bottom:before {
  content: "\F84D"; }

.fa-border-center-h:before {
  content: "\F89C"; }

.fa-border-center-v:before {
  content: "\F89D"; }

.fa-border-inner:before {
  content: "\F84E"; }

.fa-border-left:before {
  content: "\F84F"; }

.fa-border-none:before {
  content: "\F850"; }

.fa-border-outer:before {
  content: "\F851"; }

.fa-border-right:before {
  content: "\F852"; }

.fa-border-style:before {
  content: "\F853"; }

.fa-border-style-alt:before {
  content: "\F854"; }

.fa-border-top:before {
  content: "\F855"; }

.fa-bow-arrow:before {
  content: "\F6B9"; }

.fa-bowling-ball:before {
  content: "\F436"; }

.fa-bowling-pins:before {
  content: "\F437"; }

.fa-box:before {
  content: "\F466"; }

.fa-box-alt:before {
  content: "\F49A"; }

.fa-box-ballot:before {
  content: "\F735"; }

.fa-box-check:before {
  content: "\F467"; }

.fa-box-fragile:before {
  content: "\F49B"; }

.fa-box-full:before {
  content: "\F49C"; }

.fa-box-heart:before {
  content: "\F49D"; }

.fa-box-open:before {
  content: "\F49E"; }

.fa-box-tissue:before {
  content: "\E05B"; }

.fa-box-up:before {
  content: "\F49F"; }

.fa-box-usd:before {
  content: "\F4A0"; }

.fa-boxes:before {
  content: "\F468"; }

.fa-boxes-alt:before {
  content: "\F4A1"; }

.fa-boxing-glove:before {
  content: "\F438"; }

.fa-brackets:before {
  content: "\F7E9"; }

.fa-brackets-curly:before {
  content: "\F7EA"; }

.fa-braille:before {
  content: "\F2A1"; }

.fa-brain:before {
  content: "\F5DC"; }

.fa-bread-loaf:before {
  content: "\F7EB"; }

.fa-bread-slice:before {
  content: "\F7EC"; }

.fa-briefcase:before {
  content: "\F0B1"; }

.fa-briefcase-medical:before {
  content: "\F469"; }

.fa-bring-forward:before {
  content: "\F856"; }

.fa-bring-front:before {
  content: "\F857"; }

.fa-broadcast-tower:before {
  content: "\F519"; }

.fa-broom:before {
  content: "\F51A"; }

.fa-browser:before {
  content: "\F37E"; }

.fa-brush:before {
  content: "\F55D"; }

.fa-btc:before {
  content: "\F15A"; }

.fa-buffer:before {
  content: "\F837"; }

.fa-bug:before {
  content: "\F188"; }

.fa-building:before {
  content: "\F1AD"; }

.fa-bullhorn:before {
  content: "\F0A1"; }

.fa-bullseye:before {
  content: "\F140"; }

.fa-bullseye-arrow:before {
  content: "\F648"; }

.fa-bullseye-pointer:before {
  content: "\F649"; }

.fa-burger-soda:before {
  content: "\F858"; }

.fa-burn:before {
  content: "\F46A"; }

.fa-buromobelexperte:before {
  content: "\F37F"; }

.fa-burrito:before {
  content: "\F7ED"; }

.fa-bus:before {
  content: "\F207"; }

.fa-bus-alt:before {
  content: "\F55E"; }

.fa-bus-school:before {
  content: "\F5DD"; }

.fa-business-time:before {
  content: "\F64A"; }

.fa-buy-n-large:before {
  content: "\F8A6"; }

.fa-buysellads:before {
  content: "\F20D"; }

.fa-cabinet-filing:before {
  content: "\F64B"; }

.fa-cactus:before {
  content: "\F8A7"; }

.fa-calculator:before {
  content: "\F1EC"; }

.fa-calculator-alt:before {
  content: "\F64C"; }

.fa-calendar:before {
  content: "\F133"; }

.fa-calendar-alt:before {
  content: "\F073"; }

.fa-calendar-check:before {
  content: "\F274"; }

.fa-calendar-day:before {
  content: "\F783"; }

.fa-calendar-edit:before {
  content: "\F333"; }

.fa-calendar-exclamation:before {
  content: "\F334"; }

.fa-calendar-minus:before {
  content: "\F272"; }

.fa-calendar-plus:before {
  content: "\F271"; }

.fa-calendar-star:before {
  content: "\F736"; }

.fa-calendar-times:before {
  content: "\F273"; }

.fa-calendar-week:before {
  content: "\F784"; }

.fa-camcorder:before {
  content: "\F8A8"; }

.fa-camera:before {
  content: "\F030"; }

.fa-camera-alt:before {
  content: "\F332"; }

.fa-camera-home:before {
  content: "\F8FE"; }

.fa-camera-movie:before {
  content: "\F8A9"; }

.fa-camera-polaroid:before {
  content: "\F8AA"; }

.fa-camera-retro:before {
  content: "\F083"; }

.fa-campfire:before {
  content: "\F6BA"; }

.fa-campground:before {
  content: "\F6BB"; }

.fa-canadian-maple-leaf:before {
  content: "\F785"; }

.fa-candle-holder:before {
  content: "\F6BC"; }

.fa-candy-cane:before {
  content: "\F786"; }

.fa-candy-corn:before {
  content: "\F6BD"; }

.fa-cannabis:before {
  content: "\F55F"; }

.fa-capsules:before {
  content: "\F46B"; }

.fa-car:before {
  content: "\F1B9"; }

.fa-car-alt:before {
  content: "\F5DE"; }

.fa-car-battery:before {
  content: "\F5DF"; }

.fa-car-building:before {
  content: "\F859"; }

.fa-car-bump:before {
  content: "\F5E0"; }

.fa-car-bus:before {
  content: "\F85A"; }

.fa-car-crash:before {
  content: "\F5E1"; }

.fa-car-garage:before {
  content: "\F5E2"; }

.fa-car-mechanic:before {
  content: "\F5E3"; }

.fa-car-side:before {
  content: "\F5E4"; }

.fa-car-tilt:before {
  content: "\F5E5"; }

.fa-car-wash:before {
  content: "\F5E6"; }

.fa-caravan:before {
  content: "\F8FF"; }

.fa-caravan-alt:before {
  content: "\E000"; }

.fa-caret-circle-down:before {
  content: "\F32D"; }

.fa-caret-circle-left:before {
  content: "\F32E"; }

.fa-caret-circle-right:before {
  content: "\F330"; }

.fa-caret-circle-up:before {
  content: "\F331"; }

.fa-caret-down:before {
  content: "\F0D7"; }

.fa-caret-left:before {
  content: "\F0D9"; }

.fa-caret-right:before {
  content: "\F0DA"; }

.fa-caret-square-down:before {
  content: "\F150"; }

.fa-caret-square-left:before {
  content: "\F191"; }

.fa-caret-square-right:before {
  content: "\F152"; }

.fa-caret-square-up:before {
  content: "\F151"; }

.fa-caret-up:before {
  content: "\F0D8"; }

.fa-carrot:before {
  content: "\F787"; }

.fa-cars:before {
  content: "\F85B"; }

.fa-cart-arrow-down:before {
  content: "\F218"; }

.fa-cart-plus:before {
  content: "\F217"; }

.fa-cash-register:before {
  content: "\F788"; }

.fa-cassette-tape:before {
  content: "\F8AB"; }

.fa-cat:before {
  content: "\F6BE"; }

.fa-cat-space:before {
  content: "\E001"; }

.fa-cauldron:before {
  content: "\F6BF"; }

.fa-cc-amazon-pay:before {
  content: "\F42D"; }

.fa-cc-amex:before {
  content: "\F1F3"; }

.fa-cc-apple-pay:before {
  content: "\F416"; }

.fa-cc-diners-club:before {
  content: "\F24C"; }

.fa-cc-discover:before {
  content: "\F1F2"; }

.fa-cc-jcb:before {
  content: "\F24B"; }

.fa-cc-mastercard:before {
  content: "\F1F1"; }

.fa-cc-paypal:before {
  content: "\F1F4"; }

.fa-cc-stripe:before {
  content: "\F1F5"; }

.fa-cc-visa:before {
  content: "\F1F0"; }

.fa-cctv:before {
  content: "\F8AC"; }

.fa-centercode:before {
  content: "\F380"; }

.fa-centos:before {
  content: "\F789"; }

.fa-certificate:before {
  content: "\F0A3"; }

.fa-chair:before {
  content: "\F6C0"; }

.fa-chair-office:before {
  content: "\F6C1"; }

.fa-chalkboard:before {
  content: "\F51B"; }

.fa-chalkboard-teacher:before {
  content: "\F51C"; }

.fa-charging-station:before {
  content: "\F5E7"; }

.fa-chart-area:before {
  content: "\F1FE"; }

.fa-chart-bar:before {
  content: "\F080"; }

.fa-chart-line:before {
  content: "\F201"; }

.fa-chart-line-down:before {
  content: "\F64D"; }

.fa-chart-network:before {
  content: "\F78A"; }

.fa-chart-pie:before {
  content: "\F200"; }

.fa-chart-pie-alt:before {
  content: "\F64E"; }

.fa-chart-scatter:before {
  content: "\F7EE"; }

.fa-check:before {
  content: "\F00C"; }

.fa-check-circle:before {
  content: "\F058"; }

.fa-check-double:before {
  content: "\F560"; }

.fa-check-square:before {
  content: "\F14A"; }

.fa-cheese:before {
  content: "\F7EF"; }

.fa-cheese-swiss:before {
  content: "\F7F0"; }

.fa-cheeseburger:before {
  content: "\F7F1"; }

.fa-chess:before {
  content: "\F439"; }

.fa-chess-bishop:before {
  content: "\F43A"; }

.fa-chess-bishop-alt:before {
  content: "\F43B"; }

.fa-chess-board:before {
  content: "\F43C"; }

.fa-chess-clock:before {
  content: "\F43D"; }

.fa-chess-clock-alt:before {
  content: "\F43E"; }

.fa-chess-king:before {
  content: "\F43F"; }

.fa-chess-king-alt:before {
  content: "\F440"; }

.fa-chess-knight:before {
  content: "\F441"; }

.fa-chess-knight-alt:before {
  content: "\F442"; }

.fa-chess-pawn:before {
  content: "\F443"; }

.fa-chess-pawn-alt:before {
  content: "\F444"; }

.fa-chess-queen:before {
  content: "\F445"; }

.fa-chess-queen-alt:before {
  content: "\F446"; }

.fa-chess-rook:before {
  content: "\F447"; }

.fa-chess-rook-alt:before {
  content: "\F448"; }

.fa-chevron-circle-down:before {
  content: "\F13A"; }

.fa-chevron-circle-left:before {
  content: "\F137"; }

.fa-chevron-circle-right:before {
  content: "\F138"; }

.fa-chevron-circle-up:before {
  content: "\F139"; }

.fa-chevron-double-down:before {
  content: "\F322"; }

.fa-chevron-double-left:before {
  content: "\F323"; }

.fa-chevron-double-right:before {
  content: "\F324"; }

.fa-chevron-double-up:before {
  content: "\F325"; }

.fa-chevron-down:before {
  content: "\F078"; }

.fa-chevron-left:before {
  content: "\F053"; }

.fa-chevron-right:before {
  content: "\F054"; }

.fa-chevron-square-down:before {
  content: "\F329"; }

.fa-chevron-square-left:before {
  content: "\F32A"; }

.fa-chevron-square-right:before {
  content: "\F32B"; }

.fa-chevron-square-up:before {
  content: "\F32C"; }

.fa-chevron-up:before {
  content: "\F077"; }

.fa-child:before {
  content: "\F1AE"; }

.fa-chimney:before {
  content: "\F78B"; }

.fa-chrome:before {
  content: "\F268"; }

.fa-chromecast:before {
  content: "\F838"; }

.fa-church:before {
  content: "\F51D"; }

.fa-circle:before {
  content: "\F111"; }

.fa-circle-notch:before {
  content: "\F1CE"; }

.fa-city:before {
  content: "\F64F"; }

.fa-clarinet:before {
  content: "\F8AD"; }

.fa-claw-marks:before {
  content: "\F6C2"; }

.fa-clinic-medical:before {
  content: "\F7F2"; }

.fa-clipboard:before {
  content: "\F328"; }

.fa-clipboard-check:before {
  content: "\F46C"; }

.fa-clipboard-list:before {
  content: "\F46D"; }

.fa-clipboard-list-check:before {
  content: "\F737"; }

.fa-clipboard-prescription:before {
  content: "\F5E8"; }

.fa-clipboard-user:before {
  content: "\F7F3"; }

.fa-clock:before {
  content: "\F017"; }

.fa-clone:before {
  content: "\F24D"; }

.fa-closed-captioning:before {
  content: "\F20A"; }

.fa-cloud:before {
  content: "\F0C2"; }

.fa-cloud-download:before {
  content: "\F0ED"; }

.fa-cloud-download-alt:before {
  content: "\F381"; }

.fa-cloud-drizzle:before {
  content: "\F738"; }

.fa-cloud-hail:before {
  content: "\F739"; }

.fa-cloud-hail-mixed:before {
  content: "\F73A"; }

.fa-cloud-meatball:before {
  content: "\F73B"; }

.fa-cloud-moon:before {
  content: "\F6C3"; }

.fa-cloud-moon-rain:before {
  content: "\F73C"; }

.fa-cloud-music:before {
  content: "\F8AE"; }

.fa-cloud-rain:before {
  content: "\F73D"; }

.fa-cloud-rainbow:before {
  content: "\F73E"; }

.fa-cloud-showers:before {
  content: "\F73F"; }

.fa-cloud-showers-heavy:before {
  content: "\F740"; }

.fa-cloud-sleet:before {
  content: "\F741"; }

.fa-cloud-snow:before {
  content: "\F742"; }

.fa-cloud-sun:before {
  content: "\F6C4"; }

.fa-cloud-sun-rain:before {
  content: "\F743"; }

.fa-cloud-upload:before {
  content: "\F0EE"; }

.fa-cloud-upload-alt:before {
  content: "\F382"; }

.fa-cloudflare:before {
  content: "\E07D"; }

.fa-clouds:before {
  content: "\F744"; }

.fa-clouds-moon:before {
  content: "\F745"; }

.fa-clouds-sun:before {
  content: "\F746"; }

.fa-cloudscale:before {
  content: "\F383"; }

.fa-cloudsmith:before {
  content: "\F384"; }

.fa-cloudversify:before {
  content: "\F385"; }

.fa-club:before {
  content: "\F327"; }

.fa-cocktail:before {
  content: "\F561"; }

.fa-code:before {
  content: "\F121"; }

.fa-code-branch:before {
  content: "\F126"; }

.fa-code-commit:before {
  content: "\F386"; }

.fa-code-merge:before {
  content: "\F387"; }

.fa-codepen:before {
  content: "\F1CB"; }

.fa-codiepie:before {
  content: "\F284"; }

.fa-coffee:before {
  content: "\F0F4"; }

.fa-coffee-pot:before {
  content: "\E002"; }

.fa-coffee-togo:before {
  content: "\F6C5"; }

.fa-coffin:before {
  content: "\F6C6"; }

.fa-coffin-cross:before {
  content: "\E051"; }

.fa-cog:before {
  content: "\F013"; }

.fa-cogs:before {
  content: "\F085"; }

.fa-coin:before {
  content: "\F85C"; }

.fa-coins:before {
  content: "\F51E"; }

.fa-columns:before {
  content: "\F0DB"; }

.fa-comet:before {
  content: "\E003"; }

.fa-comment:before {
  content: "\F075"; }

.fa-comment-alt:before {
  content: "\F27A"; }

.fa-comment-alt-check:before {
  content: "\F4A2"; }

.fa-comment-alt-dollar:before {
  content: "\F650"; }

.fa-comment-alt-dots:before {
  content: "\F4A3"; }

.fa-comment-alt-edit:before {
  content: "\F4A4"; }

.fa-comment-alt-exclamation:before {
  content: "\F4A5"; }

.fa-comment-alt-lines:before {
  content: "\F4A6"; }

.fa-comment-alt-medical:before {
  content: "\F7F4"; }

.fa-comment-alt-minus:before {
  content: "\F4A7"; }

.fa-comment-alt-music:before {
  content: "\F8AF"; }

.fa-comment-alt-plus:before {
  content: "\F4A8"; }

.fa-comment-alt-slash:before {
  content: "\F4A9"; }

.fa-comment-alt-smile:before {
  content: "\F4AA"; }

.fa-comment-alt-times:before {
  content: "\F4AB"; }

.fa-comment-check:before {
  content: "\F4AC"; }

.fa-comment-dollar:before {
  content: "\F651"; }

.fa-comment-dots:before {
  content: "\F4AD"; }

.fa-comment-edit:before {
  content: "\F4AE"; }

.fa-comment-exclamation:before {
  content: "\F4AF"; }

.fa-comment-lines:before {
  content: "\F4B0"; }

.fa-comment-medical:before {
  content: "\F7F5"; }

.fa-comment-minus:before {
  content: "\F4B1"; }

.fa-comment-music:before {
  content: "\F8B0"; }

.fa-comment-plus:before {
  content: "\F4B2"; }

.fa-comment-slash:before {
  content: "\F4B3"; }

.fa-comment-smile:before {
  content: "\F4B4"; }

.fa-comment-times:before {
  content: "\F4B5"; }

.fa-comments:before {
  content: "\F086"; }

.fa-comments-alt:before {
  content: "\F4B6"; }

.fa-comments-alt-dollar:before {
  content: "\F652"; }

.fa-comments-dollar:before {
  content: "\F653"; }

.fa-compact-disc:before {
  content: "\F51F"; }

.fa-compass:before {
  content: "\F14E"; }

.fa-compass-slash:before {
  content: "\F5E9"; }

.fa-compress:before {
  content: "\F066"; }

.fa-compress-alt:before {
  content: "\F422"; }

.fa-compress-arrows-alt:before {
  content: "\F78C"; }

.fa-compress-wide:before {
  content: "\F326"; }

.fa-computer-classic:before {
  content: "\F8B1"; }

.fa-computer-speaker:before {
  content: "\F8B2"; }

.fa-concierge-bell:before {
  content: "\F562"; }

.fa-confluence:before {
  content: "\F78D"; }

.fa-connectdevelop:before {
  content: "\F20E"; }

.fa-construction:before {
  content: "\F85D"; }

.fa-container-storage:before {
  content: "\F4B7"; }

.fa-contao:before {
  content: "\F26D"; }

.fa-conveyor-belt:before {
  content: "\F46E"; }

.fa-conveyor-belt-alt:before {
  content: "\F46F"; }

.fa-cookie:before {
  content: "\F563"; }

.fa-cookie-bite:before {
  content: "\F564"; }

.fa-copy:before {
  content: "\F0C5"; }

.fa-copyright:before {
  content: "\F1F9"; }

.fa-corn:before {
  content: "\F6C7"; }

.fa-cotton-bureau:before {
  content: "\F89E"; }

.fa-couch:before {
  content: "\F4B8"; }

.fa-cow:before {
  content: "\F6C8"; }

.fa-cowbell:before {
  content: "\F8B3"; }

.fa-cowbell-more:before {
  content: "\F8B4"; }

.fa-cpanel:before {
  content: "\F388"; }

.fa-creative-commons:before {
  content: "\F25E"; }

.fa-creative-commons-by:before {
  content: "\F4E7"; }

.fa-creative-commons-nc:before {
  content: "\F4E8"; }

.fa-creative-commons-nc-eu:before {
  content: "\F4E9"; }

.fa-creative-commons-nc-jp:before {
  content: "\F4EA"; }

.fa-creative-commons-nd:before {
  content: "\F4EB"; }

.fa-creative-commons-pd:before {
  content: "\F4EC"; }

.fa-creative-commons-pd-alt:before {
  content: "\F4ED"; }

.fa-creative-commons-remix:before {
  content: "\F4EE"; }

.fa-creative-commons-sa:before {
  content: "\F4EF"; }

.fa-creative-commons-sampling:before {
  content: "\F4F0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\F4F1"; }

.fa-creative-commons-share:before {
  content: "\F4F2"; }

.fa-creative-commons-zero:before {
  content: "\F4F3"; }

.fa-credit-card:before {
  content: "\F09D"; }

.fa-credit-card-blank:before {
  content: "\F389"; }

.fa-credit-card-front:before {
  content: "\F38A"; }

.fa-cricket:before {
  content: "\F449"; }

.fa-critical-role:before {
  content: "\F6C9"; }

.fa-croissant:before {
  content: "\F7F6"; }

.fa-crop:before {
  content: "\F125"; }

.fa-crop-alt:before {
  content: "\F565"; }

.fa-cross:before {
  content: "\F654"; }

.fa-crosshairs:before {
  content: "\F05B"; }

.fa-crow:before {
  content: "\F520"; }

.fa-crown:before {
  content: "\F521"; }

.fa-crutch:before {
  content: "\F7F7"; }

.fa-crutches:before {
  content: "\F7F8"; }

.fa-css3:before {
  content: "\F13C"; }

.fa-css3-alt:before {
  content: "\F38B"; }

.fa-cube:before {
  content: "\F1B2"; }

.fa-cubes:before {
  content: "\F1B3"; }

.fa-curling:before {
  content: "\F44A"; }

.fa-cut:before {
  content: "\F0C4"; }

.fa-cuttlefish:before {
  content: "\F38C"; }

.fa-d-and-d:before {
  content: "\F38D"; }

.fa-d-and-d-beyond:before {
  content: "\F6CA"; }

.fa-dagger:before {
  content: "\F6CB"; }

.fa-dailymotion:before {
  content: "\E052"; }

.fa-dashcube:before {
  content: "\F210"; }

.fa-database:before {
  content: "\F1C0"; }

.fa-deaf:before {
  content: "\F2A4"; }

.fa-debug:before {
  content: "\F7F9"; }

.fa-deer:before {
  content: "\F78E"; }

.fa-deer-rudolph:before {
  content: "\F78F"; }

.fa-deezer:before {
  content: "\E077"; }

.fa-delicious:before {
  content: "\F1A5"; }

.fa-democrat:before {
  content: "\F747"; }

.fa-deploydog:before {
  content: "\F38E"; }

.fa-deskpro:before {
  content: "\F38F"; }

.fa-desktop:before {
  content: "\F108"; }

.fa-desktop-alt:before {
  content: "\F390"; }

.fa-dev:before {
  content: "\F6CC"; }

.fa-deviantart:before {
  content: "\F1BD"; }

.fa-dewpoint:before {
  content: "\F748"; }

.fa-dharmachakra:before {
  content: "\F655"; }

.fa-dhl:before {
  content: "\F790"; }

.fa-diagnoses:before {
  content: "\F470"; }

.fa-diamond:before {
  content: "\F219"; }

.fa-diaspora:before {
  content: "\F791"; }

.fa-dice:before {
  content: "\F522"; }

.fa-dice-d10:before {
  content: "\F6CD"; }

.fa-dice-d12:before {
  content: "\F6CE"; }

.fa-dice-d20:before {
  content: "\F6CF"; }

.fa-dice-d4:before {
  content: "\F6D0"; }

.fa-dice-d6:before {
  content: "\F6D1"; }

.fa-dice-d8:before {
  content: "\F6D2"; }

.fa-dice-five:before {
  content: "\F523"; }

.fa-dice-four:before {
  content: "\F524"; }

.fa-dice-one:before {
  content: "\F525"; }

.fa-dice-six:before {
  content: "\F526"; }

.fa-dice-three:before {
  content: "\F527"; }

.fa-dice-two:before {
  content: "\F528"; }

.fa-digg:before {
  content: "\F1A6"; }

.fa-digging:before {
  content: "\F85E"; }

.fa-digital-ocean:before {
  content: "\F391"; }

.fa-digital-tachograph:before {
  content: "\F566"; }

.fa-diploma:before {
  content: "\F5EA"; }

.fa-directions:before {
  content: "\F5EB"; }

.fa-disc-drive:before {
  content: "\F8B5"; }

.fa-discord:before {
  content: "\F392"; }

.fa-discourse:before {
  content: "\F393"; }

.fa-disease:before {
  content: "\F7FA"; }

.fa-divide:before {
  content: "\F529"; }

.fa-dizzy:before {
  content: "\F567"; }

.fa-dna:before {
  content: "\F471"; }

.fa-do-not-enter:before {
  content: "\F5EC"; }

.fa-dochub:before {
  content: "\F394"; }

.fa-docker:before {
  content: "\F395"; }

.fa-dog:before {
  content: "\F6D3"; }

.fa-dog-leashed:before {
  content: "\F6D4"; }

.fa-dollar-sign:before {
  content: "\F155"; }

.fa-dolly:before {
  content: "\F472"; }

.fa-dolly-empty:before {
  content: "\F473"; }

.fa-dolly-flatbed:before {
  content: "\F474"; }

.fa-dolly-flatbed-alt:before {
  content: "\F475"; }

.fa-dolly-flatbed-empty:before {
  content: "\F476"; }

.fa-donate:before {
  content: "\F4B9"; }

.fa-door-closed:before {
  content: "\F52A"; }

.fa-door-open:before {
  content: "\F52B"; }

.fa-dot-circle:before {
  content: "\F192"; }

.fa-dove:before {
  content: "\F4BA"; }

.fa-download:before {
  content: "\F019"; }

.fa-draft2digital:before {
  content: "\F396"; }

.fa-drafting-compass:before {
  content: "\F568"; }

.fa-dragon:before {
  content: "\F6D5"; }

.fa-draw-circle:before {
  content: "\F5ED"; }

.fa-draw-polygon:before {
  content: "\F5EE"; }

.fa-draw-square:before {
  content: "\F5EF"; }

.fa-dreidel:before {
  content: "\F792"; }

.fa-dribbble:before {
  content: "\F17D"; }

.fa-dribbble-square:before {
  content: "\F397"; }

.fa-drone:before {
  content: "\F85F"; }

.fa-drone-alt:before {
  content: "\F860"; }

.fa-dropbox:before {
  content: "\F16B"; }

.fa-drum:before {
  content: "\F569"; }

.fa-drum-steelpan:before {
  content: "\F56A"; }

.fa-drumstick:before {
  content: "\F6D6"; }

.fa-drumstick-bite:before {
  content: "\F6D7"; }

.fa-drupal:before {
  content: "\F1A9"; }

.fa-dryer:before {
  content: "\F861"; }

.fa-dryer-alt:before {
  content: "\F862"; }

.fa-duck:before {
  content: "\F6D8"; }

.fa-dumbbell:before {
  content: "\F44B"; }

.fa-dumpster:before {
  content: "\F793"; }

.fa-dumpster-fire:before {
  content: "\F794"; }

.fa-dungeon:before {
  content: "\F6D9"; }

.fa-dyalog:before {
  content: "\F399"; }

.fa-ear:before {
  content: "\F5F0"; }

.fa-ear-muffs:before {
  content: "\F795"; }

.fa-earlybirds:before {
  content: "\F39A"; }

.fa-ebay:before {
  content: "\F4F4"; }

.fa-eclipse:before {
  content: "\F749"; }

.fa-eclipse-alt:before {
  content: "\F74A"; }

.fa-edge:before {
  content: "\F282"; }

.fa-edge-legacy:before {
  content: "\E078"; }

.fa-edit:before {
  content: "\F044"; }

.fa-egg:before {
  content: "\F7FB"; }

.fa-egg-fried:before {
  content: "\F7FC"; }

.fa-eject:before {
  content: "\F052"; }

.fa-elementor:before {
  content: "\F430"; }

.fa-elephant:before {
  content: "\F6DA"; }

.fa-ellipsis-h:before {
  content: "\F141"; }

.fa-ellipsis-h-alt:before {
  content: "\F39B"; }

.fa-ellipsis-v:before {
  content: "\F142"; }

.fa-ellipsis-v-alt:before {
  content: "\F39C"; }

.fa-ello:before {
  content: "\F5F1"; }

.fa-ember:before {
  content: "\F423"; }

.fa-empire:before {
  content: "\F1D1"; }

.fa-empty-set:before {
  content: "\F656"; }

.fa-engine-warning:before {
  content: "\F5F2"; }

.fa-envelope:before {
  content: "\F0E0"; }

.fa-envelope-open:before {
  content: "\F2B6"; }

.fa-envelope-open-dollar:before {
  content: "\F657"; }

.fa-envelope-open-text:before {
  content: "\F658"; }

.fa-envelope-square:before {
  content: "\F199"; }

.fa-envira:before {
  content: "\F299"; }

.fa-equals:before {
  content: "\F52C"; }

.fa-eraser:before {
  content: "\F12D"; }

.fa-erlang:before {
  content: "\F39D"; }

.fa-ethereum:before {
  content: "\F42E"; }

.fa-ethernet:before {
  content: "\F796"; }

.fa-etsy:before {
  content: "\F2D7"; }

.fa-euro-sign:before {
  content: "\F153"; }

.fa-evernote:before {
  content: "\F839"; }

.fa-exchange:before {
  content: "\F0EC"; }

.fa-exchange-alt:before {
  content: "\F362"; }

.fa-exclamation:before {
  content: "\F12A"; }

.fa-exclamation-circle:before {
  content: "\F06A"; }

.fa-exclamation-square:before {
  content: "\F321"; }

.fa-exclamation-triangle:before {
  content: "\F071"; }

.fa-expand:before {
  content: "\F065"; }

.fa-expand-alt:before {
  content: "\F424"; }

.fa-expand-arrows:before {
  content: "\F31D"; }

.fa-expand-arrows-alt:before {
  content: "\F31E"; }

.fa-expand-wide:before {
  content: "\F320"; }

.fa-expeditedssl:before {
  content: "\F23E"; }

.fa-external-link:before {
  content: "\F08E"; }

.fa-external-link-alt:before {
  content: "\F35D"; }

.fa-external-link-square:before {
  content: "\F14C"; }

.fa-external-link-square-alt:before {
  content: "\F360"; }

.fa-eye:before {
  content: "\F06E"; }

.fa-eye-dropper:before {
  content: "\F1FB"; }

.fa-eye-evil:before {
  content: "\F6DB"; }

.fa-eye-slash:before {
  content: "\F070"; }

.fa-facebook:before {
  content: "\F09A"; }

.fa-facebook-f:before {
  content: "\F39E"; }

.fa-facebook-messenger:before {
  content: "\F39F"; }

.fa-facebook-square:before {
  content: "\F082"; }

.fa-fan:before {
  content: "\F863"; }

.fa-fan-table:before {
  content: "\E004"; }

.fa-fantasy-flight-games:before {
  content: "\F6DC"; }

.fa-farm:before {
  content: "\F864"; }

.fa-fast-backward:before {
  content: "\F049"; }

.fa-fast-forward:before {
  content: "\F050"; }

.fa-faucet:before {
  content: "\E005"; }

.fa-faucet-drip:before {
  content: "\E006"; }

.fa-fax:before {
  content: "\F1AC"; }

.fa-feather:before {
  content: "\F52D"; }

.fa-feather-alt:before {
  content: "\F56B"; }

.fa-fedex:before {
  content: "\F797"; }

.fa-fedora:before {
  content: "\F798"; }

.fa-female:before {
  content: "\F182"; }

.fa-field-hockey:before {
  content: "\F44C"; }

.fa-fighter-jet:before {
  content: "\F0FB"; }

.fa-figma:before {
  content: "\F799"; }

.fa-file:before {
  content: "\F15B"; }

.fa-file-alt:before {
  content: "\F15C"; }

.fa-file-archive:before {
  content: "\F1C6"; }

.fa-file-audio:before {
  content: "\F1C7"; }

.fa-file-certificate:before {
  content: "\F5F3"; }

.fa-file-chart-line:before {
  content: "\F659"; }

.fa-file-chart-pie:before {
  content: "\F65A"; }

.fa-file-check:before {
  content: "\F316"; }

.fa-file-code:before {
  content: "\F1C9"; }

.fa-file-contract:before {
  content: "\F56C"; }

.fa-file-csv:before {
  content: "\F6DD"; }

.fa-file-download:before {
  content: "\F56D"; }

.fa-file-edit:before {
  content: "\F31C"; }

.fa-file-excel:before {
  content: "\F1C3"; }

.fa-file-exclamation:before {
  content: "\F31A"; }

.fa-file-export:before {
  content: "\F56E"; }

.fa-file-image:before {
  content: "\F1C5"; }

.fa-file-import:before {
  content: "\F56F"; }

.fa-file-invoice:before {
  content: "\F570"; }

.fa-file-invoice-dollar:before {
  content: "\F571"; }

.fa-file-medical:before {
  content: "\F477"; }

.fa-file-medical-alt:before {
  content: "\F478"; }

.fa-file-minus:before {
  content: "\F318"; }

.fa-file-music:before {
  content: "\F8B6"; }

.fa-file-pdf:before {
  content: "\F1C1"; }

.fa-file-plus:before {
  content: "\F319"; }

.fa-file-powerpoint:before {
  content: "\F1C4"; }

.fa-file-prescription:before {
  content: "\F572"; }

.fa-file-search:before {
  content: "\F865"; }

.fa-file-signature:before {
  content: "\F573"; }

.fa-file-spreadsheet:before {
  content: "\F65B"; }

.fa-file-times:before {
  content: "\F317"; }

.fa-file-upload:before {
  content: "\F574"; }

.fa-file-user:before {
  content: "\F65C"; }

.fa-file-video:before {
  content: "\F1C8"; }

.fa-file-word:before {
  content: "\F1C2"; }

.fa-files-medical:before {
  content: "\F7FD"; }

.fa-fill:before {
  content: "\F575"; }

.fa-fill-drip:before {
  content: "\F576"; }

.fa-film:before {
  content: "\F008"; }

.fa-film-alt:before {
  content: "\F3A0"; }

.fa-film-canister:before {
  content: "\F8B7"; }

.fa-filter:before {
  content: "\F0B0"; }

.fa-fingerprint:before {
  content: "\F577"; }

.fa-fire:before {
  content: "\F06D"; }

.fa-fire-alt:before {
  content: "\F7E4"; }

.fa-fire-extinguisher:before {
  content: "\F134"; }

.fa-fire-smoke:before {
  content: "\F74B"; }

.fa-firefox:before {
  content: "\F269"; }

.fa-firefox-browser:before {
  content: "\E007"; }

.fa-fireplace:before {
  content: "\F79A"; }

.fa-first-aid:before {
  content: "\F479"; }

.fa-first-order:before {
  content: "\F2B0"; }

.fa-first-order-alt:before {
  content: "\F50A"; }

.fa-firstdraft:before {
  content: "\F3A1"; }

.fa-fish:before {
  content: "\F578"; }

.fa-fish-cooked:before {
  content: "\F7FE"; }

.fa-fist-raised:before {
  content: "\F6DE"; }

.fa-flag:before {
  content: "\F024"; }

.fa-flag-alt:before {
  content: "\F74C"; }

.fa-flag-checkered:before {
  content: "\F11E"; }

.fa-flag-usa:before {
  content: "\F74D"; }

.fa-flame:before {
  content: "\F6DF"; }

.fa-flashlight:before {
  content: "\F8B8"; }

.fa-flask:before {
  content: "\F0C3"; }

.fa-flask-poison:before {
  content: "\F6E0"; }

.fa-flask-potion:before {
  content: "\F6E1"; }

.fa-flickr:before {
  content: "\F16E"; }

.fa-flipboard:before {
  content: "\F44D"; }

.fa-flower:before {
  content: "\F7FF"; }

.fa-flower-daffodil:before {
  content: "\F800"; }

.fa-flower-tulip:before {
  content: "\F801"; }

.fa-flushed:before {
  content: "\F579"; }

.fa-flute:before {
  content: "\F8B9"; }

.fa-flux-capacitor:before {
  content: "\F8BA"; }

.fa-fly:before {
  content: "\F417"; }

.fa-fog:before {
  content: "\F74E"; }

.fa-folder:before {
  content: "\F07B"; }

.fa-folder-download:before {
  content: "\E053"; }

.fa-folder-minus:before {
  content: "\F65D"; }

.fa-folder-open:before {
  content: "\F07C"; }

.fa-folder-plus:before {
  content: "\F65E"; }

.fa-folder-times:before {
  content: "\F65F"; }

.fa-folder-tree:before {
  content: "\F802"; }

.fa-folder-upload:before {
  content: "\E054"; }

.fa-folders:before {
  content: "\F660"; }

.fa-font:before {
  content: "\F031"; }

.fa-font-awesome:before {
  content: "\F2B4"; }

.fa-font-awesome-alt:before {
  content: "\F35C"; }

.fa-font-awesome-flag:before {
  content: "\F425"; }

.fa-font-awesome-logo-full:before {
  content: "\F4E6"; }

.fa-font-case:before {
  content: "\F866"; }

.fa-fonticons:before {
  content: "\F280"; }

.fa-fonticons-fi:before {
  content: "\F3A2"; }

.fa-football-ball:before {
  content: "\F44E"; }

.fa-football-helmet:before {
  content: "\F44F"; }

.fa-forklift:before {
  content: "\F47A"; }

.fa-fort-awesome:before {
  content: "\F286"; }

.fa-fort-awesome-alt:before {
  content: "\F3A3"; }

.fa-forumbee:before {
  content: "\F211"; }

.fa-forward:before {
  content: "\F04E"; }

.fa-foursquare:before {
  content: "\F180"; }

.fa-fragile:before {
  content: "\F4BB"; }

.fa-free-code-camp:before {
  content: "\F2C5"; }

.fa-freebsd:before {
  content: "\F3A4"; }

.fa-french-fries:before {
  content: "\F803"; }

.fa-frog:before {
  content: "\F52E"; }

.fa-frosty-head:before {
  content: "\F79B"; }

.fa-frown:before {
  content: "\F119"; }

.fa-frown-open:before {
  content: "\F57A"; }

.fa-fulcrum:before {
  content: "\F50B"; }

.fa-function:before {
  content: "\F661"; }

.fa-funnel-dollar:before {
  content: "\F662"; }

.fa-futbol:before {
  content: "\F1E3"; }

.fa-galactic-republic:before {
  content: "\F50C"; }

.fa-galactic-senate:before {
  content: "\F50D"; }

.fa-galaxy:before {
  content: "\E008"; }

.fa-game-board:before {
  content: "\F867"; }

.fa-game-board-alt:before {
  content: "\F868"; }

.fa-game-console-handheld:before {
  content: "\F8BB"; }

.fa-gamepad:before {
  content: "\F11B"; }

.fa-gamepad-alt:before {
  content: "\F8BC"; }

.fa-garage:before {
  content: "\E009"; }

.fa-garage-car:before {
  content: "\E00A"; }

.fa-garage-open:before {
  content: "\E00B"; }

.fa-gas-pump:before {
  content: "\F52F"; }

.fa-gas-pump-slash:before {
  content: "\F5F4"; }

.fa-gavel:before {
  content: "\F0E3"; }

.fa-gem:before {
  content: "\F3A5"; }

.fa-genderless:before {
  content: "\F22D"; }

.fa-get-pocket:before {
  content: "\F265"; }

.fa-gg:before {
  content: "\F260"; }

.fa-gg-circle:before {
  content: "\F261"; }

.fa-ghost:before {
  content: "\F6E2"; }

.fa-gift:before {
  content: "\F06B"; }

.fa-gift-card:before {
  content: "\F663"; }

.fa-gifts:before {
  content: "\F79C"; }

.fa-gingerbread-man:before {
  content: "\F79D"; }

.fa-git:before {
  content: "\F1D3"; }

.fa-git-alt:before {
  content: "\F841"; }

.fa-git-square:before {
  content: "\F1D2"; }

.fa-github:before {
  content: "\F09B"; }

.fa-github-alt:before {
  content: "\F113"; }

.fa-github-square:before {
  content: "\F092"; }

.fa-gitkraken:before {
  content: "\F3A6"; }

.fa-gitlab:before {
  content: "\F296"; }

.fa-gitter:before {
  content: "\F426"; }

.fa-glass:before {
  content: "\F804"; }

.fa-glass-champagne:before {
  content: "\F79E"; }

.fa-glass-cheers:before {
  content: "\F79F"; }

.fa-glass-citrus:before {
  content: "\F869"; }

.fa-glass-martini:before {
  content: "\F000"; }

.fa-glass-martini-alt:before {
  content: "\F57B"; }

.fa-glass-whiskey:before {
  content: "\F7A0"; }

.fa-glass-whiskey-rocks:before {
  content: "\F7A1"; }

.fa-glasses:before {
  content: "\F530"; }

.fa-glasses-alt:before {
  content: "\F5F5"; }

.fa-glide:before {
  content: "\F2A5"; }

.fa-glide-g:before {
  content: "\F2A6"; }

.fa-globe:before {
  content: "\F0AC"; }

.fa-globe-africa:before {
  content: "\F57C"; }

.fa-globe-americas:before {
  content: "\F57D"; }

.fa-globe-asia:before {
  content: "\F57E"; }

.fa-globe-europe:before {
  content: "\F7A2"; }

.fa-globe-snow:before {
  content: "\F7A3"; }

.fa-globe-stand:before {
  content: "\F5F6"; }

.fa-gofore:before {
  content: "\F3A7"; }

.fa-golf-ball:before {
  content: "\F450"; }

.fa-golf-club:before {
  content: "\F451"; }

.fa-goodreads:before {
  content: "\F3A8"; }

.fa-goodreads-g:before {
  content: "\F3A9"; }

.fa-google:before {
  content: "\F1A0"; }

.fa-google-drive:before {
  content: "\F3AA"; }

.fa-google-pay:before {
  content: "\E079"; }

.fa-google-play:before {
  content: "\F3AB"; }

.fa-google-plus:before {
  content: "\F2B3"; }

.fa-google-plus-g:before {
  content: "\F0D5"; }

.fa-google-plus-square:before {
  content: "\F0D4"; }

.fa-google-wallet:before {
  content: "\F1EE"; }

.fa-gopuram:before {
  content: "\F664"; }

.fa-graduation-cap:before {
  content: "\F19D"; }

.fa-gramophone:before {
  content: "\F8BD"; }

.fa-gratipay:before {
  content: "\F184"; }

.fa-grav:before {
  content: "\F2D6"; }

.fa-greater-than:before {
  content: "\F531"; }

.fa-greater-than-equal:before {
  content: "\F532"; }

.fa-grimace:before {
  content: "\F57F"; }

.fa-grin:before {
  content: "\F580"; }

.fa-grin-alt:before {
  content: "\F581"; }

.fa-grin-beam:before {
  content: "\F582"; }

.fa-grin-beam-sweat:before {
  content: "\F583"; }

.fa-grin-hearts:before {
  content: "\F584"; }

.fa-grin-squint:before {
  content: "\F585"; }

.fa-grin-squint-tears:before {
  content: "\F586"; }

.fa-grin-stars:before {
  content: "\F587"; }

.fa-grin-tears:before {
  content: "\F588"; }

.fa-grin-tongue:before {
  content: "\F589"; }

.fa-grin-tongue-squint:before {
  content: "\F58A"; }

.fa-grin-tongue-wink:before {
  content: "\F58B"; }

.fa-grin-wink:before {
  content: "\F58C"; }

.fa-grip-horizontal:before {
  content: "\F58D"; }

.fa-grip-lines:before {
  content: "\F7A4"; }

.fa-grip-lines-vertical:before {
  content: "\F7A5"; }

.fa-grip-vertical:before {
  content: "\F58E"; }

.fa-gripfire:before {
  content: "\F3AC"; }

.fa-grunt:before {
  content: "\F3AD"; }

.fa-guilded:before {
  content: "\E07E"; }

.fa-guitar:before {
  content: "\F7A6"; }

.fa-guitar-electric:before {
  content: "\F8BE"; }

.fa-guitars:before {
  content: "\F8BF"; }

.fa-gulp:before {
  content: "\F3AE"; }

.fa-h-square:before {
  content: "\F0FD"; }

.fa-h1:before {
  content: "\F313"; }

.fa-h2:before {
  content: "\F314"; }

.fa-h3:before {
  content: "\F315"; }

.fa-h4:before {
  content: "\F86A"; }

.fa-hacker-news:before {
  content: "\F1D4"; }

.fa-hacker-news-square:before {
  content: "\F3AF"; }

.fa-hackerrank:before {
  content: "\F5F7"; }

.fa-hamburger:before {
  content: "\F805"; }

.fa-hammer:before {
  content: "\F6E3"; }

.fa-hammer-war:before {
  content: "\F6E4"; }

.fa-hamsa:before {
  content: "\F665"; }

.fa-hand-heart:before {
  content: "\F4BC"; }

.fa-hand-holding:before {
  content: "\F4BD"; }

.fa-hand-holding-box:before {
  content: "\F47B"; }

.fa-hand-holding-heart:before {
  content: "\F4BE"; }

.fa-hand-holding-magic:before {
  content: "\F6E5"; }

.fa-hand-holding-medical:before {
  content: "\E05C"; }

.fa-hand-holding-seedling:before {
  content: "\F4BF"; }

.fa-hand-holding-usd:before {
  content: "\F4C0"; }

.fa-hand-holding-water:before {
  content: "\F4C1"; }

.fa-hand-lizard:before {
  content: "\F258"; }

.fa-hand-middle-finger:before {
  content: "\F806"; }

.fa-hand-paper:before {
  content: "\F256"; }

.fa-hand-peace:before {
  content: "\F25B"; }

.fa-hand-point-down:before {
  content: "\F0A7"; }

.fa-hand-point-left:before {
  content: "\F0A5"; }

.fa-hand-point-right:before {
  content: "\F0A4"; }

.fa-hand-point-up:before {
  content: "\F0A6"; }

.fa-hand-pointer:before {
  content: "\F25A"; }

.fa-hand-receiving:before {
  content: "\F47C"; }

.fa-hand-rock:before {
  content: "\F255"; }

.fa-hand-scissors:before {
  content: "\F257"; }

.fa-hand-sparkles:before {
  content: "\E05D"; }

.fa-hand-spock:before {
  content: "\F259"; }

.fa-hands:before {
  content: "\F4C2"; }

.fa-hands-heart:before {
  content: "\F4C3"; }

.fa-hands-helping:before {
  content: "\F4C4"; }

.fa-hands-usd:before {
  content: "\F4C5"; }

.fa-hands-wash:before {
  content: "\E05E"; }

.fa-handshake:before {
  content: "\F2B5"; }

.fa-handshake-alt:before {
  content: "\F4C6"; }

.fa-handshake-alt-slash:before {
  content: "\E05F"; }

.fa-handshake-slash:before {
  content: "\E060"; }

.fa-hanukiah:before {
  content: "\F6E6"; }

.fa-hard-hat:before {
  content: "\F807"; }

.fa-hashtag:before {
  content: "\F292"; }

.fa-hat-chef:before {
  content: "\F86B"; }

.fa-hat-cowboy:before {
  content: "\F8C0"; }

.fa-hat-cowboy-side:before {
  content: "\F8C1"; }

.fa-hat-santa:before {
  content: "\F7A7"; }

.fa-hat-winter:before {
  content: "\F7A8"; }

.fa-hat-witch:before {
  content: "\F6E7"; }

.fa-hat-wizard:before {
  content: "\F6E8"; }

.fa-hdd:before {
  content: "\F0A0"; }

.fa-head-side:before {
  content: "\F6E9"; }

.fa-head-side-brain:before {
  content: "\F808"; }

.fa-head-side-cough:before {
  content: "\E061"; }

.fa-head-side-cough-slash:before {
  content: "\E062"; }

.fa-head-side-headphones:before {
  content: "\F8C2"; }

.fa-head-side-mask:before {
  content: "\E063"; }

.fa-head-side-medical:before {
  content: "\F809"; }

.fa-head-side-virus:before {
  content: "\E064"; }

.fa-head-vr:before {
  content: "\F6EA"; }

.fa-heading:before {
  content: "\F1DC"; }

.fa-headphones:before {
  content: "\F025"; }

.fa-headphones-alt:before {
  content: "\F58F"; }

.fa-headset:before {
  content: "\F590"; }

.fa-heart:before {
  content: "\F004"; }

.fa-heart-broken:before {
  content: "\F7A9"; }

.fa-heart-circle:before {
  content: "\F4C7"; }

.fa-heart-rate:before {
  content: "\F5F8"; }

.fa-heart-square:before {
  content: "\F4C8"; }

.fa-heartbeat:before {
  content: "\F21E"; }

.fa-heat:before {
  content: "\E00C"; }

.fa-helicopter:before {
  content: "\F533"; }

.fa-helmet-battle:before {
  content: "\F6EB"; }

.fa-hexagon:before {
  content: "\F312"; }

.fa-highlighter:before {
  content: "\F591"; }

.fa-hiking:before {
  content: "\F6EC"; }

.fa-hippo:before {
  content: "\F6ED"; }

.fa-hips:before {
  content: "\F452"; }

.fa-hire-a-helper:before {
  content: "\F3B0"; }

.fa-history:before {
  content: "\F1DA"; }

.fa-hive:before {
  content: "\E07F"; }

.fa-hockey-mask:before {
  content: "\F6EE"; }

.fa-hockey-puck:before {
  content: "\F453"; }

.fa-hockey-sticks:before {
  content: "\F454"; }

.fa-holly-berry:before {
  content: "\F7AA"; }

.fa-home:before {
  content: "\F015"; }

.fa-home-alt:before {
  content: "\F80A"; }

.fa-home-heart:before {
  content: "\F4C9"; }

.fa-home-lg:before {
  content: "\F80B"; }

.fa-home-lg-alt:before {
  content: "\F80C"; }

.fa-hood-cloak:before {
  content: "\F6EF"; }

.fa-hooli:before {
  content: "\F427"; }

.fa-horizontal-rule:before {
  content: "\F86C"; }

.fa-hornbill:before {
  content: "\F592"; }

.fa-horse:before {
  content: "\F6F0"; }

.fa-horse-head:before {
  content: "\F7AB"; }

.fa-horse-saddle:before {
  content: "\F8C3"; }

.fa-hospital:before {
  content: "\F0F8"; }

.fa-hospital-alt:before {
  content: "\F47D"; }

.fa-hospital-symbol:before {
  content: "\F47E"; }

.fa-hospital-user:before {
  content: "\F80D"; }

.fa-hospitals:before {
  content: "\F80E"; }

.fa-hot-tub:before {
  content: "\F593"; }

.fa-hotdog:before {
  content: "\F80F"; }

.fa-hotel:before {
  content: "\F594"; }

.fa-hotjar:before {
  content: "\F3B1"; }

.fa-hourglass:before {
  content: "\F254"; }

.fa-hourglass-end:before {
  content: "\F253"; }

.fa-hourglass-half:before {
  content: "\F252"; }

.fa-hourglass-start:before {
  content: "\F251"; }

.fa-house:before {
  content: "\E00D"; }

.fa-house-damage:before {
  content: "\F6F1"; }

.fa-house-day:before {
  content: "\E00E"; }

.fa-house-flood:before {
  content: "\F74F"; }

.fa-house-leave:before {
  content: "\E00F"; }

.fa-house-night:before {
  content: "\E010"; }

.fa-house-return:before {
  content: "\E011"; }

.fa-house-signal:before {
  content: "\E012"; }

.fa-house-user:before {
  content: "\E065"; }

.fa-houzz:before {
  content: "\F27C"; }

.fa-hryvnia:before {
  content: "\F6F2"; }

.fa-html5:before {
  content: "\F13B"; }

.fa-hubspot:before {
  content: "\F3B2"; }

.fa-humidity:before {
  content: "\F750"; }

.fa-hurricane:before {
  content: "\F751"; }

.fa-i-cursor:before {
  content: "\F246"; }

.fa-ice-cream:before {
  content: "\F810"; }

.fa-ice-skate:before {
  content: "\F7AC"; }

.fa-icicles:before {
  content: "\F7AD"; }

.fa-icons:before {
  content: "\F86D"; }

.fa-icons-alt:before {
  content: "\F86E"; }

.fa-id-badge:before {
  content: "\F2C1"; }

.fa-id-card:before {
  content: "\F2C2"; }

.fa-id-card-alt:before {
  content: "\F47F"; }

.fa-ideal:before {
  content: "\E013"; }

.fa-igloo:before {
  content: "\F7AE"; }

.fa-image:before {
  content: "\F03E"; }

.fa-image-polaroid:before {
  content: "\F8C4"; }

.fa-images:before {
  content: "\F302"; }

.fa-imdb:before {
  content: "\F2D8"; }

.fa-inbox:before {
  content: "\F01C"; }

.fa-inbox-in:before {
  content: "\F310"; }

.fa-inbox-out:before {
  content: "\F311"; }

.fa-indent:before {
  content: "\F03C"; }

.fa-industry:before {
  content: "\F275"; }

.fa-industry-alt:before {
  content: "\F3B3"; }

.fa-infinity:before {
  content: "\F534"; }

.fa-info:before {
  content: "\F129"; }

.fa-info-circle:before {
  content: "\F05A"; }

.fa-info-square:before {
  content: "\F30F"; }

.fa-inhaler:before {
  content: "\F5F9"; }

.fa-innosoft:before {
  content: "\E080"; }

.fa-instagram:before {
  content: "\F16D"; }

.fa-instagram-square:before {
  content: "\E055"; }

.fa-instalod:before {
  content: "\E081"; }

.fa-integral:before {
  content: "\F667"; }

.fa-intercom:before {
  content: "\F7AF"; }

.fa-internet-explorer:before {
  content: "\F26B"; }

.fa-intersection:before {
  content: "\F668"; }

.fa-inventory:before {
  content: "\F480"; }

.fa-invision:before {
  content: "\F7B0"; }

.fa-ioxhost:before {
  content: "\F208"; }

.fa-island-tropical:before {
  content: "\F811"; }

.fa-italic:before {
  content: "\F033"; }

.fa-itch-io:before {
  content: "\F83A"; }

.fa-itunes:before {
  content: "\F3B4"; }

.fa-itunes-note:before {
  content: "\F3B5"; }

.fa-jack-o-lantern:before {
  content: "\F30E"; }

.fa-java:before {
  content: "\F4E4"; }

.fa-jedi:before {
  content: "\F669"; }

.fa-jedi-order:before {
  content: "\F50E"; }

.fa-jenkins:before {
  content: "\F3B6"; }

.fa-jira:before {
  content: "\F7B1"; }

.fa-joget:before {
  content: "\F3B7"; }

.fa-joint:before {
  content: "\F595"; }

.fa-joomla:before {
  content: "\F1AA"; }

.fa-journal-whills:before {
  content: "\F66A"; }

.fa-joystick:before {
  content: "\F8C5"; }

.fa-js:before {
  content: "\F3B8"; }

.fa-js-square:before {
  content: "\F3B9"; }

.fa-jsfiddle:before {
  content: "\F1CC"; }

.fa-jug:before {
  content: "\F8C6"; }

.fa-kaaba:before {
  content: "\F66B"; }

.fa-kaggle:before {
  content: "\F5FA"; }

.fa-kazoo:before {
  content: "\F8C7"; }

.fa-kerning:before {
  content: "\F86F"; }

.fa-key:before {
  content: "\F084"; }

.fa-key-skeleton:before {
  content: "\F6F3"; }

.fa-keybase:before {
  content: "\F4F5"; }

.fa-keyboard:before {
  content: "\F11C"; }

.fa-keycdn:before {
  content: "\F3BA"; }

.fa-keynote:before {
  content: "\F66C"; }

.fa-khanda:before {
  content: "\F66D"; }

.fa-kickstarter:before {
  content: "\F3BB"; }

.fa-kickstarter-k:before {
  content: "\F3BC"; }

.fa-kidneys:before {
  content: "\F5FB"; }

.fa-kiss:before {
  content: "\F596"; }

.fa-kiss-beam:before {
  content: "\F597"; }

.fa-kiss-wink-heart:before {
  content: "\F598"; }

.fa-kite:before {
  content: "\F6F4"; }

.fa-kiwi-bird:before {
  content: "\F535"; }

.fa-knife-kitchen:before {
  content: "\F6F5"; }

.fa-korvue:before {
  content: "\F42F"; }

.fa-lambda:before {
  content: "\F66E"; }

.fa-lamp:before {
  content: "\F4CA"; }

.fa-lamp-desk:before {
  content: "\E014"; }

.fa-lamp-floor:before {
  content: "\E015"; }

.fa-landmark:before {
  content: "\F66F"; }

.fa-landmark-alt:before {
  content: "\F752"; }

.fa-language:before {
  content: "\F1AB"; }

.fa-laptop:before {
  content: "\F109"; }

.fa-laptop-code:before {
  content: "\F5FC"; }

.fa-laptop-house:before {
  content: "\E066"; }

.fa-laptop-medical:before {
  content: "\F812"; }

.fa-laravel:before {
  content: "\F3BD"; }

.fa-lasso:before {
  content: "\F8C8"; }

.fa-lastfm:before {
  content: "\F202"; }

.fa-lastfm-square:before {
  content: "\F203"; }

.fa-laugh:before {
  content: "\F599"; }

.fa-laugh-beam:before {
  content: "\F59A"; }

.fa-laugh-squint:before {
  content: "\F59B"; }

.fa-laugh-wink:before {
  content: "\F59C"; }

.fa-layer-group:before {
  content: "\F5FD"; }

.fa-layer-minus:before {
  content: "\F5FE"; }

.fa-layer-plus:before {
  content: "\F5FF"; }

.fa-leaf:before {
  content: "\F06C"; }

.fa-leaf-heart:before {
  content: "\F4CB"; }

.fa-leaf-maple:before {
  content: "\F6F6"; }

.fa-leaf-oak:before {
  content: "\F6F7"; }

.fa-leanpub:before {
  content: "\F212"; }

.fa-lemon:before {
  content: "\F094"; }

.fa-less:before {
  content: "\F41D"; }

.fa-less-than:before {
  content: "\F536"; }

.fa-less-than-equal:before {
  content: "\F537"; }

.fa-level-down:before {
  content: "\F149"; }

.fa-level-down-alt:before {
  content: "\F3BE"; }

.fa-level-up:before {
  content: "\F148"; }

.fa-level-up-alt:before {
  content: "\F3BF"; }

.fa-life-ring:before {
  content: "\F1CD"; }

.fa-light-ceiling:before {
  content: "\E016"; }

.fa-light-switch:before {
  content: "\E017"; }

.fa-light-switch-off:before {
  content: "\E018"; }

.fa-light-switch-on:before {
  content: "\E019"; }

.fa-lightbulb:before {
  content: "\F0EB"; }

.fa-lightbulb-dollar:before {
  content: "\F670"; }

.fa-lightbulb-exclamation:before {
  content: "\F671"; }

.fa-lightbulb-on:before {
  content: "\F672"; }

.fa-lightbulb-slash:before {
  content: "\F673"; }

.fa-lights-holiday:before {
  content: "\F7B2"; }

.fa-line:before {
  content: "\F3C0"; }

.fa-line-columns:before {
  content: "\F870"; }

.fa-line-height:before {
  content: "\F871"; }

.fa-link:before {
  content: "\F0C1"; }

.fa-linkedin:before {
  content: "\F08C"; }

.fa-linkedin-in:before {
  content: "\F0E1"; }

.fa-linode:before {
  content: "\F2B8"; }

.fa-linux:before {
  content: "\F17C"; }

.fa-lips:before {
  content: "\F600"; }

.fa-lira-sign:before {
  content: "\F195"; }

.fa-list:before {
  content: "\F03A"; }

.fa-list-alt:before {
  content: "\F022"; }

.fa-list-music:before {
  content: "\F8C9"; }

.fa-list-ol:before {
  content: "\F0CB"; }

.fa-list-ul:before {
  content: "\F0CA"; }

.fa-location:before {
  content: "\F601"; }

.fa-location-arrow:before {
  content: "\F124"; }

.fa-location-circle:before {
  content: "\F602"; }

.fa-location-slash:before {
  content: "\F603"; }

.fa-lock:before {
  content: "\F023"; }

.fa-lock-alt:before {
  content: "\F30D"; }

.fa-lock-open:before {
  content: "\F3C1"; }

.fa-lock-open-alt:before {
  content: "\F3C2"; }

.fa-long-arrow-alt-down:before {
  content: "\F309"; }

.fa-long-arrow-alt-left:before {
  content: "\F30A"; }

.fa-long-arrow-alt-right:before {
  content: "\F30B"; }

.fa-long-arrow-alt-up:before {
  content: "\F30C"; }

.fa-long-arrow-down:before {
  content: "\F175"; }

.fa-long-arrow-left:before {
  content: "\F177"; }

.fa-long-arrow-right:before {
  content: "\F178"; }

.fa-long-arrow-up:before {
  content: "\F176"; }

.fa-loveseat:before {
  content: "\F4CC"; }

.fa-low-vision:before {
  content: "\F2A8"; }

.fa-luchador:before {
  content: "\F455"; }

.fa-luggage-cart:before {
  content: "\F59D"; }

.fa-lungs:before {
  content: "\F604"; }

.fa-lungs-virus:before {
  content: "\E067"; }

.fa-lyft:before {
  content: "\F3C3"; }

.fa-mace:before {
  content: "\F6F8"; }

.fa-magento:before {
  content: "\F3C4"; }

.fa-magic:before {
  content: "\F0D0"; }

.fa-magnet:before {
  content: "\F076"; }

.fa-mail-bulk:before {
  content: "\F674"; }

.fa-mailbox:before {
  content: "\F813"; }

.fa-mailchimp:before {
  content: "\F59E"; }

.fa-male:before {
  content: "\F183"; }

.fa-mandalorian:before {
  content: "\F50F"; }

.fa-mandolin:before {
  content: "\F6F9"; }

.fa-map:before {
  content: "\F279"; }

.fa-map-marked:before {
  content: "\F59F"; }

.fa-map-marked-alt:before {
  content: "\F5A0"; }

.fa-map-marker:before {
  content: "\F041"; }

.fa-map-marker-alt:before {
  content: "\F3C5"; }

.fa-map-marker-alt-slash:before {
  content: "\F605"; }

.fa-map-marker-check:before {
  content: "\F606"; }

.fa-map-marker-edit:before {
  content: "\F607"; }

.fa-map-marker-exclamation:before {
  content: "\F608"; }

.fa-map-marker-minus:before {
  content: "\F609"; }

.fa-map-marker-plus:before {
  content: "\F60A"; }

.fa-map-marker-question:before {
  content: "\F60B"; }

.fa-map-marker-slash:before {
  content: "\F60C"; }

.fa-map-marker-smile:before {
  content: "\F60D"; }

.fa-map-marker-times:before {
  content: "\F60E"; }

.fa-map-pin:before {
  content: "\F276"; }

.fa-map-signs:before {
  content: "\F277"; }

.fa-markdown:before {
  content: "\F60F"; }

.fa-marker:before {
  content: "\F5A1"; }

.fa-mars:before {
  content: "\F222"; }

.fa-mars-double:before {
  content: "\F227"; }

.fa-mars-stroke:before {
  content: "\F229"; }

.fa-mars-stroke-h:before {
  content: "\F22B"; }

.fa-mars-stroke-v:before {
  content: "\F22A"; }

.fa-mask:before {
  content: "\F6FA"; }

.fa-mastodon:before {
  content: "\F4F6"; }

.fa-maxcdn:before {
  content: "\F136"; }

.fa-mdb:before {
  content: "\F8CA"; }

.fa-meat:before {
  content: "\F814"; }

.fa-medal:before {
  content: "\F5A2"; }

.fa-medapps:before {
  content: "\F3C6"; }

.fa-medium:before {
  content: "\F23A"; }

.fa-medium-m:before {
  content: "\F3C7"; }

.fa-medkit:before {
  content: "\F0FA"; }

.fa-medrt:before {
  content: "\F3C8"; }

.fa-meetup:before {
  content: "\F2E0"; }

.fa-megaphone:before {
  content: "\F675"; }

.fa-megaport:before {
  content: "\F5A3"; }

.fa-meh:before {
  content: "\F11A"; }

.fa-meh-blank:before {
  content: "\F5A4"; }

.fa-meh-rolling-eyes:before {
  content: "\F5A5"; }

.fa-memory:before {
  content: "\F538"; }

.fa-mendeley:before {
  content: "\F7B3"; }

.fa-menorah:before {
  content: "\F676"; }

.fa-mercury:before {
  content: "\F223"; }

.fa-meteor:before {
  content: "\F753"; }

.fa-microblog:before {
  content: "\E01A"; }

.fa-microchip:before {
  content: "\F2DB"; }

.fa-microphone:before {
  content: "\F130"; }

.fa-microphone-alt:before {
  content: "\F3C9"; }

.fa-microphone-alt-slash:before {
  content: "\F539"; }

.fa-microphone-slash:before {
  content: "\F131"; }

.fa-microphone-stand:before {
  content: "\F8CB"; }

.fa-microscope:before {
  content: "\F610"; }

.fa-microsoft:before {
  content: "\F3CA"; }

.fa-microwave:before {
  content: "\E01B"; }

.fa-mind-share:before {
  content: "\F677"; }

.fa-minus:before {
  content: "\F068"; }

.fa-minus-circle:before {
  content: "\F056"; }

.fa-minus-hexagon:before {
  content: "\F307"; }

.fa-minus-octagon:before {
  content: "\F308"; }

.fa-minus-square:before {
  content: "\F146"; }

.fa-mistletoe:before {
  content: "\F7B4"; }

.fa-mitten:before {
  content: "\F7B5"; }

.fa-mix:before {
  content: "\F3CB"; }

.fa-mixcloud:before {
  content: "\F289"; }

.fa-mixer:before {
  content: "\E056"; }

.fa-mizuni:before {
  content: "\F3CC"; }

.fa-mobile:before {
  content: "\F10B"; }

.fa-mobile-alt:before {
  content: "\F3CD"; }

.fa-mobile-android:before {
  content: "\F3CE"; }

.fa-mobile-android-alt:before {
  content: "\F3CF"; }

.fa-modx:before {
  content: "\F285"; }

.fa-monero:before {
  content: "\F3D0"; }

.fa-money-bill:before {
  content: "\F0D6"; }

.fa-money-bill-alt:before {
  content: "\F3D1"; }

.fa-money-bill-wave:before {
  content: "\F53A"; }

.fa-money-bill-wave-alt:before {
  content: "\F53B"; }

.fa-money-check:before {
  content: "\F53C"; }

.fa-money-check-alt:before {
  content: "\F53D"; }

.fa-money-check-edit:before {
  content: "\F872"; }

.fa-money-check-edit-alt:before {
  content: "\F873"; }

.fa-monitor-heart-rate:before {
  content: "\F611"; }

.fa-monkey:before {
  content: "\F6FB"; }

.fa-monument:before {
  content: "\F5A6"; }

.fa-moon:before {
  content: "\F186"; }

.fa-moon-cloud:before {
  content: "\F754"; }

.fa-moon-stars:before {
  content: "\F755"; }

.fa-mortar-pestle:before {
  content: "\F5A7"; }

.fa-mosque:before {
  content: "\F678"; }

.fa-motorcycle:before {
  content: "\F21C"; }

.fa-mountain:before {
  content: "\F6FC"; }

.fa-mountains:before {
  content: "\F6FD"; }

.fa-mouse:before {
  content: "\F8CC"; }

.fa-mouse-alt:before {
  content: "\F8CD"; }

.fa-mouse-pointer:before {
  content: "\F245"; }

.fa-mp3-player:before {
  content: "\F8CE"; }

.fa-mug:before {
  content: "\F874"; }

.fa-mug-hot:before {
  content: "\F7B6"; }

.fa-mug-marshmallows:before {
  content: "\F7B7"; }

.fa-mug-tea:before {
  content: "\F875"; }

.fa-music:before {
  content: "\F001"; }

.fa-music-alt:before {
  content: "\F8CF"; }

.fa-music-alt-slash:before {
  content: "\F8D0"; }

.fa-music-slash:before {
  content: "\F8D1"; }

.fa-napster:before {
  content: "\F3D2"; }

.fa-narwhal:before {
  content: "\F6FE"; }

.fa-neos:before {
  content: "\F612"; }

.fa-network-wired:before {
  content: "\F6FF"; }

.fa-neuter:before {
  content: "\F22C"; }

.fa-newspaper:before {
  content: "\F1EA"; }

.fa-nimblr:before {
  content: "\F5A8"; }

.fa-node:before {
  content: "\F419"; }

.fa-node-js:before {
  content: "\F3D3"; }

.fa-not-equal:before {
  content: "\F53E"; }

.fa-notes-medical:before {
  content: "\F481"; }

.fa-npm:before {
  content: "\F3D4"; }

.fa-ns8:before {
  content: "\F3D5"; }

.fa-nutritionix:before {
  content: "\F3D6"; }

.fa-object-group:before {
  content: "\F247"; }

.fa-object-ungroup:before {
  content: "\F248"; }

.fa-octagon:before {
  content: "\F306"; }

.fa-octopus-deploy:before {
  content: "\E082"; }

.fa-odnoklassniki:before {
  content: "\F263"; }

.fa-odnoklassniki-square:before {
  content: "\F264"; }

.fa-oil-can:before {
  content: "\F613"; }

.fa-oil-temp:before {
  content: "\F614"; }

.fa-old-republic:before {
  content: "\F510"; }

.fa-om:before {
  content: "\F679"; }

.fa-omega:before {
  content: "\F67A"; }

.fa-opencart:before {
  content: "\F23D"; }

.fa-openid:before {
  content: "\F19B"; }

.fa-opera:before {
  content: "\F26A"; }

.fa-optin-monster:before {
  content: "\F23C"; }

.fa-orcid:before {
  content: "\F8D2"; }

.fa-ornament:before {
  content: "\F7B8"; }

.fa-osi:before {
  content: "\F41A"; }

.fa-otter:before {
  content: "\F700"; }

.fa-outdent:before {
  content: "\F03B"; }

.fa-outlet:before {
  content: "\E01C"; }

.fa-oven:before {
  content: "\E01D"; }

.fa-overline:before {
  content: "\F876"; }

.fa-page-break:before {
  content: "\F877"; }

.fa-page4:before {
  content: "\F3D7"; }

.fa-pagelines:before {
  content: "\F18C"; }

.fa-pager:before {
  content: "\F815"; }

.fa-paint-brush:before {
  content: "\F1FC"; }

.fa-paint-brush-alt:before {
  content: "\F5A9"; }

.fa-paint-roller:before {
  content: "\F5AA"; }

.fa-palette:before {
  content: "\F53F"; }

.fa-palfed:before {
  content: "\F3D8"; }

.fa-pallet:before {
  content: "\F482"; }

.fa-pallet-alt:before {
  content: "\F483"; }

.fa-paper-plane:before {
  content: "\F1D8"; }

.fa-paperclip:before {
  content: "\F0C6"; }

.fa-parachute-box:before {
  content: "\F4CD"; }

.fa-paragraph:before {
  content: "\F1DD"; }

.fa-paragraph-rtl:before {
  content: "\F878"; }

.fa-parking:before {
  content: "\F540"; }

.fa-parking-circle:before {
  content: "\F615"; }

.fa-parking-circle-slash:before {
  content: "\F616"; }

.fa-parking-slash:before {
  content: "\F617"; }

.fa-passport:before {
  content: "\F5AB"; }

.fa-pastafarianism:before {
  content: "\F67B"; }

.fa-paste:before {
  content: "\F0EA"; }

.fa-patreon:before {
  content: "\F3D9"; }

.fa-pause:before {
  content: "\F04C"; }

.fa-pause-circle:before {
  content: "\F28B"; }

.fa-paw:before {
  content: "\F1B0"; }

.fa-paw-alt:before {
  content: "\F701"; }

.fa-paw-claws:before {
  content: "\F702"; }

.fa-paypal:before {
  content: "\F1ED"; }

.fa-peace:before {
  content: "\F67C"; }

.fa-pegasus:before {
  content: "\F703"; }

.fa-pen:before {
  content: "\F304"; }

.fa-pen-alt:before {
  content: "\F305"; }

.fa-pen-fancy:before {
  content: "\F5AC"; }

.fa-pen-nib:before {
  content: "\F5AD"; }

.fa-pen-square:before {
  content: "\F14B"; }

.fa-pencil:before {
  content: "\F040"; }

.fa-pencil-alt:before {
  content: "\F303"; }

.fa-pencil-paintbrush:before {
  content: "\F618"; }

.fa-pencil-ruler:before {
  content: "\F5AE"; }

.fa-pennant:before {
  content: "\F456"; }

.fa-penny-arcade:before {
  content: "\F704"; }

.fa-people-arrows:before {
  content: "\E068"; }

.fa-people-carry:before {
  content: "\F4CE"; }

.fa-pepper-hot:before {
  content: "\F816"; }

.fa-perbyte:before {
  content: "\E083"; }

.fa-percent:before {
  content: "\F295"; }

.fa-percentage:before {
  content: "\F541"; }

.fa-periscope:before {
  content: "\F3DA"; }

.fa-person-booth:before {
  content: "\F756"; }

.fa-person-carry:before {
  content: "\F4CF"; }

.fa-person-dolly:before {
  content: "\F4D0"; }

.fa-person-dolly-empty:before {
  content: "\F4D1"; }

.fa-person-sign:before {
  content: "\F757"; }

.fa-phabricator:before {
  content: "\F3DB"; }

.fa-phoenix-framework:before {
  content: "\F3DC"; }

.fa-phoenix-squadron:before {
  content: "\F511"; }

.fa-phone:before {
  content: "\F095"; }

.fa-phone-alt:before {
  content: "\F879"; }

.fa-phone-laptop:before {
  content: "\F87A"; }

.fa-phone-office:before {
  content: "\F67D"; }

.fa-phone-plus:before {
  content: "\F4D2"; }

.fa-phone-rotary:before {
  content: "\F8D3"; }

.fa-phone-slash:before {
  content: "\F3DD"; }

.fa-phone-square:before {
  content: "\F098"; }

.fa-phone-square-alt:before {
  content: "\F87B"; }

.fa-phone-volume:before {
  content: "\F2A0"; }

.fa-photo-video:before {
  content: "\F87C"; }

.fa-php:before {
  content: "\F457"; }

.fa-pi:before {
  content: "\F67E"; }

.fa-piano:before {
  content: "\F8D4"; }

.fa-piano-keyboard:before {
  content: "\F8D5"; }

.fa-pie:before {
  content: "\F705"; }

.fa-pied-piper:before {
  content: "\F2AE"; }

.fa-pied-piper-alt:before {
  content: "\F1A8"; }

.fa-pied-piper-hat:before {
  content: "\F4E5"; }

.fa-pied-piper-pp:before {
  content: "\F1A7"; }

.fa-pied-piper-square:before {
  content: "\E01E"; }

.fa-pig:before {
  content: "\F706"; }

.fa-piggy-bank:before {
  content: "\F4D3"; }

.fa-pills:before {
  content: "\F484"; }

.fa-pinterest:before {
  content: "\F0D2"; }

.fa-pinterest-p:before {
  content: "\F231"; }

.fa-pinterest-square:before {
  content: "\F0D3"; }

.fa-pizza:before {
  content: "\F817"; }

.fa-pizza-slice:before {
  content: "\F818"; }

.fa-place-of-worship:before {
  content: "\F67F"; }

.fa-plane:before {
  content: "\F072"; }

.fa-plane-alt:before {
  content: "\F3DE"; }

.fa-plane-arrival:before {
  content: "\F5AF"; }

.fa-plane-departure:before {
  content: "\F5B0"; }

.fa-plane-slash:before {
  content: "\E069"; }

.fa-planet-moon:before {
  content: "\E01F"; }

.fa-planet-ringed:before {
  content: "\E020"; }

.fa-play:before {
  content: "\F04B"; }

.fa-play-circle:before {
  content: "\F144"; }

.fa-playstation:before {
  content: "\F3DF"; }

.fa-plug:before {
  content: "\F1E6"; }

.fa-plus:before {
  content: "\F067"; }

.fa-plus-circle:before {
  content: "\F055"; }

.fa-plus-hexagon:before {
  content: "\F300"; }

.fa-plus-octagon:before {
  content: "\F301"; }

.fa-plus-square:before {
  content: "\F0FE"; }

.fa-podcast:before {
  content: "\F2CE"; }

.fa-podium:before {
  content: "\F680"; }

.fa-podium-star:before {
  content: "\F758"; }

.fa-police-box:before {
  content: "\E021"; }

.fa-poll:before {
  content: "\F681"; }

.fa-poll-h:before {
  content: "\F682"; }

.fa-poll-people:before {
  content: "\F759"; }

.fa-poo:before {
  content: "\F2FE"; }

.fa-poo-storm:before {
  content: "\F75A"; }

.fa-poop:before {
  content: "\F619"; }

.fa-popcorn:before {
  content: "\F819"; }

.fa-portal-enter:before {
  content: "\E022"; }

.fa-portal-exit:before {
  content: "\E023"; }

.fa-portrait:before {
  content: "\F3E0"; }

.fa-pound-sign:before {
  content: "\F154"; }

.fa-power-off:before {
  content: "\F011"; }

.fa-pray:before {
  content: "\F683"; }

.fa-praying-hands:before {
  content: "\F684"; }

.fa-prescription:before {
  content: "\F5B1"; }

.fa-prescription-bottle:before {
  content: "\F485"; }

.fa-prescription-bottle-alt:before {
  content: "\F486"; }

.fa-presentation:before {
  content: "\F685"; }

.fa-print:before {
  content: "\F02F"; }

.fa-print-search:before {
  content: "\F81A"; }

.fa-print-slash:before {
  content: "\F686"; }

.fa-procedures:before {
  content: "\F487"; }

.fa-product-hunt:before {
  content: "\F288"; }

.fa-project-diagram:before {
  content: "\F542"; }

.fa-projector:before {
  content: "\F8D6"; }

.fa-pump-medical:before {
  content: "\E06A"; }

.fa-pump-soap:before {
  content: "\E06B"; }

.fa-pumpkin:before {
  content: "\F707"; }

.fa-pushed:before {
  content: "\F3E1"; }

.fa-puzzle-piece:before {
  content: "\F12E"; }

.fa-python:before {
  content: "\F3E2"; }

.fa-qq:before {
  content: "\F1D6"; }

.fa-qrcode:before {
  content: "\F029"; }

.fa-question:before {
  content: "\F128"; }

.fa-question-circle:before {
  content: "\F059"; }

.fa-question-square:before {
  content: "\F2FD"; }

.fa-quidditch:before {
  content: "\F458"; }

.fa-quinscape:before {
  content: "\F459"; }

.fa-quora:before {
  content: "\F2C4"; }

.fa-quote-left:before {
  content: "\F10D"; }

.fa-quote-right:before {
  content: "\F10E"; }

.fa-quran:before {
  content: "\F687"; }

.fa-r-project:before {
  content: "\F4F7"; }

.fa-rabbit:before {
  content: "\F708"; }

.fa-rabbit-fast:before {
  content: "\F709"; }

.fa-racquet:before {
  content: "\F45A"; }

.fa-radar:before {
  content: "\E024"; }

.fa-radiation:before {
  content: "\F7B9"; }

.fa-radiation-alt:before {
  content: "\F7BA"; }

.fa-radio:before {
  content: "\F8D7"; }

.fa-radio-alt:before {
  content: "\F8D8"; }

.fa-rainbow:before {
  content: "\F75B"; }

.fa-raindrops:before {
  content: "\F75C"; }

.fa-ram:before {
  content: "\F70A"; }

.fa-ramp-loading:before {
  content: "\F4D4"; }

.fa-random:before {
  content: "\F074"; }

.fa-raspberry-pi:before {
  content: "\F7BB"; }

.fa-ravelry:before {
  content: "\F2D9"; }

.fa-raygun:before {
  content: "\E025"; }

.fa-react:before {
  content: "\F41B"; }

.fa-reacteurope:before {
  content: "\F75D"; }

.fa-readme:before {
  content: "\F4D5"; }

.fa-rebel:before {
  content: "\F1D0"; }

.fa-receipt:before {
  content: "\F543"; }

.fa-record-vinyl:before {
  content: "\F8D9"; }

.fa-rectangle-landscape:before {
  content: "\F2FA"; }

.fa-rectangle-portrait:before {
  content: "\F2FB"; }

.fa-rectangle-wide:before {
  content: "\F2FC"; }

.fa-recycle:before {
  content: "\F1B8"; }

.fa-red-river:before {
  content: "\F3E3"; }

.fa-reddit:before {
  content: "\F1A1"; }

.fa-reddit-alien:before {
  content: "\F281"; }

.fa-reddit-square:before {
  content: "\F1A2"; }

.fa-redhat:before {
  content: "\F7BC"; }

.fa-redo:before {
  content: "\F01E"; }

.fa-redo-alt:before {
  content: "\F2F9"; }

.fa-refrigerator:before {
  content: "\E026"; }

.fa-registered:before {
  content: "\F25D"; }

.fa-remove-format:before {
  content: "\F87D"; }

.fa-renren:before {
  content: "\F18B"; }

.fa-repeat:before {
  content: "\F363"; }

.fa-repeat-1:before {
  content: "\F365"; }

.fa-repeat-1-alt:before {
  content: "\F366"; }

.fa-repeat-alt:before {
  content: "\F364"; }

.fa-reply:before {
  content: "\F3E5"; }

.fa-reply-all:before {
  content: "\F122"; }

.fa-replyd:before {
  content: "\F3E6"; }

.fa-republican:before {
  content: "\F75E"; }

.fa-researchgate:before {
  content: "\F4F8"; }

.fa-resolving:before {
  content: "\F3E7"; }

.fa-restroom:before {
  content: "\F7BD"; }

.fa-retweet:before {
  content: "\F079"; }

.fa-retweet-alt:before {
  content: "\F361"; }

.fa-rev:before {
  content: "\F5B2"; }

.fa-ribbon:before {
  content: "\F4D6"; }

.fa-ring:before {
  content: "\F70B"; }

.fa-rings-wedding:before {
  content: "\F81B"; }

.fa-road:before {
  content: "\F018"; }

.fa-robot:before {
  content: "\F544"; }

.fa-rocket:before {
  content: "\F135"; }

.fa-rocket-launch:before {
  content: "\E027"; }

.fa-rocketchat:before {
  content: "\F3E8"; }

.fa-rockrms:before {
  content: "\F3E9"; }

.fa-route:before {
  content: "\F4D7"; }

.fa-route-highway:before {
  content: "\F61A"; }

.fa-route-interstate:before {
  content: "\F61B"; }

.fa-router:before {
  content: "\F8DA"; }

.fa-rss:before {
  content: "\F09E"; }

.fa-rss-square:before {
  content: "\F143"; }

.fa-ruble-sign:before {
  content: "\F158"; }

.fa-ruler:before {
  content: "\F545"; }

.fa-ruler-combined:before {
  content: "\F546"; }

.fa-ruler-horizontal:before {
  content: "\F547"; }

.fa-ruler-triangle:before {
  content: "\F61C"; }

.fa-ruler-vertical:before {
  content: "\F548"; }

.fa-running:before {
  content: "\F70C"; }

.fa-rupee-sign:before {
  content: "\F156"; }

.fa-rust:before {
  content: "\E07A"; }

.fa-rv:before {
  content: "\F7BE"; }

.fa-sack:before {
  content: "\F81C"; }

.fa-sack-dollar:before {
  content: "\F81D"; }

.fa-sad-cry:before {
  content: "\F5B3"; }

.fa-sad-tear:before {
  content: "\F5B4"; }

.fa-safari:before {
  content: "\F267"; }

.fa-salad:before {
  content: "\F81E"; }

.fa-salesforce:before {
  content: "\F83B"; }

.fa-sandwich:before {
  content: "\F81F"; }

.fa-sass:before {
  content: "\F41E"; }

.fa-satellite:before {
  content: "\F7BF"; }

.fa-satellite-dish:before {
  content: "\F7C0"; }

.fa-sausage:before {
  content: "\F820"; }

.fa-save:before {
  content: "\F0C7"; }

.fa-sax-hot:before {
  content: "\F8DB"; }

.fa-saxophone:before {
  content: "\F8DC"; }

.fa-scalpel:before {
  content: "\F61D"; }

.fa-scalpel-path:before {
  content: "\F61E"; }

.fa-scanner:before {
  content: "\F488"; }

.fa-scanner-image:before {
  content: "\F8F3"; }

.fa-scanner-keyboard:before {
  content: "\F489"; }

.fa-scanner-touchscreen:before {
  content: "\F48A"; }

.fa-scarecrow:before {
  content: "\F70D"; }

.fa-scarf:before {
  content: "\F7C1"; }

.fa-schlix:before {
  content: "\F3EA"; }

.fa-school:before {
  content: "\F549"; }

.fa-screwdriver:before {
  content: "\F54A"; }

.fa-scribd:before {
  content: "\F28A"; }

.fa-scroll:before {
  content: "\F70E"; }

.fa-scroll-old:before {
  content: "\F70F"; }

.fa-scrubber:before {
  content: "\F2F8"; }

.fa-scythe:before {
  content: "\F710"; }

.fa-sd-card:before {
  content: "\F7C2"; }

.fa-search:before {
  content: "\F002"; }

.fa-search-dollar:before {
  content: "\F688"; }

.fa-search-location:before {
  content: "\F689"; }

.fa-search-minus:before {
  content: "\F010"; }

.fa-search-plus:before {
  content: "\F00E"; }

.fa-searchengin:before {
  content: "\F3EB"; }

.fa-seedling:before {
  content: "\F4D8"; }

.fa-sellcast:before {
  content: "\F2DA"; }

.fa-sellsy:before {
  content: "\F213"; }

.fa-send-back:before {
  content: "\F87E"; }

.fa-send-backward:before {
  content: "\F87F"; }

.fa-sensor:before {
  content: "\E028"; }

.fa-sensor-alert:before {
  content: "\E029"; }

.fa-sensor-fire:before {
  content: "\E02A"; }

.fa-sensor-on:before {
  content: "\E02B"; }

.fa-sensor-smoke:before {
  content: "\E02C"; }

.fa-server:before {
  content: "\F233"; }

.fa-servicestack:before {
  content: "\F3EC"; }

.fa-shapes:before {
  content: "\F61F"; }

.fa-share:before {
  content: "\F064"; }

.fa-share-all:before {
  content: "\F367"; }

.fa-share-alt:before {
  content: "\F1E0"; }

.fa-share-alt-square:before {
  content: "\F1E1"; }

.fa-share-square:before {
  content: "\F14D"; }

.fa-sheep:before {
  content: "\F711"; }

.fa-shekel-sign:before {
  content: "\F20B"; }

.fa-shield:before {
  content: "\F132"; }

.fa-shield-alt:before {
  content: "\F3ED"; }

.fa-shield-check:before {
  content: "\F2F7"; }

.fa-shield-cross:before {
  content: "\F712"; }

.fa-shield-virus:before {
  content: "\E06C"; }

.fa-ship:before {
  content: "\F21A"; }

.fa-shipping-fast:before {
  content: "\F48B"; }

.fa-shipping-timed:before {
  content: "\F48C"; }

.fa-shirtsinbulk:before {
  content: "\F214"; }

.fa-shish-kebab:before {
  content: "\F821"; }

.fa-shoe-prints:before {
  content: "\F54B"; }

.fa-shopify:before {
  content: "\E057"; }

.fa-shopping-bag:before {
  content: "\F290"; }

.fa-shopping-basket:before {
  content: "\F291"; }

.fa-shopping-cart:before {
  content: "\F07A"; }

.fa-shopware:before {
  content: "\F5B5"; }

.fa-shovel:before {
  content: "\F713"; }

.fa-shovel-snow:before {
  content: "\F7C3"; }

.fa-shower:before {
  content: "\F2CC"; }

.fa-shredder:before {
  content: "\F68A"; }

.fa-shuttle-van:before {
  content: "\F5B6"; }

.fa-shuttlecock:before {
  content: "\F45B"; }

.fa-sickle:before {
  content: "\F822"; }

.fa-sigma:before {
  content: "\F68B"; }

.fa-sign:before {
  content: "\F4D9"; }

.fa-sign-in:before {
  content: "\F090"; }

.fa-sign-in-alt:before {
  content: "\F2F6"; }

.fa-sign-language:before {
  content: "\F2A7"; }

.fa-sign-out:before {
  content: "\F08B"; }

.fa-sign-out-alt:before {
  content: "\F2F5"; }

.fa-signal:before {
  content: "\F012"; }

.fa-signal-1:before {
  content: "\F68C"; }

.fa-signal-2:before {
  content: "\F68D"; }

.fa-signal-3:before {
  content: "\F68E"; }

.fa-signal-4:before {
  content: "\F68F"; }

.fa-signal-alt:before {
  content: "\F690"; }

.fa-signal-alt-1:before {
  content: "\F691"; }

.fa-signal-alt-2:before {
  content: "\F692"; }

.fa-signal-alt-3:before {
  content: "\F693"; }

.fa-signal-alt-slash:before {
  content: "\F694"; }

.fa-signal-slash:before {
  content: "\F695"; }

.fa-signal-stream:before {
  content: "\F8DD"; }

.fa-signature:before {
  content: "\F5B7"; }

.fa-sim-card:before {
  content: "\F7C4"; }

.fa-simplybuilt:before {
  content: "\F215"; }

.fa-sink:before {
  content: "\E06D"; }

.fa-siren:before {
  content: "\E02D"; }

.fa-siren-on:before {
  content: "\E02E"; }

.fa-sistrix:before {
  content: "\F3EE"; }

.fa-sitemap:before {
  content: "\F0E8"; }

.fa-sith:before {
  content: "\F512"; }

.fa-skating:before {
  content: "\F7C5"; }

.fa-skeleton:before {
  content: "\F620"; }

.fa-sketch:before {
  content: "\F7C6"; }

.fa-ski-jump:before {
  content: "\F7C7"; }

.fa-ski-lift:before {
  content: "\F7C8"; }

.fa-skiing:before {
  content: "\F7C9"; }

.fa-skiing-nordic:before {
  content: "\F7CA"; }

.fa-skull:before {
  content: "\F54C"; }

.fa-skull-cow:before {
  content: "\F8DE"; }

.fa-skull-crossbones:before {
  content: "\F714"; }

.fa-skyatlas:before {
  content: "\F216"; }

.fa-skype:before {
  content: "\F17E"; }

.fa-slack:before {
  content: "\F198"; }

.fa-slack-hash:before {
  content: "\F3EF"; }

.fa-slash:before {
  content: "\F715"; }

.fa-sledding:before {
  content: "\F7CB"; }

.fa-sleigh:before {
  content: "\F7CC"; }

.fa-sliders-h:before {
  content: "\F1DE"; }

.fa-sliders-h-square:before {
  content: "\F3F0"; }

.fa-sliders-v:before {
  content: "\F3F1"; }

.fa-sliders-v-square:before {
  content: "\F3F2"; }

.fa-slideshare:before {
  content: "\F1E7"; }

.fa-smile:before {
  content: "\F118"; }

.fa-smile-beam:before {
  content: "\F5B8"; }

.fa-smile-plus:before {
  content: "\F5B9"; }

.fa-smile-wink:before {
  content: "\F4DA"; }

.fa-smog:before {
  content: "\F75F"; }

.fa-smoke:before {
  content: "\F760"; }

.fa-smoking:before {
  content: "\F48D"; }

.fa-smoking-ban:before {
  content: "\F54D"; }

.fa-sms:before {
  content: "\F7CD"; }

.fa-snake:before {
  content: "\F716"; }

.fa-snapchat:before {
  content: "\F2AB"; }

.fa-snapchat-ghost:before {
  content: "\F2AC"; }

.fa-snapchat-square:before {
  content: "\F2AD"; }

.fa-snooze:before {
  content: "\F880"; }

.fa-snow-blowing:before {
  content: "\F761"; }

.fa-snowboarding:before {
  content: "\F7CE"; }

.fa-snowflake:before {
  content: "\F2DC"; }

.fa-snowflakes:before {
  content: "\F7CF"; }

.fa-snowman:before {
  content: "\F7D0"; }

.fa-snowmobile:before {
  content: "\F7D1"; }

.fa-snowplow:before {
  content: "\F7D2"; }

.fa-soap:before {
  content: "\E06E"; }

.fa-socks:before {
  content: "\F696"; }

.fa-solar-panel:before {
  content: "\F5BA"; }

.fa-solar-system:before {
  content: "\E02F"; }

.fa-sort:before {
  content: "\F0DC"; }

.fa-sort-alpha-down:before {
  content: "\F15D"; }

.fa-sort-alpha-down-alt:before {
  content: "\F881"; }

.fa-sort-alpha-up:before {
  content: "\F15E"; }

.fa-sort-alpha-up-alt:before {
  content: "\F882"; }

.fa-sort-alt:before {
  content: "\F883"; }

.fa-sort-amount-down:before {
  content: "\F160"; }

.fa-sort-amount-down-alt:before {
  content: "\F884"; }

.fa-sort-amount-up:before {
  content: "\F161"; }

.fa-sort-amount-up-alt:before {
  content: "\F885"; }

.fa-sort-circle:before {
  content: "\E030"; }

.fa-sort-circle-down:before {
  content: "\E031"; }

.fa-sort-circle-up:before {
  content: "\E032"; }

.fa-sort-down:before {
  content: "\F0DD"; }

.fa-sort-numeric-down:before {
  content: "\F162"; }

.fa-sort-numeric-down-alt:before {
  content: "\F886"; }

.fa-sort-numeric-up:before {
  content: "\F163"; }

.fa-sort-numeric-up-alt:before {
  content: "\F887"; }

.fa-sort-shapes-down:before {
  content: "\F888"; }

.fa-sort-shapes-down-alt:before {
  content: "\F889"; }

.fa-sort-shapes-up:before {
  content: "\F88A"; }

.fa-sort-shapes-up-alt:before {
  content: "\F88B"; }

.fa-sort-size-down:before {
  content: "\F88C"; }

.fa-sort-size-down-alt:before {
  content: "\F88D"; }

.fa-sort-size-up:before {
  content: "\F88E"; }

.fa-sort-size-up-alt:before {
  content: "\F88F"; }

.fa-sort-up:before {
  content: "\F0DE"; }

.fa-soundcloud:before {
  content: "\F1BE"; }

.fa-soup:before {
  content: "\F823"; }

.fa-sourcetree:before {
  content: "\F7D3"; }

.fa-spa:before {
  content: "\F5BB"; }

.fa-space-shuttle:before {
  content: "\F197"; }

.fa-space-station-moon:before {
  content: "\E033"; }

.fa-space-station-moon-alt:before {
  content: "\E034"; }

.fa-spade:before {
  content: "\F2F4"; }

.fa-sparkles:before {
  content: "\F890"; }

.fa-speakap:before {
  content: "\F3F3"; }

.fa-speaker:before {
  content: "\F8DF"; }

.fa-speaker-deck:before {
  content: "\F83C"; }

.fa-speakers:before {
  content: "\F8E0"; }

.fa-spell-check:before {
  content: "\F891"; }

.fa-spider:before {
  content: "\F717"; }

.fa-spider-black-widow:before {
  content: "\F718"; }

.fa-spider-web:before {
  content: "\F719"; }

.fa-spinner:before {
  content: "\F110"; }

.fa-spinner-third:before {
  content: "\F3F4"; }

.fa-splotch:before {
  content: "\F5BC"; }

.fa-spotify:before {
  content: "\F1BC"; }

.fa-spray-can:before {
  content: "\F5BD"; }

.fa-sprinkler:before {
  content: "\E035"; }

.fa-square:before {
  content: "\F0C8"; }

.fa-square-full:before {
  content: "\F45C"; }

.fa-square-root:before {
  content: "\F697"; }

.fa-square-root-alt:before {
  content: "\F698"; }

.fa-squarespace:before {
  content: "\F5BE"; }

.fa-squirrel:before {
  content: "\F71A"; }

.fa-stack-exchange:before {
  content: "\F18D"; }

.fa-stack-overflow:before {
  content: "\F16C"; }

.fa-stackpath:before {
  content: "\F842"; }

.fa-staff:before {
  content: "\F71B"; }

.fa-stamp:before {
  content: "\F5BF"; }

.fa-star:before {
  content: "\F005"; }

.fa-star-and-crescent:before {
  content: "\F699"; }

.fa-star-christmas:before {
  content: "\F7D4"; }

.fa-star-exclamation:before {
  content: "\F2F3"; }

.fa-star-half:before {
  content: "\F089"; }

.fa-star-half-alt:before {
  content: "\F5C0"; }

.fa-star-of-david:before {
  content: "\F69A"; }

.fa-star-of-life:before {
  content: "\F621"; }

.fa-star-shooting:before {
  content: "\E036"; }

.fa-starfighter:before {
  content: "\E037"; }

.fa-starfighter-alt:before {
  content: "\E038"; }

.fa-stars:before {
  content: "\F762"; }

.fa-starship:before {
  content: "\E039"; }

.fa-starship-freighter:before {
  content: "\E03A"; }

.fa-staylinked:before {
  content: "\F3F5"; }

.fa-steak:before {
  content: "\F824"; }

.fa-steam:before {
  content: "\F1B6"; }

.fa-steam-square:before {
  content: "\F1B7"; }

.fa-steam-symbol:before {
  content: "\F3F6"; }

.fa-steering-wheel:before {
  content: "\F622"; }

.fa-step-backward:before {
  content: "\F048"; }

.fa-step-forward:before {
  content: "\F051"; }

.fa-stethoscope:before {
  content: "\F0F1"; }

.fa-sticker-mule:before {
  content: "\F3F7"; }

.fa-sticky-note:before {
  content: "\F249"; }

.fa-stocking:before {
  content: "\F7D5"; }

.fa-stomach:before {
  content: "\F623"; }

.fa-stop:before {
  content: "\F04D"; }

.fa-stop-circle:before {
  content: "\F28D"; }

.fa-stopwatch:before {
  content: "\F2F2"; }

.fa-stopwatch-20:before {
  content: "\E06F"; }

.fa-store:before {
  content: "\F54E"; }

.fa-store-alt:before {
  content: "\F54F"; }

.fa-store-alt-slash:before {
  content: "\E070"; }

.fa-store-slash:before {
  content: "\E071"; }

.fa-strava:before {
  content: "\F428"; }

.fa-stream:before {
  content: "\F550"; }

.fa-street-view:before {
  content: "\F21D"; }

.fa-stretcher:before {
  content: "\F825"; }

.fa-strikethrough:before {
  content: "\F0CC"; }

.fa-stripe:before {
  content: "\F429"; }

.fa-stripe-s:before {
  content: "\F42A"; }

.fa-stroopwafel:before {
  content: "\F551"; }

.fa-studiovinari:before {
  content: "\F3F8"; }

.fa-stumbleupon:before {
  content: "\F1A4"; }

.fa-stumbleupon-circle:before {
  content: "\F1A3"; }

.fa-subscript:before {
  content: "\F12C"; }

.fa-subway:before {
  content: "\F239"; }

.fa-suitcase:before {
  content: "\F0F2"; }

.fa-suitcase-rolling:before {
  content: "\F5C1"; }

.fa-sun:before {
  content: "\F185"; }

.fa-sun-cloud:before {
  content: "\F763"; }

.fa-sun-dust:before {
  content: "\F764"; }

.fa-sun-haze:before {
  content: "\F765"; }

.fa-sunglasses:before {
  content: "\F892"; }

.fa-sunrise:before {
  content: "\F766"; }

.fa-sunset:before {
  content: "\F767"; }

.fa-superpowers:before {
  content: "\F2DD"; }

.fa-superscript:before {
  content: "\F12B"; }

.fa-supple:before {
  content: "\F3F9"; }

.fa-surprise:before {
  content: "\F5C2"; }

.fa-suse:before {
  content: "\F7D6"; }

.fa-swatchbook:before {
  content: "\F5C3"; }

.fa-swift:before {
  content: "\F8E1"; }

.fa-swimmer:before {
  content: "\F5C4"; }

.fa-swimming-pool:before {
  content: "\F5C5"; }

.fa-sword:before {
  content: "\F71C"; }

.fa-sword-laser:before {
  content: "\E03B"; }

.fa-sword-laser-alt:before {
  content: "\E03C"; }

.fa-swords:before {
  content: "\F71D"; }

.fa-swords-laser:before {
  content: "\E03D"; }

.fa-symfony:before {
  content: "\F83D"; }

.fa-synagogue:before {
  content: "\F69B"; }

.fa-sync:before {
  content: "\F021"; }

.fa-sync-alt:before {
  content: "\F2F1"; }

.fa-syringe:before {
  content: "\F48E"; }

.fa-table:before {
  content: "\F0CE"; }

.fa-table-tennis:before {
  content: "\F45D"; }

.fa-tablet:before {
  content: "\F10A"; }

.fa-tablet-alt:before {
  content: "\F3FA"; }

.fa-tablet-android:before {
  content: "\F3FB"; }

.fa-tablet-android-alt:before {
  content: "\F3FC"; }

.fa-tablet-rugged:before {
  content: "\F48F"; }

.fa-tablets:before {
  content: "\F490"; }

.fa-tachometer:before {
  content: "\F0E4"; }

.fa-tachometer-alt:before {
  content: "\F3FD"; }

.fa-tachometer-alt-average:before {
  content: "\F624"; }

.fa-tachometer-alt-fast:before {
  content: "\F625"; }

.fa-tachometer-alt-fastest:before {
  content: "\F626"; }

.fa-tachometer-alt-slow:before {
  content: "\F627"; }

.fa-tachometer-alt-slowest:before {
  content: "\F628"; }

.fa-tachometer-average:before {
  content: "\F629"; }

.fa-tachometer-fast:before {
  content: "\F62A"; }

.fa-tachometer-fastest:before {
  content: "\F62B"; }

.fa-tachometer-slow:before {
  content: "\F62C"; }

.fa-tachometer-slowest:before {
  content: "\F62D"; }

.fa-taco:before {
  content: "\F826"; }

.fa-tag:before {
  content: "\F02B"; }

.fa-tags:before {
  content: "\F02C"; }

.fa-tally:before {
  content: "\F69C"; }

.fa-tanakh:before {
  content: "\F827"; }

.fa-tape:before {
  content: "\F4DB"; }

.fa-tasks:before {
  content: "\F0AE"; }

.fa-tasks-alt:before {
  content: "\F828"; }

.fa-taxi:before {
  content: "\F1BA"; }

.fa-teamspeak:before {
  content: "\F4F9"; }

.fa-teeth:before {
  content: "\F62E"; }

.fa-teeth-open:before {
  content: "\F62F"; }

.fa-telegram:before {
  content: "\F2C6"; }

.fa-telegram-plane:before {
  content: "\F3FE"; }

.fa-telescope:before {
  content: "\E03E"; }

.fa-temperature-down:before {
  content: "\E03F"; }

.fa-temperature-frigid:before {
  content: "\F768"; }

.fa-temperature-high:before {
  content: "\F769"; }

.fa-temperature-hot:before {
  content: "\F76A"; }

.fa-temperature-low:before {
  content: "\F76B"; }

.fa-temperature-up:before {
  content: "\E040"; }

.fa-tencent-weibo:before {
  content: "\F1D5"; }

.fa-tenge:before {
  content: "\F7D7"; }

.fa-tennis-ball:before {
  content: "\F45E"; }

.fa-terminal:before {
  content: "\F120"; }

.fa-text:before {
  content: "\F893"; }

.fa-text-height:before {
  content: "\F034"; }

.fa-text-size:before {
  content: "\F894"; }

.fa-text-width:before {
  content: "\F035"; }

.fa-th:before {
  content: "\F00A"; }

.fa-th-large:before {
  content: "\F009"; }

.fa-th-list:before {
  content: "\F00B"; }

.fa-the-red-yeti:before {
  content: "\F69D"; }

.fa-theater-masks:before {
  content: "\F630"; }

.fa-themeco:before {
  content: "\F5C6"; }

.fa-themeisle:before {
  content: "\F2B2"; }

.fa-thermometer:before {
  content: "\F491"; }

.fa-thermometer-empty:before {
  content: "\F2CB"; }

.fa-thermometer-full:before {
  content: "\F2C7"; }

.fa-thermometer-half:before {
  content: "\F2C9"; }

.fa-thermometer-quarter:before {
  content: "\F2CA"; }

.fa-thermometer-three-quarters:before {
  content: "\F2C8"; }

.fa-theta:before {
  content: "\F69E"; }

.fa-think-peaks:before {
  content: "\F731"; }

.fa-thumbs-down:before {
  content: "\F165"; }

.fa-thumbs-up:before {
  content: "\F164"; }

.fa-thumbtack:before {
  content: "\F08D"; }

.fa-thunderstorm:before {
  content: "\F76C"; }

.fa-thunderstorm-moon:before {
  content: "\F76D"; }

.fa-thunderstorm-sun:before {
  content: "\F76E"; }

.fa-ticket:before {
  content: "\F145"; }

.fa-ticket-alt:before {
  content: "\F3FF"; }

.fa-tiktok:before {
  content: "\E07B"; }

.fa-tilde:before {
  content: "\F69F"; }

.fa-times:before {
  content: "\F00D"; }

.fa-times-circle:before {
  content: "\F057"; }

.fa-times-hexagon:before {
  content: "\F2EE"; }

.fa-times-octagon:before {
  content: "\F2F0"; }

.fa-times-square:before {
  content: "\F2D3"; }

.fa-tint:before {
  content: "\F043"; }

.fa-tint-slash:before {
  content: "\F5C7"; }

.fa-tire:before {
  content: "\F631"; }

.fa-tire-flat:before {
  content: "\F632"; }

.fa-tire-pressure-warning:before {
  content: "\F633"; }

.fa-tire-rugged:before {
  content: "\F634"; }

.fa-tired:before {
  content: "\F5C8"; }

.fa-toggle-off:before {
  content: "\F204"; }

.fa-toggle-on:before {
  content: "\F205"; }

.fa-toilet:before {
  content: "\F7D8"; }

.fa-toilet-paper:before {
  content: "\F71E"; }

.fa-toilet-paper-alt:before {
  content: "\F71F"; }

.fa-toilet-paper-slash:before {
  content: "\E072"; }

.fa-tombstone:before {
  content: "\F720"; }

.fa-tombstone-alt:before {
  content: "\F721"; }

.fa-toolbox:before {
  content: "\F552"; }

.fa-tools:before {
  content: "\F7D9"; }

.fa-tooth:before {
  content: "\F5C9"; }

.fa-toothbrush:before {
  content: "\F635"; }

.fa-torah:before {
  content: "\F6A0"; }

.fa-torii-gate:before {
  content: "\F6A1"; }

.fa-tornado:before {
  content: "\F76F"; }

.fa-tractor:before {
  content: "\F722"; }

.fa-trade-federation:before {
  content: "\F513"; }

.fa-trademark:before {
  content: "\F25C"; }

.fa-traffic-cone:before {
  content: "\F636"; }

.fa-traffic-light:before {
  content: "\F637"; }

.fa-traffic-light-go:before {
  content: "\F638"; }

.fa-traffic-light-slow:before {
  content: "\F639"; }

.fa-traffic-light-stop:before {
  content: "\F63A"; }

.fa-trailer:before {
  content: "\E041"; }

.fa-train:before {
  content: "\F238"; }

.fa-tram:before {
  content: "\F7DA"; }

.fa-transgender:before {
  content: "\F224"; }

.fa-transgender-alt:before {
  content: "\F225"; }

.fa-transporter:before {
  content: "\E042"; }

.fa-transporter-1:before {
  content: "\E043"; }

.fa-transporter-2:before {
  content: "\E044"; }

.fa-transporter-3:before {
  content: "\E045"; }

.fa-transporter-empty:before {
  content: "\E046"; }

.fa-trash:before {
  content: "\F1F8"; }

.fa-trash-alt:before {
  content: "\F2ED"; }

.fa-trash-restore:before {
  content: "\F829"; }

.fa-trash-restore-alt:before {
  content: "\F82A"; }

.fa-trash-undo:before {
  content: "\F895"; }

.fa-trash-undo-alt:before {
  content: "\F896"; }

.fa-treasure-chest:before {
  content: "\F723"; }

.fa-tree:before {
  content: "\F1BB"; }

.fa-tree-alt:before {
  content: "\F400"; }

.fa-tree-christmas:before {
  content: "\F7DB"; }

.fa-tree-decorated:before {
  content: "\F7DC"; }

.fa-tree-large:before {
  content: "\F7DD"; }

.fa-tree-palm:before {
  content: "\F82B"; }

.fa-trees:before {
  content: "\F724"; }

.fa-trello:before {
  content: "\F181"; }

.fa-triangle:before {
  content: "\F2EC"; }

.fa-triangle-music:before {
  content: "\F8E2"; }

.fa-trophy:before {
  content: "\F091"; }

.fa-trophy-alt:before {
  content: "\F2EB"; }

.fa-truck:before {
  content: "\F0D1"; }

.fa-truck-container:before {
  content: "\F4DC"; }

.fa-truck-couch:before {
  content: "\F4DD"; }

.fa-truck-loading:before {
  content: "\F4DE"; }

.fa-truck-monster:before {
  content: "\F63B"; }

.fa-truck-moving:before {
  content: "\F4DF"; }

.fa-truck-pickup:before {
  content: "\F63C"; }

.fa-truck-plow:before {
  content: "\F7DE"; }

.fa-truck-ramp:before {
  content: "\F4E0"; }

.fa-trumpet:before {
  content: "\F8E3"; }

.fa-tshirt:before {
  content: "\F553"; }

.fa-tty:before {
  content: "\F1E4"; }

.fa-tumblr:before {
  content: "\F173"; }

.fa-tumblr-square:before {
  content: "\F174"; }

.fa-turkey:before {
  content: "\F725"; }

.fa-turntable:before {
  content: "\F8E4"; }

.fa-turtle:before {
  content: "\F726"; }

.fa-tv:before {
  content: "\F26C"; }

.fa-tv-alt:before {
  content: "\F8E5"; }

.fa-tv-music:before {
  content: "\F8E6"; }

.fa-tv-retro:before {
  content: "\F401"; }

.fa-twitch:before {
  content: "\F1E8"; }

.fa-twitter:before {
  content: "\F099"; }

.fa-twitter-square:before {
  content: "\F081"; }

.fa-typewriter:before {
  content: "\F8E7"; }

.fa-typo3:before {
  content: "\F42B"; }

.fa-uber:before {
  content: "\F402"; }

.fa-ubuntu:before {
  content: "\F7DF"; }

.fa-ufo:before {
  content: "\E047"; }

.fa-ufo-beam:before {
  content: "\E048"; }

.fa-uikit:before {
  content: "\F403"; }

.fa-umbraco:before {
  content: "\F8E8"; }

.fa-umbrella:before {
  content: "\F0E9"; }

.fa-umbrella-beach:before {
  content: "\F5CA"; }

.fa-uncharted:before {
  content: "\E084"; }

.fa-underline:before {
  content: "\F0CD"; }

.fa-undo:before {
  content: "\F0E2"; }

.fa-undo-alt:before {
  content: "\F2EA"; }

.fa-unicorn:before {
  content: "\F727"; }

.fa-union:before {
  content: "\F6A2"; }

.fa-uniregistry:before {
  content: "\F404"; }

.fa-unity:before {
  content: "\E049"; }

.fa-universal-access:before {
  content: "\F29A"; }

.fa-university:before {
  content: "\F19C"; }

.fa-unlink:before {
  content: "\F127"; }

.fa-unlock:before {
  content: "\F09C"; }

.fa-unlock-alt:before {
  content: "\F13E"; }

.fa-unsplash:before {
  content: "\E07C"; }

.fa-untappd:before {
  content: "\F405"; }

.fa-upload:before {
  content: "\F093"; }

.fa-ups:before {
  content: "\F7E0"; }

.fa-usb:before {
  content: "\F287"; }

.fa-usb-drive:before {
  content: "\F8E9"; }

.fa-usd-circle:before {
  content: "\F2E8"; }

.fa-usd-square:before {
  content: "\F2E9"; }

.fa-user:before {
  content: "\F007"; }

.fa-user-alien:before {
  content: "\E04A"; }

.fa-user-alt:before {
  content: "\F406"; }

.fa-user-alt-slash:before {
  content: "\F4FA"; }

.fa-user-astronaut:before {
  content: "\F4FB"; }

.fa-user-chart:before {
  content: "\F6A3"; }

.fa-user-check:before {
  content: "\F4FC"; }

.fa-user-circle:before {
  content: "\F2BD"; }

.fa-user-clock:before {
  content: "\F4FD"; }

.fa-user-cog:before {
  content: "\F4FE"; }

.fa-user-cowboy:before {
  content: "\F8EA"; }

.fa-user-crown:before {
  content: "\F6A4"; }

.fa-user-edit:before {
  content: "\F4FF"; }

.fa-user-friends:before {
  content: "\F500"; }

.fa-user-graduate:before {
  content: "\F501"; }

.fa-user-hard-hat:before {
  content: "\F82C"; }

.fa-user-headset:before {
  content: "\F82D"; }

.fa-user-injured:before {
  content: "\F728"; }

.fa-user-lock:before {
  content: "\F502"; }

.fa-user-md:before {
  content: "\F0F0"; }

.fa-user-md-chat:before {
  content: "\F82E"; }

.fa-user-minus:before {
  content: "\F503"; }

.fa-user-music:before {
  content: "\F8EB"; }

.fa-user-ninja:before {
  content: "\F504"; }

.fa-user-nurse:before {
  content: "\F82F"; }

.fa-user-plus:before {
  content: "\F234"; }

.fa-user-robot:before {
  content: "\E04B"; }

.fa-user-secret:before {
  content: "\F21B"; }

.fa-user-shield:before {
  content: "\F505"; }

.fa-user-slash:before {
  content: "\F506"; }

.fa-user-tag:before {
  content: "\F507"; }

.fa-user-tie:before {
  content: "\F508"; }

.fa-user-times:before {
  content: "\F235"; }

.fa-user-unlock:before {
  content: "\E058"; }

.fa-user-visor:before {
  content: "\E04C"; }

.fa-users:before {
  content: "\F0C0"; }

.fa-users-class:before {
  content: "\F63D"; }

.fa-users-cog:before {
  content: "\F509"; }

.fa-users-crown:before {
  content: "\F6A5"; }

.fa-users-medical:before {
  content: "\F830"; }

.fa-users-slash:before {
  content: "\E073"; }

.fa-usps:before {
  content: "\F7E1"; }

.fa-ussunnah:before {
  content: "\F407"; }

.fa-utensil-fork:before {
  content: "\F2E3"; }

.fa-utensil-knife:before {
  content: "\F2E4"; }

.fa-utensil-spoon:before {
  content: "\F2E5"; }

.fa-utensils:before {
  content: "\F2E7"; }

.fa-utensils-alt:before {
  content: "\F2E6"; }

.fa-vaadin:before {
  content: "\F408"; }

.fa-vacuum:before {
  content: "\E04D"; }

.fa-vacuum-robot:before {
  content: "\E04E"; }

.fa-value-absolute:before {
  content: "\F6A6"; }

.fa-vector-square:before {
  content: "\F5CB"; }

.fa-venus:before {
  content: "\F221"; }

.fa-venus-double:before {
  content: "\F226"; }

.fa-venus-mars:before {
  content: "\F228"; }

.fa-vest:before {
  content: "\E085"; }

.fa-vest-patches:before {
  content: "\E086"; }

.fa-vhs:before {
  content: "\F8EC"; }

.fa-viacoin:before {
  content: "\F237"; }

.fa-viadeo:before {
  content: "\F2A9"; }

.fa-viadeo-square:before {
  content: "\F2AA"; }

.fa-vial:before {
  content: "\F492"; }

.fa-vials:before {
  content: "\F493"; }

.fa-viber:before {
  content: "\F409"; }

.fa-video:before {
  content: "\F03D"; }

.fa-video-plus:before {
  content: "\F4E1"; }

.fa-video-slash:before {
  content: "\F4E2"; }

.fa-vihara:before {
  content: "\F6A7"; }

.fa-vimeo:before {
  content: "\F40A"; }

.fa-vimeo-square:before {
  content: "\F194"; }

.fa-vimeo-v:before {
  content: "\F27D"; }

.fa-vine:before {
  content: "\F1CA"; }

.fa-violin:before {
  content: "\F8ED"; }

.fa-virus:before {
  content: "\E074"; }

.fa-virus-slash:before {
  content: "\E075"; }

.fa-viruses:before {
  content: "\E076"; }

.fa-vk:before {
  content: "\F189"; }

.fa-vnv:before {
  content: "\F40B"; }

.fa-voicemail:before {
  content: "\F897"; }

.fa-volcano:before {
  content: "\F770"; }

.fa-volleyball-ball:before {
  content: "\F45F"; }

.fa-volume:before {
  content: "\F6A8"; }

.fa-volume-down:before {
  content: "\F027"; }

.fa-volume-mute:before {
  content: "\F6A9"; }

.fa-volume-off:before {
  content: "\F026"; }

.fa-volume-slash:before {
  content: "\F2E2"; }

.fa-volume-up:before {
  content: "\F028"; }

.fa-vote-nay:before {
  content: "\F771"; }

.fa-vote-yea:before {
  content: "\F772"; }

.fa-vr-cardboard:before {
  content: "\F729"; }

.fa-vuejs:before {
  content: "\F41F"; }

.fa-wagon-covered:before {
  content: "\F8EE"; }

.fa-walker:before {
  content: "\F831"; }

.fa-walkie-talkie:before {
  content: "\F8EF"; }

.fa-walking:before {
  content: "\F554"; }

.fa-wallet:before {
  content: "\F555"; }

.fa-wand:before {
  content: "\F72A"; }

.fa-wand-magic:before {
  content: "\F72B"; }

.fa-warehouse:before {
  content: "\F494"; }

.fa-warehouse-alt:before {
  content: "\F495"; }

.fa-washer:before {
  content: "\F898"; }

.fa-watch:before {
  content: "\F2E1"; }

.fa-watch-calculator:before {
  content: "\F8F0"; }

.fa-watch-fitness:before {
  content: "\F63E"; }

.fa-watchman-monitoring:before {
  content: "\E087"; }

.fa-water:before {
  content: "\F773"; }

.fa-water-lower:before {
  content: "\F774"; }

.fa-water-rise:before {
  content: "\F775"; }

.fa-wave-sine:before {
  content: "\F899"; }

.fa-wave-square:before {
  content: "\F83E"; }

.fa-wave-triangle:before {
  content: "\F89A"; }

.fa-waveform:before {
  content: "\F8F1"; }

.fa-waveform-path:before {
  content: "\F8F2"; }

.fa-waze:before {
  content: "\F83F"; }

.fa-webcam:before {
  content: "\F832"; }

.fa-webcam-slash:before {
  content: "\F833"; }

.fa-weebly:before {
  content: "\F5CC"; }

.fa-weibo:before {
  content: "\F18A"; }

.fa-weight:before {
  content: "\F496"; }

.fa-weight-hanging:before {
  content: "\F5CD"; }

.fa-weixin:before {
  content: "\F1D7"; }

.fa-whale:before {
  content: "\F72C"; }

.fa-whatsapp:before {
  content: "\F232"; }

.fa-whatsapp-square:before {
  content: "\F40C"; }

.fa-wheat:before {
  content: "\F72D"; }

.fa-wheelchair:before {
  content: "\F193"; }

.fa-whistle:before {
  content: "\F460"; }

.fa-whmcs:before {
  content: "\F40D"; }

.fa-wifi:before {
  content: "\F1EB"; }

.fa-wifi-1:before {
  content: "\F6AA"; }

.fa-wifi-2:before {
  content: "\F6AB"; }

.fa-wifi-slash:before {
  content: "\F6AC"; }

.fa-wikipedia-w:before {
  content: "\F266"; }

.fa-wind:before {
  content: "\F72E"; }

.fa-wind-turbine:before {
  content: "\F89B"; }

.fa-wind-warning:before {
  content: "\F776"; }

.fa-window:before {
  content: "\F40E"; }

.fa-window-alt:before {
  content: "\F40F"; }

.fa-window-close:before {
  content: "\F410"; }

.fa-window-frame:before {
  content: "\E04F"; }

.fa-window-frame-open:before {
  content: "\E050"; }

.fa-window-maximize:before {
  content: "\F2D0"; }

.fa-window-minimize:before {
  content: "\F2D1"; }

.fa-window-restore:before {
  content: "\F2D2"; }

.fa-windows:before {
  content: "\F17A"; }

.fa-windsock:before {
  content: "\F777"; }

.fa-wine-bottle:before {
  content: "\F72F"; }

.fa-wine-glass:before {
  content: "\F4E3"; }

.fa-wine-glass-alt:before {
  content: "\F5CE"; }

.fa-wix:before {
  content: "\F5CF"; }

.fa-wizards-of-the-coast:before {
  content: "\F730"; }

.fa-wodu:before {
  content: "\E088"; }

.fa-wolf-pack-battalion:before {
  content: "\F514"; }

.fa-won-sign:before {
  content: "\F159"; }

.fa-wordpress:before {
  content: "\F19A"; }

.fa-wordpress-simple:before {
  content: "\F411"; }

.fa-wpbeginner:before {
  content: "\F297"; }

.fa-wpexplorer:before {
  content: "\F2DE"; }

.fa-wpforms:before {
  content: "\F298"; }

.fa-wpressr:before {
  content: "\F3E4"; }

.fa-wreath:before {
  content: "\F7E2"; }

.fa-wrench:before {
  content: "\F0AD"; }

.fa-x-ray:before {
  content: "\F497"; }

.fa-xbox:before {
  content: "\F412"; }

.fa-xing:before {
  content: "\F168"; }

.fa-xing-square:before {
  content: "\F169"; }

.fa-y-combinator:before {
  content: "\F23B"; }

.fa-yahoo:before {
  content: "\F19E"; }

.fa-yammer:before {
  content: "\F840"; }

.fa-yandex:before {
  content: "\F413"; }

.fa-yandex-international:before {
  content: "\F414"; }

.fa-yarn:before {
  content: "\F7E3"; }

.fa-yelp:before {
  content: "\F1E9"; }

.fa-yen-sign:before {
  content: "\F157"; }

.fa-yin-yang:before {
  content: "\F6AD"; }

.fa-yoast:before {
  content: "\F2B1"; }

.fa-youtube:before {
  content: "\F167"; }

.fa-youtube-square:before {
  content: "\F431"; }

.fa-zhihu:before {
  content: "\F63F"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/static/media/fa-brands-400.44893603.eot);
  src: url(/static/media/fa-brands-400.44893603.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-brands-400.f4120760.woff2) format("woff2"), url(/static/media/fa-brands-400.fe6bd13b.woff) format("woff"), url(/static/media/fa-brands-400.198bce06.ttf) format("truetype"), url(/static/media/fa-brands-400.5bfa0017.svg#fontawesome) format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(/static/media/fa-duotone-900.60d8eaf6.eot);
  src: url(/static/media/fa-duotone-900.60d8eaf6.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-duotone-900.923bc494.woff2) format("woff2"), url(/static/media/fa-duotone-900.2af34257.woff) format("woff"), url(/static/media/fa-duotone-900.6cb8e311.ttf) format("truetype"), url(/static/media/fa-duotone-900.314e2724.svg#fontawesome) format("svg"); }

.fad {
  position: relative;
  font-family: 'Font Awesome 5 Duotone';
  font-weight: 900; }

.fad:before {
  position: absolute;
  color: inherit;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.fad:after {
  color: inherit;
  color: var(--fa-secondary-color, inherit);
  opacity: 0.4;
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad:before,
.fad.fa-swap-opacity:before {
  opacity: 0.4;
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad:after,
.fad.fa-swap-opacity:after {
  opacity: 1;
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.fad.fa-inverse {
  color: #fff; }

.fad.fa-stack-1x, .fad.fa-stack-2x {
  position: absolute; }

.fad.fa-stack-1x:before,
.fad.fa-stack-2x:before,
.fad.fa-fw:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.fad.fa-abacus:after {
  content: "\10F640"; }

.fad.fa-acorn:after {
  content: "\10F6AE"; }

.fad.fa-ad:after {
  content: "\10F641"; }

.fad.fa-address-book:after {
  content: "\10F2B9"; }

.fad.fa-address-card:after {
  content: "\10F2BB"; }

.fad.fa-adjust:after {
  content: "\10F042"; }

.fad.fa-air-conditioner:after {
  content: "\10F8F4"; }

.fad.fa-air-freshener:after {
  content: "\10F5D0"; }

.fad.fa-alarm-clock:after {
  content: "\10F34E"; }

.fad.fa-alarm-exclamation:after {
  content: "\10F843"; }

.fad.fa-alarm-plus:after {
  content: "\10F844"; }

.fad.fa-alarm-snooze:after {
  content: "\10F845"; }

.fad.fa-album:after {
  content: "\10F89F"; }

.fad.fa-album-collection:after {
  content: "\10F8A0"; }

.fad.fa-alicorn:after {
  content: "\10F6B0"; }

.fad.fa-alien:after {
  content: "\10F8F5"; }

.fad.fa-alien-monster:after {
  content: "\10F8F6"; }

.fad.fa-align-center:after {
  content: "\10F037"; }

.fad.fa-align-justify:after {
  content: "\10F039"; }

.fad.fa-align-left:after {
  content: "\10F036"; }

.fad.fa-align-right:after {
  content: "\10F038"; }

.fad.fa-align-slash:after {
  content: "\10F846"; }

.fad.fa-allergies:after {
  content: "\10F461"; }

.fad.fa-ambulance:after {
  content: "\10F0F9"; }

.fad.fa-american-sign-language-interpreting:after {
  content: "\10F2A3"; }

.fad.fa-amp-guitar:after {
  content: "\10F8A1"; }

.fad.fa-analytics:after {
  content: "\10F643"; }

.fad.fa-anchor:after {
  content: "\10F13D"; }

.fad.fa-angel:after {
  content: "\10F779"; }

.fad.fa-angle-double-down:after {
  content: "\10F103"; }

.fad.fa-angle-double-left:after {
  content: "\10F100"; }

.fad.fa-angle-double-right:after {
  content: "\10F101"; }

.fad.fa-angle-double-up:after {
  content: "\10F102"; }

.fad.fa-angle-down:after {
  content: "\10F107"; }

.fad.fa-angle-left:after {
  content: "\10F104"; }

.fad.fa-angle-right:after {
  content: "\10F105"; }

.fad.fa-angle-up:after {
  content: "\10F106"; }

.fad.fa-angry:after {
  content: "\10F556"; }

.fad.fa-ankh:after {
  content: "\10F644"; }

.fad.fa-apple-alt:after {
  content: "\10F5D1"; }

.fad.fa-apple-crate:after {
  content: "\10F6B1"; }

.fad.fa-archive:after {
  content: "\10F187"; }

.fad.fa-archway:after {
  content: "\10F557"; }

.fad.fa-arrow-alt-circle-down:after {
  content: "\10F358"; }

.fad.fa-arrow-alt-circle-left:after {
  content: "\10F359"; }

.fad.fa-arrow-alt-circle-right:after {
  content: "\10F35A"; }

.fad.fa-arrow-alt-circle-up:after {
  content: "\10F35B"; }

.fad.fa-arrow-alt-down:after {
  content: "\10F354"; }

.fad.fa-arrow-alt-from-bottom:after {
  content: "\10F346"; }

.fad.fa-arrow-alt-from-left:after {
  content: "\10F347"; }

.fad.fa-arrow-alt-from-right:after {
  content: "\10F348"; }

.fad.fa-arrow-alt-from-top:after {
  content: "\10F349"; }

.fad.fa-arrow-alt-left:after {
  content: "\10F355"; }

.fad.fa-arrow-alt-right:after {
  content: "\10F356"; }

.fad.fa-arrow-alt-square-down:after {
  content: "\10F350"; }

.fad.fa-arrow-alt-square-left:after {
  content: "\10F351"; }

.fad.fa-arrow-alt-square-right:after {
  content: "\10F352"; }

.fad.fa-arrow-alt-square-up:after {
  content: "\10F353"; }

.fad.fa-arrow-alt-to-bottom:after {
  content: "\10F34A"; }

.fad.fa-arrow-alt-to-left:after {
  content: "\10F34B"; }

.fad.fa-arrow-alt-to-right:after {
  content: "\10F34C"; }

.fad.fa-arrow-alt-to-top:after {
  content: "\10F34D"; }

.fad.fa-arrow-alt-up:after {
  content: "\10F357"; }

.fad.fa-arrow-circle-down:after {
  content: "\10F0AB"; }

.fad.fa-arrow-circle-left:after {
  content: "\10F0A8"; }

.fad.fa-arrow-circle-right:after {
  content: "\10F0A9"; }

.fad.fa-arrow-circle-up:after {
  content: "\10F0AA"; }

.fad.fa-arrow-down:after {
  content: "\10F063"; }

.fad.fa-arrow-from-bottom:after {
  content: "\10F342"; }

.fad.fa-arrow-from-left:after {
  content: "\10F343"; }

.fad.fa-arrow-from-right:after {
  content: "\10F344"; }

.fad.fa-arrow-from-top:after {
  content: "\10F345"; }

.fad.fa-arrow-left:after {
  content: "\10F060"; }

.fad.fa-arrow-right:after {
  content: "\10F061"; }

.fad.fa-arrow-square-down:after {
  content: "\10F339"; }

.fad.fa-arrow-square-left:after {
  content: "\10F33A"; }

.fad.fa-arrow-square-right:after {
  content: "\10F33B"; }

.fad.fa-arrow-square-up:after {
  content: "\10F33C"; }

.fad.fa-arrow-to-bottom:after {
  content: "\10F33D"; }

.fad.fa-arrow-to-left:after {
  content: "\10F33E"; }

.fad.fa-arrow-to-right:after {
  content: "\10F340"; }

.fad.fa-arrow-to-top:after {
  content: "\10F341"; }

.fad.fa-arrow-up:after {
  content: "\10F062"; }

.fad.fa-arrows:after {
  content: "\10F047"; }

.fad.fa-arrows-alt:after {
  content: "\10F0B2"; }

.fad.fa-arrows-alt-h:after {
  content: "\10F337"; }

.fad.fa-arrows-alt-v:after {
  content: "\10F338"; }

.fad.fa-arrows-h:after {
  content: "\10F07E"; }

.fad.fa-arrows-v:after {
  content: "\10F07D"; }

.fad.fa-assistive-listening-systems:after {
  content: "\10F2A2"; }

.fad.fa-asterisk:after {
  content: "\10F069"; }

.fad.fa-at:after {
  content: "\10F1FA"; }

.fad.fa-atlas:after {
  content: "\10F558"; }

.fad.fa-atom:after {
  content: "\10F5D2"; }

.fad.fa-atom-alt:after {
  content: "\10F5D3"; }

.fad.fa-audio-description:after {
  content: "\10F29E"; }

.fad.fa-award:after {
  content: "\10F559"; }

.fad.fa-axe:after {
  content: "\10F6B2"; }

.fad.fa-axe-battle:after {
  content: "\10F6B3"; }

.fad.fa-baby:after {
  content: "\10F77C"; }

.fad.fa-baby-carriage:after {
  content: "\10F77D"; }

.fad.fa-backpack:after {
  content: "\10F5D4"; }

.fad.fa-backspace:after {
  content: "\10F55A"; }

.fad.fa-backward:after {
  content: "\10F04A"; }

.fad.fa-bacon:after {
  content: "\10F7E5"; }

.fad.fa-bacteria:after {
  content: "\10E059"; }

.fad.fa-bacterium:after {
  content: "\10E05A"; }

.fad.fa-badge:after {
  content: "\10F335"; }

.fad.fa-badge-check:after {
  content: "\10F336"; }

.fad.fa-badge-dollar:after {
  content: "\10F645"; }

.fad.fa-badge-percent:after {
  content: "\10F646"; }

.fad.fa-badge-sheriff:after {
  content: "\10F8A2"; }

.fad.fa-badger-honey:after {
  content: "\10F6B4"; }

.fad.fa-bags-shopping:after {
  content: "\10F847"; }

.fad.fa-bahai:after {
  content: "\10F666"; }

.fad.fa-balance-scale:after {
  content: "\10F24E"; }

.fad.fa-balance-scale-left:after {
  content: "\10F515"; }

.fad.fa-balance-scale-right:after {
  content: "\10F516"; }

.fad.fa-ball-pile:after {
  content: "\10F77E"; }

.fad.fa-ballot:after {
  content: "\10F732"; }

.fad.fa-ballot-check:after {
  content: "\10F733"; }

.fad.fa-ban:after {
  content: "\10F05E"; }

.fad.fa-band-aid:after {
  content: "\10F462"; }

.fad.fa-banjo:after {
  content: "\10F8A3"; }

.fad.fa-barcode:after {
  content: "\10F02A"; }

.fad.fa-barcode-alt:after {
  content: "\10F463"; }

.fad.fa-barcode-read:after {
  content: "\10F464"; }

.fad.fa-barcode-scan:after {
  content: "\10F465"; }

.fad.fa-bars:after {
  content: "\10F0C9"; }

.fad.fa-baseball:after {
  content: "\10F432"; }

.fad.fa-baseball-ball:after {
  content: "\10F433"; }

.fad.fa-basketball-ball:after {
  content: "\10F434"; }

.fad.fa-basketball-hoop:after {
  content: "\10F435"; }

.fad.fa-bat:after {
  content: "\10F6B5"; }

.fad.fa-bath:after {
  content: "\10F2CD"; }

.fad.fa-battery-bolt:after {
  content: "\10F376"; }

.fad.fa-battery-empty:after {
  content: "\10F244"; }

.fad.fa-battery-full:after {
  content: "\10F240"; }

.fad.fa-battery-half:after {
  content: "\10F242"; }

.fad.fa-battery-quarter:after {
  content: "\10F243"; }

.fad.fa-battery-slash:after {
  content: "\10F377"; }

.fad.fa-battery-three-quarters:after {
  content: "\10F241"; }

.fad.fa-bed:after {
  content: "\10F236"; }

.fad.fa-bed-alt:after {
  content: "\10F8F7"; }

.fad.fa-bed-bunk:after {
  content: "\10F8F8"; }

.fad.fa-bed-empty:after {
  content: "\10F8F9"; }

.fad.fa-beer:after {
  content: "\10F0FC"; }

.fad.fa-bell:after {
  content: "\10F0F3"; }

.fad.fa-bell-exclamation:after {
  content: "\10F848"; }

.fad.fa-bell-on:after {
  content: "\10F8FA"; }

.fad.fa-bell-plus:after {
  content: "\10F849"; }

.fad.fa-bell-school:after {
  content: "\10F5D5"; }

.fad.fa-bell-school-slash:after {
  content: "\10F5D6"; }

.fad.fa-bell-slash:after {
  content: "\10F1F6"; }

.fad.fa-bells:after {
  content: "\10F77F"; }

.fad.fa-betamax:after {
  content: "\10F8A4"; }

.fad.fa-bezier-curve:after {
  content: "\10F55B"; }

.fad.fa-bible:after {
  content: "\10F647"; }

.fad.fa-bicycle:after {
  content: "\10F206"; }

.fad.fa-biking:after {
  content: "\10F84A"; }

.fad.fa-biking-mountain:after {
  content: "\10F84B"; }

.fad.fa-binoculars:after {
  content: "\10F1E5"; }

.fad.fa-biohazard:after {
  content: "\10F780"; }

.fad.fa-birthday-cake:after {
  content: "\10F1FD"; }

.fad.fa-blanket:after {
  content: "\10F498"; }

.fad.fa-blender:after {
  content: "\10F517"; }

.fad.fa-blender-phone:after {
  content: "\10F6B6"; }

.fad.fa-blind:after {
  content: "\10F29D"; }

.fad.fa-blinds:after {
  content: "\10F8FB"; }

.fad.fa-blinds-open:after {
  content: "\10F8FC"; }

.fad.fa-blinds-raised:after {
  content: "\10F8FD"; }

.fad.fa-blog:after {
  content: "\10F781"; }

.fad.fa-bold:after {
  content: "\10F032"; }

.fad.fa-bolt:after {
  content: "\10F0E7"; }

.fad.fa-bomb:after {
  content: "\10F1E2"; }

.fad.fa-bone:after {
  content: "\10F5D7"; }

.fad.fa-bone-break:after {
  content: "\10F5D8"; }

.fad.fa-bong:after {
  content: "\10F55C"; }

.fad.fa-book:after {
  content: "\10F02D"; }

.fad.fa-book-alt:after {
  content: "\10F5D9"; }

.fad.fa-book-dead:after {
  content: "\10F6B7"; }

.fad.fa-book-heart:after {
  content: "\10F499"; }

.fad.fa-book-medical:after {
  content: "\10F7E6"; }

.fad.fa-book-open:after {
  content: "\10F518"; }

.fad.fa-book-reader:after {
  content: "\10F5DA"; }

.fad.fa-book-spells:after {
  content: "\10F6B8"; }

.fad.fa-book-user:after {
  content: "\10F7E7"; }

.fad.fa-bookmark:after {
  content: "\10F02E"; }

.fad.fa-books:after {
  content: "\10F5DB"; }

.fad.fa-books-medical:after {
  content: "\10F7E8"; }

.fad.fa-boombox:after {
  content: "\10F8A5"; }

.fad.fa-boot:after {
  content: "\10F782"; }

.fad.fa-booth-curtain:after {
  content: "\10F734"; }

.fad.fa-border-all:after {
  content: "\10F84C"; }

.fad.fa-border-bottom:after {
  content: "\10F84D"; }

.fad.fa-border-center-h:after {
  content: "\10F89C"; }

.fad.fa-border-center-v:after {
  content: "\10F89D"; }

.fad.fa-border-inner:after {
  content: "\10F84E"; }

.fad.fa-border-left:after {
  content: "\10F84F"; }

.fad.fa-border-none:after {
  content: "\10F850"; }

.fad.fa-border-outer:after {
  content: "\10F851"; }

.fad.fa-border-right:after {
  content: "\10F852"; }

.fad.fa-border-style:after {
  content: "\10F853"; }

.fad.fa-border-style-alt:after {
  content: "\10F854"; }

.fad.fa-border-top:after {
  content: "\10F855"; }

.fad.fa-bow-arrow:after {
  content: "\10F6B9"; }

.fad.fa-bowling-ball:after {
  content: "\10F436"; }

.fad.fa-bowling-pins:after {
  content: "\10F437"; }

.fad.fa-box:after {
  content: "\10F466"; }

.fad.fa-box-alt:after {
  content: "\10F49A"; }

.fad.fa-box-ballot:after {
  content: "\10F735"; }

.fad.fa-box-check:after {
  content: "\10F467"; }

.fad.fa-box-fragile:after {
  content: "\10F49B"; }

.fad.fa-box-full:after {
  content: "\10F49C"; }

.fad.fa-box-heart:after {
  content: "\10F49D"; }

.fad.fa-box-open:after {
  content: "\10F49E"; }

.fad.fa-box-tissue:after {
  content: "\10E05B"; }

.fad.fa-box-up:after {
  content: "\10F49F"; }

.fad.fa-box-usd:after {
  content: "\10F4A0"; }

.fad.fa-boxes:after {
  content: "\10F468"; }

.fad.fa-boxes-alt:after {
  content: "\10F4A1"; }

.fad.fa-boxing-glove:after {
  content: "\10F438"; }

.fad.fa-brackets:after {
  content: "\10F7E9"; }

.fad.fa-brackets-curly:after {
  content: "\10F7EA"; }

.fad.fa-braille:after {
  content: "\10F2A1"; }

.fad.fa-brain:after {
  content: "\10F5DC"; }

.fad.fa-bread-loaf:after {
  content: "\10F7EB"; }

.fad.fa-bread-slice:after {
  content: "\10F7EC"; }

.fad.fa-briefcase:after {
  content: "\10F0B1"; }

.fad.fa-briefcase-medical:after {
  content: "\10F469"; }

.fad.fa-bring-forward:after {
  content: "\10F856"; }

.fad.fa-bring-front:after {
  content: "\10F857"; }

.fad.fa-broadcast-tower:after {
  content: "\10F519"; }

.fad.fa-broom:after {
  content: "\10F51A"; }

.fad.fa-browser:after {
  content: "\10F37E"; }

.fad.fa-brush:after {
  content: "\10F55D"; }

.fad.fa-bug:after {
  content: "\10F188"; }

.fad.fa-building:after {
  content: "\10F1AD"; }

.fad.fa-bullhorn:after {
  content: "\10F0A1"; }

.fad.fa-bullseye:after {
  content: "\10F140"; }

.fad.fa-bullseye-arrow:after {
  content: "\10F648"; }

.fad.fa-bullseye-pointer:after {
  content: "\10F649"; }

.fad.fa-burger-soda:after {
  content: "\10F858"; }

.fad.fa-burn:after {
  content: "\10F46A"; }

.fad.fa-burrito:after {
  content: "\10F7ED"; }

.fad.fa-bus:after {
  content: "\10F207"; }

.fad.fa-bus-alt:after {
  content: "\10F55E"; }

.fad.fa-bus-school:after {
  content: "\10F5DD"; }

.fad.fa-business-time:after {
  content: "\10F64A"; }

.fad.fa-cabinet-filing:after {
  content: "\10F64B"; }

.fad.fa-cactus:after {
  content: "\10F8A7"; }

.fad.fa-calculator:after {
  content: "\10F1EC"; }

.fad.fa-calculator-alt:after {
  content: "\10F64C"; }

.fad.fa-calendar:after {
  content: "\10F133"; }

.fad.fa-calendar-alt:after {
  content: "\10F073"; }

.fad.fa-calendar-check:after {
  content: "\10F274"; }

.fad.fa-calendar-day:after {
  content: "\10F783"; }

.fad.fa-calendar-edit:after {
  content: "\10F333"; }

.fad.fa-calendar-exclamation:after {
  content: "\10F334"; }

.fad.fa-calendar-minus:after {
  content: "\10F272"; }

.fad.fa-calendar-plus:after {
  content: "\10F271"; }

.fad.fa-calendar-star:after {
  content: "\10F736"; }

.fad.fa-calendar-times:after {
  content: "\10F273"; }

.fad.fa-calendar-week:after {
  content: "\10F784"; }

.fad.fa-camcorder:after {
  content: "\10F8A8"; }

.fad.fa-camera:after {
  content: "\10F030"; }

.fad.fa-camera-alt:after {
  content: "\10F332"; }

.fad.fa-camera-home:after {
  content: "\10F8FE"; }

.fad.fa-camera-movie:after {
  content: "\10F8A9"; }

.fad.fa-camera-polaroid:after {
  content: "\10F8AA"; }

.fad.fa-camera-retro:after {
  content: "\10F083"; }

.fad.fa-campfire:after {
  content: "\10F6BA"; }

.fad.fa-campground:after {
  content: "\10F6BB"; }

.fad.fa-candle-holder:after {
  content: "\10F6BC"; }

.fad.fa-candy-cane:after {
  content: "\10F786"; }

.fad.fa-candy-corn:after {
  content: "\10F6BD"; }

.fad.fa-cannabis:after {
  content: "\10F55F"; }

.fad.fa-capsules:after {
  content: "\10F46B"; }

.fad.fa-car:after {
  content: "\10F1B9"; }

.fad.fa-car-alt:after {
  content: "\10F5DE"; }

.fad.fa-car-battery:after {
  content: "\10F5DF"; }

.fad.fa-car-building:after {
  content: "\10F859"; }

.fad.fa-car-bump:after {
  content: "\10F5E0"; }

.fad.fa-car-bus:after {
  content: "\10F85A"; }

.fad.fa-car-crash:after {
  content: "\10F5E1"; }

.fad.fa-car-garage:after {
  content: "\10F5E2"; }

.fad.fa-car-mechanic:after {
  content: "\10F5E3"; }

.fad.fa-car-side:after {
  content: "\10F5E4"; }

.fad.fa-car-tilt:after {
  content: "\10F5E5"; }

.fad.fa-car-wash:after {
  content: "\10F5E6"; }

.fad.fa-caravan:after {
  content: "\10F8FF"; }

.fad.fa-caravan-alt:after {
  content: "\10E000"; }

.fad.fa-caret-circle-down:after {
  content: "\10F32D"; }

.fad.fa-caret-circle-left:after {
  content: "\10F32E"; }

.fad.fa-caret-circle-right:after {
  content: "\10F330"; }

.fad.fa-caret-circle-up:after {
  content: "\10F331"; }

.fad.fa-caret-down:after {
  content: "\10F0D7"; }

.fad.fa-caret-left:after {
  content: "\10F0D9"; }

.fad.fa-caret-right:after {
  content: "\10F0DA"; }

.fad.fa-caret-square-down:after {
  content: "\10F150"; }

.fad.fa-caret-square-left:after {
  content: "\10F191"; }

.fad.fa-caret-square-right:after {
  content: "\10F152"; }

.fad.fa-caret-square-up:after {
  content: "\10F151"; }

.fad.fa-caret-up:after {
  content: "\10F0D8"; }

.fad.fa-carrot:after {
  content: "\10F787"; }

.fad.fa-cars:after {
  content: "\10F85B"; }

.fad.fa-cart-arrow-down:after {
  content: "\10F218"; }

.fad.fa-cart-plus:after {
  content: "\10F217"; }

.fad.fa-cash-register:after {
  content: "\10F788"; }

.fad.fa-cassette-tape:after {
  content: "\10F8AB"; }

.fad.fa-cat:after {
  content: "\10F6BE"; }

.fad.fa-cat-space:after {
  content: "\10E001"; }

.fad.fa-cauldron:after {
  content: "\10F6BF"; }

.fad.fa-cctv:after {
  content: "\10F8AC"; }

.fad.fa-certificate:after {
  content: "\10F0A3"; }

.fad.fa-chair:after {
  content: "\10F6C0"; }

.fad.fa-chair-office:after {
  content: "\10F6C1"; }

.fad.fa-chalkboard:after {
  content: "\10F51B"; }

.fad.fa-chalkboard-teacher:after {
  content: "\10F51C"; }

.fad.fa-charging-station:after {
  content: "\10F5E7"; }

.fad.fa-chart-area:after {
  content: "\10F1FE"; }

.fad.fa-chart-bar:after {
  content: "\10F080"; }

.fad.fa-chart-line:after {
  content: "\10F201"; }

.fad.fa-chart-line-down:after {
  content: "\10F64D"; }

.fad.fa-chart-network:after {
  content: "\10F78A"; }

.fad.fa-chart-pie:after {
  content: "\10F200"; }

.fad.fa-chart-pie-alt:after {
  content: "\10F64E"; }

.fad.fa-chart-scatter:after {
  content: "\10F7EE"; }

.fad.fa-check:after {
  content: "\10F00C"; }

.fad.fa-check-circle:after {
  content: "\10F058"; }

.fad.fa-check-double:after {
  content: "\10F560"; }

.fad.fa-check-square:after {
  content: "\10F14A"; }

.fad.fa-cheese:after {
  content: "\10F7EF"; }

.fad.fa-cheese-swiss:after {
  content: "\10F7F0"; }

.fad.fa-cheeseburger:after {
  content: "\10F7F1"; }

.fad.fa-chess:after {
  content: "\10F439"; }

.fad.fa-chess-bishop:after {
  content: "\10F43A"; }

.fad.fa-chess-bishop-alt:after {
  content: "\10F43B"; }

.fad.fa-chess-board:after {
  content: "\10F43C"; }

.fad.fa-chess-clock:after {
  content: "\10F43D"; }

.fad.fa-chess-clock-alt:after {
  content: "\10F43E"; }

.fad.fa-chess-king:after {
  content: "\10F43F"; }

.fad.fa-chess-king-alt:after {
  content: "\10F440"; }

.fad.fa-chess-knight:after {
  content: "\10F441"; }

.fad.fa-chess-knight-alt:after {
  content: "\10F442"; }

.fad.fa-chess-pawn:after {
  content: "\10F443"; }

.fad.fa-chess-pawn-alt:after {
  content: "\10F444"; }

.fad.fa-chess-queen:after {
  content: "\10F445"; }

.fad.fa-chess-queen-alt:after {
  content: "\10F446"; }

.fad.fa-chess-rook:after {
  content: "\10F447"; }

.fad.fa-chess-rook-alt:after {
  content: "\10F448"; }

.fad.fa-chevron-circle-down:after {
  content: "\10F13A"; }

.fad.fa-chevron-circle-left:after {
  content: "\10F137"; }

.fad.fa-chevron-circle-right:after {
  content: "\10F138"; }

.fad.fa-chevron-circle-up:after {
  content: "\10F139"; }

.fad.fa-chevron-double-down:after {
  content: "\10F322"; }

.fad.fa-chevron-double-left:after {
  content: "\10F323"; }

.fad.fa-chevron-double-right:after {
  content: "\10F324"; }

.fad.fa-chevron-double-up:after {
  content: "\10F325"; }

.fad.fa-chevron-down:after {
  content: "\10F078"; }

.fad.fa-chevron-left:after {
  content: "\10F053"; }

.fad.fa-chevron-right:after {
  content: "\10F054"; }

.fad.fa-chevron-square-down:after {
  content: "\10F329"; }

.fad.fa-chevron-square-left:after {
  content: "\10F32A"; }

.fad.fa-chevron-square-right:after {
  content: "\10F32B"; }

.fad.fa-chevron-square-up:after {
  content: "\10F32C"; }

.fad.fa-chevron-up:after {
  content: "\10F077"; }

.fad.fa-child:after {
  content: "\10F1AE"; }

.fad.fa-chimney:after {
  content: "\10F78B"; }

.fad.fa-church:after {
  content: "\10F51D"; }

.fad.fa-circle:after {
  content: "\10F111"; }

.fad.fa-circle-notch:after {
  content: "\10F1CE"; }

.fad.fa-city:after {
  content: "\10F64F"; }

.fad.fa-clarinet:after {
  content: "\10F8AD"; }

.fad.fa-claw-marks:after {
  content: "\10F6C2"; }

.fad.fa-clinic-medical:after {
  content: "\10F7F2"; }

.fad.fa-clipboard:after {
  content: "\10F328"; }

.fad.fa-clipboard-check:after {
  content: "\10F46C"; }

.fad.fa-clipboard-list:after {
  content: "\10F46D"; }

.fad.fa-clipboard-list-check:after {
  content: "\10F737"; }

.fad.fa-clipboard-prescription:after {
  content: "\10F5E8"; }

.fad.fa-clipboard-user:after {
  content: "\10F7F3"; }

.fad.fa-clock:after {
  content: "\10F017"; }

.fad.fa-clone:after {
  content: "\10F24D"; }

.fad.fa-closed-captioning:after {
  content: "\10F20A"; }

.fad.fa-cloud:after {
  content: "\10F0C2"; }

.fad.fa-cloud-download:after {
  content: "\10F0ED"; }

.fad.fa-cloud-download-alt:after {
  content: "\10F381"; }

.fad.fa-cloud-drizzle:after {
  content: "\10F738"; }

.fad.fa-cloud-hail:after {
  content: "\10F739"; }

.fad.fa-cloud-hail-mixed:after {
  content: "\10F73A"; }

.fad.fa-cloud-meatball:after {
  content: "\10F73B"; }

.fad.fa-cloud-moon:after {
  content: "\10F6C3"; }

.fad.fa-cloud-moon-rain:after {
  content: "\10F73C"; }

.fad.fa-cloud-music:after {
  content: "\10F8AE"; }

.fad.fa-cloud-rain:after {
  content: "\10F73D"; }

.fad.fa-cloud-rainbow:after {
  content: "\10F73E"; }

.fad.fa-cloud-showers:after {
  content: "\10F73F"; }

.fad.fa-cloud-showers-heavy:after {
  content: "\10F740"; }

.fad.fa-cloud-sleet:after {
  content: "\10F741"; }

.fad.fa-cloud-snow:after {
  content: "\10F742"; }

.fad.fa-cloud-sun:after {
  content: "\10F6C4"; }

.fad.fa-cloud-sun-rain:after {
  content: "\10F743"; }

.fad.fa-cloud-upload:after {
  content: "\10F0EE"; }

.fad.fa-cloud-upload-alt:after {
  content: "\10F382"; }

.fad.fa-clouds:after {
  content: "\10F744"; }

.fad.fa-clouds-moon:after {
  content: "\10F745"; }

.fad.fa-clouds-sun:after {
  content: "\10F746"; }

.fad.fa-club:after {
  content: "\10F327"; }

.fad.fa-cocktail:after {
  content: "\10F561"; }

.fad.fa-code:after {
  content: "\10F121"; }

.fad.fa-code-branch:after {
  content: "\10F126"; }

.fad.fa-code-commit:after {
  content: "\10F386"; }

.fad.fa-code-merge:after {
  content: "\10F387"; }

.fad.fa-coffee:after {
  content: "\10F0F4"; }

.fad.fa-coffee-pot:after {
  content: "\10E002"; }

.fad.fa-coffee-togo:after {
  content: "\10F6C5"; }

.fad.fa-coffin:after {
  content: "\10F6C6"; }

.fad.fa-coffin-cross:after {
  content: "\10E051"; }

.fad.fa-cog:after {
  content: "\10F013"; }

.fad.fa-cogs:after {
  content: "\10F085"; }

.fad.fa-coin:after {
  content: "\10F85C"; }

.fad.fa-coins:after {
  content: "\10F51E"; }

.fad.fa-columns:after {
  content: "\10F0DB"; }

.fad.fa-comet:after {
  content: "\10E003"; }

.fad.fa-comment:after {
  content: "\10F075"; }

.fad.fa-comment-alt:after {
  content: "\10F27A"; }

.fad.fa-comment-alt-check:after {
  content: "\10F4A2"; }

.fad.fa-comment-alt-dollar:after {
  content: "\10F650"; }

.fad.fa-comment-alt-dots:after {
  content: "\10F4A3"; }

.fad.fa-comment-alt-edit:after {
  content: "\10F4A4"; }

.fad.fa-comment-alt-exclamation:after {
  content: "\10F4A5"; }

.fad.fa-comment-alt-lines:after {
  content: "\10F4A6"; }

.fad.fa-comment-alt-medical:after {
  content: "\10F7F4"; }

.fad.fa-comment-alt-minus:after {
  content: "\10F4A7"; }

.fad.fa-comment-alt-music:after {
  content: "\10F8AF"; }

.fad.fa-comment-alt-plus:after {
  content: "\10F4A8"; }

.fad.fa-comment-alt-slash:after {
  content: "\10F4A9"; }

.fad.fa-comment-alt-smile:after {
  content: "\10F4AA"; }

.fad.fa-comment-alt-times:after {
  content: "\10F4AB"; }

.fad.fa-comment-check:after {
  content: "\10F4AC"; }

.fad.fa-comment-dollar:after {
  content: "\10F651"; }

.fad.fa-comment-dots:after {
  content: "\10F4AD"; }

.fad.fa-comment-edit:after {
  content: "\10F4AE"; }

.fad.fa-comment-exclamation:after {
  content: "\10F4AF"; }

.fad.fa-comment-lines:after {
  content: "\10F4B0"; }

.fad.fa-comment-medical:after {
  content: "\10F7F5"; }

.fad.fa-comment-minus:after {
  content: "\10F4B1"; }

.fad.fa-comment-music:after {
  content: "\10F8B0"; }

.fad.fa-comment-plus:after {
  content: "\10F4B2"; }

.fad.fa-comment-slash:after {
  content: "\10F4B3"; }

.fad.fa-comment-smile:after {
  content: "\10F4B4"; }

.fad.fa-comment-times:after {
  content: "\10F4B5"; }

.fad.fa-comments:after {
  content: "\10F086"; }

.fad.fa-comments-alt:after {
  content: "\10F4B6"; }

.fad.fa-comments-alt-dollar:after {
  content: "\10F652"; }

.fad.fa-comments-dollar:after {
  content: "\10F653"; }

.fad.fa-compact-disc:after {
  content: "\10F51F"; }

.fad.fa-compass:after {
  content: "\10F14E"; }

.fad.fa-compass-slash:after {
  content: "\10F5E9"; }

.fad.fa-compress:after {
  content: "\10F066"; }

.fad.fa-compress-alt:after {
  content: "\10F422"; }

.fad.fa-compress-arrows-alt:after {
  content: "\10F78C"; }

.fad.fa-compress-wide:after {
  content: "\10F326"; }

.fad.fa-computer-classic:after {
  content: "\10F8B1"; }

.fad.fa-computer-speaker:after {
  content: "\10F8B2"; }

.fad.fa-concierge-bell:after {
  content: "\10F562"; }

.fad.fa-construction:after {
  content: "\10F85D"; }

.fad.fa-container-storage:after {
  content: "\10F4B7"; }

.fad.fa-conveyor-belt:after {
  content: "\10F46E"; }

.fad.fa-conveyor-belt-alt:after {
  content: "\10F46F"; }

.fad.fa-cookie:after {
  content: "\10F563"; }

.fad.fa-cookie-bite:after {
  content: "\10F564"; }

.fad.fa-copy:after {
  content: "\10F0C5"; }

.fad.fa-copyright:after {
  content: "\10F1F9"; }

.fad.fa-corn:after {
  content: "\10F6C7"; }

.fad.fa-couch:after {
  content: "\10F4B8"; }

.fad.fa-cow:after {
  content: "\10F6C8"; }

.fad.fa-cowbell:after {
  content: "\10F8B3"; }

.fad.fa-cowbell-more:after {
  content: "\10F8B4"; }

.fad.fa-credit-card:after {
  content: "\10F09D"; }

.fad.fa-credit-card-blank:after {
  content: "\10F389"; }

.fad.fa-credit-card-front:after {
  content: "\10F38A"; }

.fad.fa-cricket:after {
  content: "\10F449"; }

.fad.fa-croissant:after {
  content: "\10F7F6"; }

.fad.fa-crop:after {
  content: "\10F125"; }

.fad.fa-crop-alt:after {
  content: "\10F565"; }

.fad.fa-cross:after {
  content: "\10F654"; }

.fad.fa-crosshairs:after {
  content: "\10F05B"; }

.fad.fa-crow:after {
  content: "\10F520"; }

.fad.fa-crown:after {
  content: "\10F521"; }

.fad.fa-crutch:after {
  content: "\10F7F7"; }

.fad.fa-crutches:after {
  content: "\10F7F8"; }

.fad.fa-cube:after {
  content: "\10F1B2"; }

.fad.fa-cubes:after {
  content: "\10F1B3"; }

.fad.fa-curling:after {
  content: "\10F44A"; }

.fad.fa-cut:after {
  content: "\10F0C4"; }

.fad.fa-dagger:after {
  content: "\10F6CB"; }

.fad.fa-database:after {
  content: "\10F1C0"; }

.fad.fa-deaf:after {
  content: "\10F2A4"; }

.fad.fa-debug:after {
  content: "\10F7F9"; }

.fad.fa-deer:after {
  content: "\10F78E"; }

.fad.fa-deer-rudolph:after {
  content: "\10F78F"; }

.fad.fa-democrat:after {
  content: "\10F747"; }

.fad.fa-desktop:after {
  content: "\10F108"; }

.fad.fa-desktop-alt:after {
  content: "\10F390"; }

.fad.fa-dewpoint:after {
  content: "\10F748"; }

.fad.fa-dharmachakra:after {
  content: "\10F655"; }

.fad.fa-diagnoses:after {
  content: "\10F470"; }

.fad.fa-diamond:after {
  content: "\10F219"; }

.fad.fa-dice:after {
  content: "\10F522"; }

.fad.fa-dice-d10:after {
  content: "\10F6CD"; }

.fad.fa-dice-d12:after {
  content: "\10F6CE"; }

.fad.fa-dice-d20:after {
  content: "\10F6CF"; }

.fad.fa-dice-d4:after {
  content: "\10F6D0"; }

.fad.fa-dice-d6:after {
  content: "\10F6D1"; }

.fad.fa-dice-d8:after {
  content: "\10F6D2"; }

.fad.fa-dice-five:after {
  content: "\10F523"; }

.fad.fa-dice-four:after {
  content: "\10F524"; }

.fad.fa-dice-one:after {
  content: "\10F525"; }

.fad.fa-dice-six:after {
  content: "\10F526"; }

.fad.fa-dice-three:after {
  content: "\10F527"; }

.fad.fa-dice-two:after {
  content: "\10F528"; }

.fad.fa-digging:after {
  content: "\10F85E"; }

.fad.fa-digital-tachograph:after {
  content: "\10F566"; }

.fad.fa-diploma:after {
  content: "\10F5EA"; }

.fad.fa-directions:after {
  content: "\10F5EB"; }

.fad.fa-disc-drive:after {
  content: "\10F8B5"; }

.fad.fa-disease:after {
  content: "\10F7FA"; }

.fad.fa-divide:after {
  content: "\10F529"; }

.fad.fa-dizzy:after {
  content: "\10F567"; }

.fad.fa-dna:after {
  content: "\10F471"; }

.fad.fa-do-not-enter:after {
  content: "\10F5EC"; }

.fad.fa-dog:after {
  content: "\10F6D3"; }

.fad.fa-dog-leashed:after {
  content: "\10F6D4"; }

.fad.fa-dollar-sign:after {
  content: "\10F155"; }

.fad.fa-dolly:after {
  content: "\10F472"; }

.fad.fa-dolly-empty:after {
  content: "\10F473"; }

.fad.fa-dolly-flatbed:after {
  content: "\10F474"; }

.fad.fa-dolly-flatbed-alt:after {
  content: "\10F475"; }

.fad.fa-dolly-flatbed-empty:after {
  content: "\10F476"; }

.fad.fa-donate:after {
  content: "\10F4B9"; }

.fad.fa-door-closed:after {
  content: "\10F52A"; }

.fad.fa-door-open:after {
  content: "\10F52B"; }

.fad.fa-dot-circle:after {
  content: "\10F192"; }

.fad.fa-dove:after {
  content: "\10F4BA"; }

.fad.fa-download:after {
  content: "\10F019"; }

.fad.fa-drafting-compass:after {
  content: "\10F568"; }

.fad.fa-dragon:after {
  content: "\10F6D5"; }

.fad.fa-draw-circle:after {
  content: "\10F5ED"; }

.fad.fa-draw-polygon:after {
  content: "\10F5EE"; }

.fad.fa-draw-square:after {
  content: "\10F5EF"; }

.fad.fa-dreidel:after {
  content: "\10F792"; }

.fad.fa-drone:after {
  content: "\10F85F"; }

.fad.fa-drone-alt:after {
  content: "\10F860"; }

.fad.fa-drum:after {
  content: "\10F569"; }

.fad.fa-drum-steelpan:after {
  content: "\10F56A"; }

.fad.fa-drumstick:after {
  content: "\10F6D6"; }

.fad.fa-drumstick-bite:after {
  content: "\10F6D7"; }

.fad.fa-dryer:after {
  content: "\10F861"; }

.fad.fa-dryer-alt:after {
  content: "\10F862"; }

.fad.fa-duck:after {
  content: "\10F6D8"; }

.fad.fa-dumbbell:after {
  content: "\10F44B"; }

.fad.fa-dumpster:after {
  content: "\10F793"; }

.fad.fa-dumpster-fire:after {
  content: "\10F794"; }

.fad.fa-dungeon:after {
  content: "\10F6D9"; }

.fad.fa-ear:after {
  content: "\10F5F0"; }

.fad.fa-ear-muffs:after {
  content: "\10F795"; }

.fad.fa-eclipse:after {
  content: "\10F749"; }

.fad.fa-eclipse-alt:after {
  content: "\10F74A"; }

.fad.fa-edit:after {
  content: "\10F044"; }

.fad.fa-egg:after {
  content: "\10F7FB"; }

.fad.fa-egg-fried:after {
  content: "\10F7FC"; }

.fad.fa-eject:after {
  content: "\10F052"; }

.fad.fa-elephant:after {
  content: "\10F6DA"; }

.fad.fa-ellipsis-h:after {
  content: "\10F141"; }

.fad.fa-ellipsis-h-alt:after {
  content: "\10F39B"; }

.fad.fa-ellipsis-v:after {
  content: "\10F142"; }

.fad.fa-ellipsis-v-alt:after {
  content: "\10F39C"; }

.fad.fa-empty-set:after {
  content: "\10F656"; }

.fad.fa-engine-warning:after {
  content: "\10F5F2"; }

.fad.fa-envelope:after {
  content: "\10F0E0"; }

.fad.fa-envelope-open:after {
  content: "\10F2B6"; }

.fad.fa-envelope-open-dollar:after {
  content: "\10F657"; }

.fad.fa-envelope-open-text:after {
  content: "\10F658"; }

.fad.fa-envelope-square:after {
  content: "\10F199"; }

.fad.fa-equals:after {
  content: "\10F52C"; }

.fad.fa-eraser:after {
  content: "\10F12D"; }

.fad.fa-ethernet:after {
  content: "\10F796"; }

.fad.fa-euro-sign:after {
  content: "\10F153"; }

.fad.fa-exchange:after {
  content: "\10F0EC"; }

.fad.fa-exchange-alt:after {
  content: "\10F362"; }

.fad.fa-exclamation:after {
  content: "\10F12A"; }

.fad.fa-exclamation-circle:after {
  content: "\10F06A"; }

.fad.fa-exclamation-square:after {
  content: "\10F321"; }

.fad.fa-exclamation-triangle:after {
  content: "\10F071"; }

.fad.fa-expand:after {
  content: "\10F065"; }

.fad.fa-expand-alt:after {
  content: "\10F424"; }

.fad.fa-expand-arrows:after {
  content: "\10F31D"; }

.fad.fa-expand-arrows-alt:after {
  content: "\10F31E"; }

.fad.fa-expand-wide:after {
  content: "\10F320"; }

.fad.fa-external-link:after {
  content: "\10F08E"; }

.fad.fa-external-link-alt:after {
  content: "\10F35D"; }

.fad.fa-external-link-square:after {
  content: "\10F14C"; }

.fad.fa-external-link-square-alt:after {
  content: "\10F360"; }

.fad.fa-eye:after {
  content: "\10F06E"; }

.fad.fa-eye-dropper:after {
  content: "\10F1FB"; }

.fad.fa-eye-evil:after {
  content: "\10F6DB"; }

.fad.fa-eye-slash:after {
  content: "\10F070"; }

.fad.fa-fan:after {
  content: "\10F863"; }

.fad.fa-fan-table:after {
  content: "\10E004"; }

.fad.fa-farm:after {
  content: "\10F864"; }

.fad.fa-fast-backward:after {
  content: "\10F049"; }

.fad.fa-fast-forward:after {
  content: "\10F050"; }

.fad.fa-faucet:after {
  content: "\10E005"; }

.fad.fa-faucet-drip:after {
  content: "\10E006"; }

.fad.fa-fax:after {
  content: "\10F1AC"; }

.fad.fa-feather:after {
  content: "\10F52D"; }

.fad.fa-feather-alt:after {
  content: "\10F56B"; }

.fad.fa-female:after {
  content: "\10F182"; }

.fad.fa-field-hockey:after {
  content: "\10F44C"; }

.fad.fa-fighter-jet:after {
  content: "\10F0FB"; }

.fad.fa-file:after {
  content: "\10F15B"; }

.fad.fa-file-alt:after {
  content: "\10F15C"; }

.fad.fa-file-archive:after {
  content: "\10F1C6"; }

.fad.fa-file-audio:after {
  content: "\10F1C7"; }

.fad.fa-file-certificate:after {
  content: "\10F5F3"; }

.fad.fa-file-chart-line:after {
  content: "\10F659"; }

.fad.fa-file-chart-pie:after {
  content: "\10F65A"; }

.fad.fa-file-check:after {
  content: "\10F316"; }

.fad.fa-file-code:after {
  content: "\10F1C9"; }

.fad.fa-file-contract:after {
  content: "\10F56C"; }

.fad.fa-file-csv:after {
  content: "\10F6DD"; }

.fad.fa-file-download:after {
  content: "\10F56D"; }

.fad.fa-file-edit:after {
  content: "\10F31C"; }

.fad.fa-file-excel:after {
  content: "\10F1C3"; }

.fad.fa-file-exclamation:after {
  content: "\10F31A"; }

.fad.fa-file-export:after {
  content: "\10F56E"; }

.fad.fa-file-image:after {
  content: "\10F1C5"; }

.fad.fa-file-import:after {
  content: "\10F56F"; }

.fad.fa-file-invoice:after {
  content: "\10F570"; }

.fad.fa-file-invoice-dollar:after {
  content: "\10F571"; }

.fad.fa-file-medical:after {
  content: "\10F477"; }

.fad.fa-file-medical-alt:after {
  content: "\10F478"; }

.fad.fa-file-minus:after {
  content: "\10F318"; }

.fad.fa-file-music:after {
  content: "\10F8B6"; }

.fad.fa-file-pdf:after {
  content: "\10F1C1"; }

.fad.fa-file-plus:after {
  content: "\10F319"; }

.fad.fa-file-powerpoint:after {
  content: "\10F1C4"; }

.fad.fa-file-prescription:after {
  content: "\10F572"; }

.fad.fa-file-search:after {
  content: "\10F865"; }

.fad.fa-file-signature:after {
  content: "\10F573"; }

.fad.fa-file-spreadsheet:after {
  content: "\10F65B"; }

.fad.fa-file-times:after {
  content: "\10F317"; }

.fad.fa-file-upload:after {
  content: "\10F574"; }

.fad.fa-file-user:after {
  content: "\10F65C"; }

.fad.fa-file-video:after {
  content: "\10F1C8"; }

.fad.fa-file-word:after {
  content: "\10F1C2"; }

.fad.fa-files-medical:after {
  content: "\10F7FD"; }

.fad.fa-fill:after {
  content: "\10F575"; }

.fad.fa-fill-drip:after {
  content: "\10F576"; }

.fad.fa-film:after {
  content: "\10F008"; }

.fad.fa-film-alt:after {
  content: "\10F3A0"; }

.fad.fa-film-canister:after {
  content: "\10F8B7"; }

.fad.fa-filter:after {
  content: "\10F0B0"; }

.fad.fa-fingerprint:after {
  content: "\10F577"; }

.fad.fa-fire:after {
  content: "\10F06D"; }

.fad.fa-fire-alt:after {
  content: "\10F7E4"; }

.fad.fa-fire-extinguisher:after {
  content: "\10F134"; }

.fad.fa-fire-smoke:after {
  content: "\10F74B"; }

.fad.fa-fireplace:after {
  content: "\10F79A"; }

.fad.fa-first-aid:after {
  content: "\10F479"; }

.fad.fa-fish:after {
  content: "\10F578"; }

.fad.fa-fish-cooked:after {
  content: "\10F7FE"; }

.fad.fa-fist-raised:after {
  content: "\10F6DE"; }

.fad.fa-flag:after {
  content: "\10F024"; }

.fad.fa-flag-alt:after {
  content: "\10F74C"; }

.fad.fa-flag-checkered:after {
  content: "\10F11E"; }

.fad.fa-flag-usa:after {
  content: "\10F74D"; }

.fad.fa-flame:after {
  content: "\10F6DF"; }

.fad.fa-flashlight:after {
  content: "\10F8B8"; }

.fad.fa-flask:after {
  content: "\10F0C3"; }

.fad.fa-flask-poison:after {
  content: "\10F6E0"; }

.fad.fa-flask-potion:after {
  content: "\10F6E1"; }

.fad.fa-flower:after {
  content: "\10F7FF"; }

.fad.fa-flower-daffodil:after {
  content: "\10F800"; }

.fad.fa-flower-tulip:after {
  content: "\10F801"; }

.fad.fa-flushed:after {
  content: "\10F579"; }

.fad.fa-flute:after {
  content: "\10F8B9"; }

.fad.fa-flux-capacitor:after {
  content: "\10F8BA"; }

.fad.fa-fog:after {
  content: "\10F74E"; }

.fad.fa-folder:after {
  content: "\10F07B"; }

.fad.fa-folder-download:after {
  content: "\10E053"; }

.fad.fa-folder-minus:after {
  content: "\10F65D"; }

.fad.fa-folder-open:after {
  content: "\10F07C"; }

.fad.fa-folder-plus:after {
  content: "\10F65E"; }

.fad.fa-folder-times:after {
  content: "\10F65F"; }

.fad.fa-folder-tree:after {
  content: "\10F802"; }

.fad.fa-folder-upload:after {
  content: "\10E054"; }

.fad.fa-folders:after {
  content: "\10F660"; }

.fad.fa-font:after {
  content: "\10F031"; }

.fad.fa-font-awesome-logo-full:after {
  content: "\10F4E6"; }

.fad.fa-font-case:after {
  content: "\10F866"; }

.fad.fa-football-ball:after {
  content: "\10F44E"; }

.fad.fa-football-helmet:after {
  content: "\10F44F"; }

.fad.fa-forklift:after {
  content: "\10F47A"; }

.fad.fa-forward:after {
  content: "\10F04E"; }

.fad.fa-fragile:after {
  content: "\10F4BB"; }

.fad.fa-french-fries:after {
  content: "\10F803"; }

.fad.fa-frog:after {
  content: "\10F52E"; }

.fad.fa-frosty-head:after {
  content: "\10F79B"; }

.fad.fa-frown:after {
  content: "\10F119"; }

.fad.fa-frown-open:after {
  content: "\10F57A"; }

.fad.fa-function:after {
  content: "\10F661"; }

.fad.fa-funnel-dollar:after {
  content: "\10F662"; }

.fad.fa-futbol:after {
  content: "\10F1E3"; }

.fad.fa-galaxy:after {
  content: "\10E008"; }

.fad.fa-game-board:after {
  content: "\10F867"; }

.fad.fa-game-board-alt:after {
  content: "\10F868"; }

.fad.fa-game-console-handheld:after {
  content: "\10F8BB"; }

.fad.fa-gamepad:after {
  content: "\10F11B"; }

.fad.fa-gamepad-alt:after {
  content: "\10F8BC"; }

.fad.fa-garage:after {
  content: "\10E009"; }

.fad.fa-garage-car:after {
  content: "\10E00A"; }

.fad.fa-garage-open:after {
  content: "\10E00B"; }

.fad.fa-gas-pump:after {
  content: "\10F52F"; }

.fad.fa-gas-pump-slash:after {
  content: "\10F5F4"; }

.fad.fa-gavel:after {
  content: "\10F0E3"; }

.fad.fa-gem:after {
  content: "\10F3A5"; }

.fad.fa-genderless:after {
  content: "\10F22D"; }

.fad.fa-ghost:after {
  content: "\10F6E2"; }

.fad.fa-gift:after {
  content: "\10F06B"; }

.fad.fa-gift-card:after {
  content: "\10F663"; }

.fad.fa-gifts:after {
  content: "\10F79C"; }

.fad.fa-gingerbread-man:after {
  content: "\10F79D"; }

.fad.fa-glass:after {
  content: "\10F804"; }

.fad.fa-glass-champagne:after {
  content: "\10F79E"; }

.fad.fa-glass-cheers:after {
  content: "\10F79F"; }

.fad.fa-glass-citrus:after {
  content: "\10F869"; }

.fad.fa-glass-martini:after {
  content: "\10F000"; }

.fad.fa-glass-martini-alt:after {
  content: "\10F57B"; }

.fad.fa-glass-whiskey:after {
  content: "\10F7A0"; }

.fad.fa-glass-whiskey-rocks:after {
  content: "\10F7A1"; }

.fad.fa-glasses:after {
  content: "\10F530"; }

.fad.fa-glasses-alt:after {
  content: "\10F5F5"; }

.fad.fa-globe:after {
  content: "\10F0AC"; }

.fad.fa-globe-africa:after {
  content: "\10F57C"; }

.fad.fa-globe-americas:after {
  content: "\10F57D"; }

.fad.fa-globe-asia:after {
  content: "\10F57E"; }

.fad.fa-globe-europe:after {
  content: "\10F7A2"; }

.fad.fa-globe-snow:after {
  content: "\10F7A3"; }

.fad.fa-globe-stand:after {
  content: "\10F5F6"; }

.fad.fa-golf-ball:after {
  content: "\10F450"; }

.fad.fa-golf-club:after {
  content: "\10F451"; }

.fad.fa-gopuram:after {
  content: "\10F664"; }

.fad.fa-graduation-cap:after {
  content: "\10F19D"; }

.fad.fa-gramophone:after {
  content: "\10F8BD"; }

.fad.fa-greater-than:after {
  content: "\10F531"; }

.fad.fa-greater-than-equal:after {
  content: "\10F532"; }

.fad.fa-grimace:after {
  content: "\10F57F"; }

.fad.fa-grin:after {
  content: "\10F580"; }

.fad.fa-grin-alt:after {
  content: "\10F581"; }

.fad.fa-grin-beam:after {
  content: "\10F582"; }

.fad.fa-grin-beam-sweat:after {
  content: "\10F583"; }

.fad.fa-grin-hearts:after {
  content: "\10F584"; }

.fad.fa-grin-squint:after {
  content: "\10F585"; }

.fad.fa-grin-squint-tears:after {
  content: "\10F586"; }

.fad.fa-grin-stars:after {
  content: "\10F587"; }

.fad.fa-grin-tears:after {
  content: "\10F588"; }

.fad.fa-grin-tongue:after {
  content: "\10F589"; }

.fad.fa-grin-tongue-squint:after {
  content: "\10F58A"; }

.fad.fa-grin-tongue-wink:after {
  content: "\10F58B"; }

.fad.fa-grin-wink:after {
  content: "\10F58C"; }

.fad.fa-grip-horizontal:after {
  content: "\10F58D"; }

.fad.fa-grip-lines:after {
  content: "\10F7A4"; }

.fad.fa-grip-lines-vertical:after {
  content: "\10F7A5"; }

.fad.fa-grip-vertical:after {
  content: "\10F58E"; }

.fad.fa-guitar:after {
  content: "\10F7A6"; }

.fad.fa-guitar-electric:after {
  content: "\10F8BE"; }

.fad.fa-guitars:after {
  content: "\10F8BF"; }

.fad.fa-h-square:after {
  content: "\10F0FD"; }

.fad.fa-h1:after {
  content: "\10F313"; }

.fad.fa-h2:after {
  content: "\10F314"; }

.fad.fa-h3:after {
  content: "\10F315"; }

.fad.fa-h4:after {
  content: "\10F86A"; }

.fad.fa-hamburger:after {
  content: "\10F805"; }

.fad.fa-hammer:after {
  content: "\10F6E3"; }

.fad.fa-hammer-war:after {
  content: "\10F6E4"; }

.fad.fa-hamsa:after {
  content: "\10F665"; }

.fad.fa-hand-heart:after {
  content: "\10F4BC"; }

.fad.fa-hand-holding:after {
  content: "\10F4BD"; }

.fad.fa-hand-holding-box:after {
  content: "\10F47B"; }

.fad.fa-hand-holding-heart:after {
  content: "\10F4BE"; }

.fad.fa-hand-holding-magic:after {
  content: "\10F6E5"; }

.fad.fa-hand-holding-medical:after {
  content: "\10E05C"; }

.fad.fa-hand-holding-seedling:after {
  content: "\10F4BF"; }

.fad.fa-hand-holding-usd:after {
  content: "\10F4C0"; }

.fad.fa-hand-holding-water:after {
  content: "\10F4C1"; }

.fad.fa-hand-lizard:after {
  content: "\10F258"; }

.fad.fa-hand-middle-finger:after {
  content: "\10F806"; }

.fad.fa-hand-paper:after {
  content: "\10F256"; }

.fad.fa-hand-peace:after {
  content: "\10F25B"; }

.fad.fa-hand-point-down:after {
  content: "\10F0A7"; }

.fad.fa-hand-point-left:after {
  content: "\10F0A5"; }

.fad.fa-hand-point-right:after {
  content: "\10F0A4"; }

.fad.fa-hand-point-up:after {
  content: "\10F0A6"; }

.fad.fa-hand-pointer:after {
  content: "\10F25A"; }

.fad.fa-hand-receiving:after {
  content: "\10F47C"; }

.fad.fa-hand-rock:after {
  content: "\10F255"; }

.fad.fa-hand-scissors:after {
  content: "\10F257"; }

.fad.fa-hand-sparkles:after {
  content: "\10E05D"; }

.fad.fa-hand-spock:after {
  content: "\10F259"; }

.fad.fa-hands:after {
  content: "\10F4C2"; }

.fad.fa-hands-heart:after {
  content: "\10F4C3"; }

.fad.fa-hands-helping:after {
  content: "\10F4C4"; }

.fad.fa-hands-usd:after {
  content: "\10F4C5"; }

.fad.fa-hands-wash:after {
  content: "\10E05E"; }

.fad.fa-handshake:after {
  content: "\10F2B5"; }

.fad.fa-handshake-alt:after {
  content: "\10F4C6"; }

.fad.fa-handshake-alt-slash:after {
  content: "\10E05F"; }

.fad.fa-handshake-slash:after {
  content: "\10E060"; }

.fad.fa-hanukiah:after {
  content: "\10F6E6"; }

.fad.fa-hard-hat:after {
  content: "\10F807"; }

.fad.fa-hashtag:after {
  content: "\10F292"; }

.fad.fa-hat-chef:after {
  content: "\10F86B"; }

.fad.fa-hat-cowboy:after {
  content: "\10F8C0"; }

.fad.fa-hat-cowboy-side:after {
  content: "\10F8C1"; }

.fad.fa-hat-santa:after {
  content: "\10F7A7"; }

.fad.fa-hat-winter:after {
  content: "\10F7A8"; }

.fad.fa-hat-witch:after {
  content: "\10F6E7"; }

.fad.fa-hat-wizard:after {
  content: "\10F6E8"; }

.fad.fa-hdd:after {
  content: "\10F0A0"; }

.fad.fa-head-side:after {
  content: "\10F6E9"; }

.fad.fa-head-side-brain:after {
  content: "\10F808"; }

.fad.fa-head-side-cough:after {
  content: "\10E061"; }

.fad.fa-head-side-cough-slash:after {
  content: "\10E062"; }

.fad.fa-head-side-headphones:after {
  content: "\10F8C2"; }

.fad.fa-head-side-mask:after {
  content: "\10E063"; }

.fad.fa-head-side-medical:after {
  content: "\10F809"; }

.fad.fa-head-side-virus:after {
  content: "\10E064"; }

.fad.fa-head-vr:after {
  content: "\10F6EA"; }

.fad.fa-heading:after {
  content: "\10F1DC"; }

.fad.fa-headphones:after {
  content: "\10F025"; }

.fad.fa-headphones-alt:after {
  content: "\10F58F"; }

.fad.fa-headset:after {
  content: "\10F590"; }

.fad.fa-heart:after {
  content: "\10F004"; }

.fad.fa-heart-broken:after {
  content: "\10F7A9"; }

.fad.fa-heart-circle:after {
  content: "\10F4C7"; }

.fad.fa-heart-rate:after {
  content: "\10F5F8"; }

.fad.fa-heart-square:after {
  content: "\10F4C8"; }

.fad.fa-heartbeat:after {
  content: "\10F21E"; }

.fad.fa-heat:after {
  content: "\10E00C"; }

.fad.fa-helicopter:after {
  content: "\10F533"; }

.fad.fa-helmet-battle:after {
  content: "\10F6EB"; }

.fad.fa-hexagon:after {
  content: "\10F312"; }

.fad.fa-highlighter:after {
  content: "\10F591"; }

.fad.fa-hiking:after {
  content: "\10F6EC"; }

.fad.fa-hippo:after {
  content: "\10F6ED"; }

.fad.fa-history:after {
  content: "\10F1DA"; }

.fad.fa-hockey-mask:after {
  content: "\10F6EE"; }

.fad.fa-hockey-puck:after {
  content: "\10F453"; }

.fad.fa-hockey-sticks:after {
  content: "\10F454"; }

.fad.fa-holly-berry:after {
  content: "\10F7AA"; }

.fad.fa-home:after {
  content: "\10F015"; }

.fad.fa-home-alt:after {
  content: "\10F80A"; }

.fad.fa-home-heart:after {
  content: "\10F4C9"; }

.fad.fa-home-lg:after {
  content: "\10F80B"; }

.fad.fa-home-lg-alt:after {
  content: "\10F80C"; }

.fad.fa-hood-cloak:after {
  content: "\10F6EF"; }

.fad.fa-horizontal-rule:after {
  content: "\10F86C"; }

.fad.fa-horse:after {
  content: "\10F6F0"; }

.fad.fa-horse-head:after {
  content: "\10F7AB"; }

.fad.fa-horse-saddle:after {
  content: "\10F8C3"; }

.fad.fa-hospital:after {
  content: "\10F0F8"; }

.fad.fa-hospital-alt:after {
  content: "\10F47D"; }

.fad.fa-hospital-symbol:after {
  content: "\10F47E"; }

.fad.fa-hospital-user:after {
  content: "\10F80D"; }

.fad.fa-hospitals:after {
  content: "\10F80E"; }

.fad.fa-hot-tub:after {
  content: "\10F593"; }

.fad.fa-hotdog:after {
  content: "\10F80F"; }

.fad.fa-hotel:after {
  content: "\10F594"; }

.fad.fa-hourglass:after {
  content: "\10F254"; }

.fad.fa-hourglass-end:after {
  content: "\10F253"; }

.fad.fa-hourglass-half:after {
  content: "\10F252"; }

.fad.fa-hourglass-start:after {
  content: "\10F251"; }

.fad.fa-house:after {
  content: "\10E00D"; }

.fad.fa-house-damage:after {
  content: "\10F6F1"; }

.fad.fa-house-day:after {
  content: "\10E00E"; }

.fad.fa-house-flood:after {
  content: "\10F74F"; }

.fad.fa-house-leave:after {
  content: "\10E00F"; }

.fad.fa-house-night:after {
  content: "\10E010"; }

.fad.fa-house-return:after {
  content: "\10E011"; }

.fad.fa-house-signal:after {
  content: "\10E012"; }

.fad.fa-house-user:after {
  content: "\10E065"; }

.fad.fa-hryvnia:after {
  content: "\10F6F2"; }

.fad.fa-humidity:after {
  content: "\10F750"; }

.fad.fa-hurricane:after {
  content: "\10F751"; }

.fad.fa-i-cursor:after {
  content: "\10F246"; }

.fad.fa-ice-cream:after {
  content: "\10F810"; }

.fad.fa-ice-skate:after {
  content: "\10F7AC"; }

.fad.fa-icicles:after {
  content: "\10F7AD"; }

.fad.fa-icons:after {
  content: "\10F86D"; }

.fad.fa-icons-alt:after {
  content: "\10F86E"; }

.fad.fa-id-badge:after {
  content: "\10F2C1"; }

.fad.fa-id-card:after {
  content: "\10F2C2"; }

.fad.fa-id-card-alt:after {
  content: "\10F47F"; }

.fad.fa-igloo:after {
  content: "\10F7AE"; }

.fad.fa-image:after {
  content: "\10F03E"; }

.fad.fa-image-polaroid:after {
  content: "\10F8C4"; }

.fad.fa-images:after {
  content: "\10F302"; }

.fad.fa-inbox:after {
  content: "\10F01C"; }

.fad.fa-inbox-in:after {
  content: "\10F310"; }

.fad.fa-inbox-out:after {
  content: "\10F311"; }

.fad.fa-indent:after {
  content: "\10F03C"; }

.fad.fa-industry:after {
  content: "\10F275"; }

.fad.fa-industry-alt:after {
  content: "\10F3B3"; }

.fad.fa-infinity:after {
  content: "\10F534"; }

.fad.fa-info:after {
  content: "\10F129"; }

.fad.fa-info-circle:after {
  content: "\10F05A"; }

.fad.fa-info-square:after {
  content: "\10F30F"; }

.fad.fa-inhaler:after {
  content: "\10F5F9"; }

.fad.fa-integral:after {
  content: "\10F667"; }

.fad.fa-intersection:after {
  content: "\10F668"; }

.fad.fa-inventory:after {
  content: "\10F480"; }

.fad.fa-island-tropical:after {
  content: "\10F811"; }

.fad.fa-italic:after {
  content: "\10F033"; }

.fad.fa-jack-o-lantern:after {
  content: "\10F30E"; }

.fad.fa-jedi:after {
  content: "\10F669"; }

.fad.fa-joint:after {
  content: "\10F595"; }

.fad.fa-journal-whills:after {
  content: "\10F66A"; }

.fad.fa-joystick:after {
  content: "\10F8C5"; }

.fad.fa-jug:after {
  content: "\10F8C6"; }

.fad.fa-kaaba:after {
  content: "\10F66B"; }

.fad.fa-kazoo:after {
  content: "\10F8C7"; }

.fad.fa-kerning:after {
  content: "\10F86F"; }

.fad.fa-key:after {
  content: "\10F084"; }

.fad.fa-key-skeleton:after {
  content: "\10F6F3"; }

.fad.fa-keyboard:after {
  content: "\10F11C"; }

.fad.fa-keynote:after {
  content: "\10F66C"; }

.fad.fa-khanda:after {
  content: "\10F66D"; }

.fad.fa-kidneys:after {
  content: "\10F5FB"; }

.fad.fa-kiss:after {
  content: "\10F596"; }

.fad.fa-kiss-beam:after {
  content: "\10F597"; }

.fad.fa-kiss-wink-heart:after {
  content: "\10F598"; }

.fad.fa-kite:after {
  content: "\10F6F4"; }

.fad.fa-kiwi-bird:after {
  content: "\10F535"; }

.fad.fa-knife-kitchen:after {
  content: "\10F6F5"; }

.fad.fa-lambda:after {
  content: "\10F66E"; }

.fad.fa-lamp:after {
  content: "\10F4CA"; }

.fad.fa-lamp-desk:after {
  content: "\10E014"; }

.fad.fa-lamp-floor:after {
  content: "\10E015"; }

.fad.fa-landmark:after {
  content: "\10F66F"; }

.fad.fa-landmark-alt:after {
  content: "\10F752"; }

.fad.fa-language:after {
  content: "\10F1AB"; }

.fad.fa-laptop:after {
  content: "\10F109"; }

.fad.fa-laptop-code:after {
  content: "\10F5FC"; }

.fad.fa-laptop-house:after {
  content: "\10E066"; }

.fad.fa-laptop-medical:after {
  content: "\10F812"; }

.fad.fa-lasso:after {
  content: "\10F8C8"; }

.fad.fa-laugh:after {
  content: "\10F599"; }

.fad.fa-laugh-beam:after {
  content: "\10F59A"; }

.fad.fa-laugh-squint:after {
  content: "\10F59B"; }

.fad.fa-laugh-wink:after {
  content: "\10F59C"; }

.fad.fa-layer-group:after {
  content: "\10F5FD"; }

.fad.fa-layer-minus:after {
  content: "\10F5FE"; }

.fad.fa-layer-plus:after {
  content: "\10F5FF"; }

.fad.fa-leaf:after {
  content: "\10F06C"; }

.fad.fa-leaf-heart:after {
  content: "\10F4CB"; }

.fad.fa-leaf-maple:after {
  content: "\10F6F6"; }

.fad.fa-leaf-oak:after {
  content: "\10F6F7"; }

.fad.fa-lemon:after {
  content: "\10F094"; }

.fad.fa-less-than:after {
  content: "\10F536"; }

.fad.fa-less-than-equal:after {
  content: "\10F537"; }

.fad.fa-level-down:after {
  content: "\10F149"; }

.fad.fa-level-down-alt:after {
  content: "\10F3BE"; }

.fad.fa-level-up:after {
  content: "\10F148"; }

.fad.fa-level-up-alt:after {
  content: "\10F3BF"; }

.fad.fa-life-ring:after {
  content: "\10F1CD"; }

.fad.fa-light-ceiling:after {
  content: "\10E016"; }

.fad.fa-light-switch:after {
  content: "\10E017"; }

.fad.fa-light-switch-off:after {
  content: "\10E018"; }

.fad.fa-light-switch-on:after {
  content: "\10E019"; }

.fad.fa-lightbulb:after {
  content: "\10F0EB"; }

.fad.fa-lightbulb-dollar:after {
  content: "\10F670"; }

.fad.fa-lightbulb-exclamation:after {
  content: "\10F671"; }

.fad.fa-lightbulb-on:after {
  content: "\10F672"; }

.fad.fa-lightbulb-slash:after {
  content: "\10F673"; }

.fad.fa-lights-holiday:after {
  content: "\10F7B2"; }

.fad.fa-line-columns:after {
  content: "\10F870"; }

.fad.fa-line-height:after {
  content: "\10F871"; }

.fad.fa-link:after {
  content: "\10F0C1"; }

.fad.fa-lips:after {
  content: "\10F600"; }

.fad.fa-lira-sign:after {
  content: "\10F195"; }

.fad.fa-list:after {
  content: "\10F03A"; }

.fad.fa-list-alt:after {
  content: "\10F022"; }

.fad.fa-list-music:after {
  content: "\10F8C9"; }

.fad.fa-list-ol:after {
  content: "\10F0CB"; }

.fad.fa-list-ul:after {
  content: "\10F0CA"; }

.fad.fa-location:after {
  content: "\10F601"; }

.fad.fa-location-arrow:after {
  content: "\10F124"; }

.fad.fa-location-circle:after {
  content: "\10F602"; }

.fad.fa-location-slash:after {
  content: "\10F603"; }

.fad.fa-lock:after {
  content: "\10F023"; }

.fad.fa-lock-alt:after {
  content: "\10F30D"; }

.fad.fa-lock-open:after {
  content: "\10F3C1"; }

.fad.fa-lock-open-alt:after {
  content: "\10F3C2"; }

.fad.fa-long-arrow-alt-down:after {
  content: "\10F309"; }

.fad.fa-long-arrow-alt-left:after {
  content: "\10F30A"; }

.fad.fa-long-arrow-alt-right:after {
  content: "\10F30B"; }

.fad.fa-long-arrow-alt-up:after {
  content: "\10F30C"; }

.fad.fa-long-arrow-down:after {
  content: "\10F175"; }

.fad.fa-long-arrow-left:after {
  content: "\10F177"; }

.fad.fa-long-arrow-right:after {
  content: "\10F178"; }

.fad.fa-long-arrow-up:after {
  content: "\10F176"; }

.fad.fa-loveseat:after {
  content: "\10F4CC"; }

.fad.fa-low-vision:after {
  content: "\10F2A8"; }

.fad.fa-luchador:after {
  content: "\10F455"; }

.fad.fa-luggage-cart:after {
  content: "\10F59D"; }

.fad.fa-lungs:after {
  content: "\10F604"; }

.fad.fa-lungs-virus:after {
  content: "\10E067"; }

.fad.fa-mace:after {
  content: "\10F6F8"; }

.fad.fa-magic:after {
  content: "\10F0D0"; }

.fad.fa-magnet:after {
  content: "\10F076"; }

.fad.fa-mail-bulk:after {
  content: "\10F674"; }

.fad.fa-mailbox:after {
  content: "\10F813"; }

.fad.fa-male:after {
  content: "\10F183"; }

.fad.fa-mandolin:after {
  content: "\10F6F9"; }

.fad.fa-map:after {
  content: "\10F279"; }

.fad.fa-map-marked:after {
  content: "\10F59F"; }

.fad.fa-map-marked-alt:after {
  content: "\10F5A0"; }

.fad.fa-map-marker:after {
  content: "\10F041"; }

.fad.fa-map-marker-alt:after {
  content: "\10F3C5"; }

.fad.fa-map-marker-alt-slash:after {
  content: "\10F605"; }

.fad.fa-map-marker-check:after {
  content: "\10F606"; }

.fad.fa-map-marker-edit:after {
  content: "\10F607"; }

.fad.fa-map-marker-exclamation:after {
  content: "\10F608"; }

.fad.fa-map-marker-minus:after {
  content: "\10F609"; }

.fad.fa-map-marker-plus:after {
  content: "\10F60A"; }

.fad.fa-map-marker-question:after {
  content: "\10F60B"; }

.fad.fa-map-marker-slash:after {
  content: "\10F60C"; }

.fad.fa-map-marker-smile:after {
  content: "\10F60D"; }

.fad.fa-map-marker-times:after {
  content: "\10F60E"; }

.fad.fa-map-pin:after {
  content: "\10F276"; }

.fad.fa-map-signs:after {
  content: "\10F277"; }

.fad.fa-marker:after {
  content: "\10F5A1"; }

.fad.fa-mars:after {
  content: "\10F222"; }

.fad.fa-mars-double:after {
  content: "\10F227"; }

.fad.fa-mars-stroke:after {
  content: "\10F229"; }

.fad.fa-mars-stroke-h:after {
  content: "\10F22B"; }

.fad.fa-mars-stroke-v:after {
  content: "\10F22A"; }

.fad.fa-mask:after {
  content: "\10F6FA"; }

.fad.fa-meat:after {
  content: "\10F814"; }

.fad.fa-medal:after {
  content: "\10F5A2"; }

.fad.fa-medkit:after {
  content: "\10F0FA"; }

.fad.fa-megaphone:after {
  content: "\10F675"; }

.fad.fa-meh:after {
  content: "\10F11A"; }

.fad.fa-meh-blank:after {
  content: "\10F5A4"; }

.fad.fa-meh-rolling-eyes:after {
  content: "\10F5A5"; }

.fad.fa-memory:after {
  content: "\10F538"; }

.fad.fa-menorah:after {
  content: "\10F676"; }

.fad.fa-mercury:after {
  content: "\10F223"; }

.fad.fa-meteor:after {
  content: "\10F753"; }

.fad.fa-microchip:after {
  content: "\10F2DB"; }

.fad.fa-microphone:after {
  content: "\10F130"; }

.fad.fa-microphone-alt:after {
  content: "\10F3C9"; }

.fad.fa-microphone-alt-slash:after {
  content: "\10F539"; }

.fad.fa-microphone-slash:after {
  content: "\10F131"; }

.fad.fa-microphone-stand:after {
  content: "\10F8CB"; }

.fad.fa-microscope:after {
  content: "\10F610"; }

.fad.fa-microwave:after {
  content: "\10E01B"; }

.fad.fa-mind-share:after {
  content: "\10F677"; }

.fad.fa-minus:after {
  content: "\10F068"; }

.fad.fa-minus-circle:after {
  content: "\10F056"; }

.fad.fa-minus-hexagon:after {
  content: "\10F307"; }

.fad.fa-minus-octagon:after {
  content: "\10F308"; }

.fad.fa-minus-square:after {
  content: "\10F146"; }

.fad.fa-mistletoe:after {
  content: "\10F7B4"; }

.fad.fa-mitten:after {
  content: "\10F7B5"; }

.fad.fa-mobile:after {
  content: "\10F10B"; }

.fad.fa-mobile-alt:after {
  content: "\10F3CD"; }

.fad.fa-mobile-android:after {
  content: "\10F3CE"; }

.fad.fa-mobile-android-alt:after {
  content: "\10F3CF"; }

.fad.fa-money-bill:after {
  content: "\10F0D6"; }

.fad.fa-money-bill-alt:after {
  content: "\10F3D1"; }

.fad.fa-money-bill-wave:after {
  content: "\10F53A"; }

.fad.fa-money-bill-wave-alt:after {
  content: "\10F53B"; }

.fad.fa-money-check:after {
  content: "\10F53C"; }

.fad.fa-money-check-alt:after {
  content: "\10F53D"; }

.fad.fa-money-check-edit:after {
  content: "\10F872"; }

.fad.fa-money-check-edit-alt:after {
  content: "\10F873"; }

.fad.fa-monitor-heart-rate:after {
  content: "\10F611"; }

.fad.fa-monkey:after {
  content: "\10F6FB"; }

.fad.fa-monument:after {
  content: "\10F5A6"; }

.fad.fa-moon:after {
  content: "\10F186"; }

.fad.fa-moon-cloud:after {
  content: "\10F754"; }

.fad.fa-moon-stars:after {
  content: "\10F755"; }

.fad.fa-mortar-pestle:after {
  content: "\10F5A7"; }

.fad.fa-mosque:after {
  content: "\10F678"; }

.fad.fa-motorcycle:after {
  content: "\10F21C"; }

.fad.fa-mountain:after {
  content: "\10F6FC"; }

.fad.fa-mountains:after {
  content: "\10F6FD"; }

.fad.fa-mouse:after {
  content: "\10F8CC"; }

.fad.fa-mouse-alt:after {
  content: "\10F8CD"; }

.fad.fa-mouse-pointer:after {
  content: "\10F245"; }

.fad.fa-mp3-player:after {
  content: "\10F8CE"; }

.fad.fa-mug:after {
  content: "\10F874"; }

.fad.fa-mug-hot:after {
  content: "\10F7B6"; }

.fad.fa-mug-marshmallows:after {
  content: "\10F7B7"; }

.fad.fa-mug-tea:after {
  content: "\10F875"; }

.fad.fa-music:after {
  content: "\10F001"; }

.fad.fa-music-alt:after {
  content: "\10F8CF"; }

.fad.fa-music-alt-slash:after {
  content: "\10F8D0"; }

.fad.fa-music-slash:after {
  content: "\10F8D1"; }

.fad.fa-narwhal:after {
  content: "\10F6FE"; }

.fad.fa-network-wired:after {
  content: "\10F6FF"; }

.fad.fa-neuter:after {
  content: "\10F22C"; }

.fad.fa-newspaper:after {
  content: "\10F1EA"; }

.fad.fa-not-equal:after {
  content: "\10F53E"; }

.fad.fa-notes-medical:after {
  content: "\10F481"; }

.fad.fa-object-group:after {
  content: "\10F247"; }

.fad.fa-object-ungroup:after {
  content: "\10F248"; }

.fad.fa-octagon:after {
  content: "\10F306"; }

.fad.fa-oil-can:after {
  content: "\10F613"; }

.fad.fa-oil-temp:after {
  content: "\10F614"; }

.fad.fa-om:after {
  content: "\10F679"; }

.fad.fa-omega:after {
  content: "\10F67A"; }

.fad.fa-ornament:after {
  content: "\10F7B8"; }

.fad.fa-otter:after {
  content: "\10F700"; }

.fad.fa-outdent:after {
  content: "\10F03B"; }

.fad.fa-outlet:after {
  content: "\10E01C"; }

.fad.fa-oven:after {
  content: "\10E01D"; }

.fad.fa-overline:after {
  content: "\10F876"; }

.fad.fa-page-break:after {
  content: "\10F877"; }

.fad.fa-pager:after {
  content: "\10F815"; }

.fad.fa-paint-brush:after {
  content: "\10F1FC"; }

.fad.fa-paint-brush-alt:after {
  content: "\10F5A9"; }

.fad.fa-paint-roller:after {
  content: "\10F5AA"; }

.fad.fa-palette:after {
  content: "\10F53F"; }

.fad.fa-pallet:after {
  content: "\10F482"; }

.fad.fa-pallet-alt:after {
  content: "\10F483"; }

.fad.fa-paper-plane:after {
  content: "\10F1D8"; }

.fad.fa-paperclip:after {
  content: "\10F0C6"; }

.fad.fa-parachute-box:after {
  content: "\10F4CD"; }

.fad.fa-paragraph:after {
  content: "\10F1DD"; }

.fad.fa-paragraph-rtl:after {
  content: "\10F878"; }

.fad.fa-parking:after {
  content: "\10F540"; }

.fad.fa-parking-circle:after {
  content: "\10F615"; }

.fad.fa-parking-circle-slash:after {
  content: "\10F616"; }

.fad.fa-parking-slash:after {
  content: "\10F617"; }

.fad.fa-passport:after {
  content: "\10F5AB"; }

.fad.fa-pastafarianism:after {
  content: "\10F67B"; }

.fad.fa-paste:after {
  content: "\10F0EA"; }

.fad.fa-pause:after {
  content: "\10F04C"; }

.fad.fa-pause-circle:after {
  content: "\10F28B"; }

.fad.fa-paw:after {
  content: "\10F1B0"; }

.fad.fa-paw-alt:after {
  content: "\10F701"; }

.fad.fa-paw-claws:after {
  content: "\10F702"; }

.fad.fa-peace:after {
  content: "\10F67C"; }

.fad.fa-pegasus:after {
  content: "\10F703"; }

.fad.fa-pen:after {
  content: "\10F304"; }

.fad.fa-pen-alt:after {
  content: "\10F305"; }

.fad.fa-pen-fancy:after {
  content: "\10F5AC"; }

.fad.fa-pen-nib:after {
  content: "\10F5AD"; }

.fad.fa-pen-square:after {
  content: "\10F14B"; }

.fad.fa-pencil:after {
  content: "\10F040"; }

.fad.fa-pencil-alt:after {
  content: "\10F303"; }

.fad.fa-pencil-paintbrush:after {
  content: "\10F618"; }

.fad.fa-pencil-ruler:after {
  content: "\10F5AE"; }

.fad.fa-pennant:after {
  content: "\10F456"; }

.fad.fa-people-arrows:after {
  content: "\10E068"; }

.fad.fa-people-carry:after {
  content: "\10F4CE"; }

.fad.fa-pepper-hot:after {
  content: "\10F816"; }

.fad.fa-percent:after {
  content: "\10F295"; }

.fad.fa-percentage:after {
  content: "\10F541"; }

.fad.fa-person-booth:after {
  content: "\10F756"; }

.fad.fa-person-carry:after {
  content: "\10F4CF"; }

.fad.fa-person-dolly:after {
  content: "\10F4D0"; }

.fad.fa-person-dolly-empty:after {
  content: "\10F4D1"; }

.fad.fa-person-sign:after {
  content: "\10F757"; }

.fad.fa-phone:after {
  content: "\10F095"; }

.fad.fa-phone-alt:after {
  content: "\10F879"; }

.fad.fa-phone-laptop:after {
  content: "\10F87A"; }

.fad.fa-phone-office:after {
  content: "\10F67D"; }

.fad.fa-phone-plus:after {
  content: "\10F4D2"; }

.fad.fa-phone-rotary:after {
  content: "\10F8D3"; }

.fad.fa-phone-slash:after {
  content: "\10F3DD"; }

.fad.fa-phone-square:after {
  content: "\10F098"; }

.fad.fa-phone-square-alt:after {
  content: "\10F87B"; }

.fad.fa-phone-volume:after {
  content: "\10F2A0"; }

.fad.fa-photo-video:after {
  content: "\10F87C"; }

.fad.fa-pi:after {
  content: "\10F67E"; }

.fad.fa-piano:after {
  content: "\10F8D4"; }

.fad.fa-piano-keyboard:after {
  content: "\10F8D5"; }

.fad.fa-pie:after {
  content: "\10F705"; }

.fad.fa-pig:after {
  content: "\10F706"; }

.fad.fa-piggy-bank:after {
  content: "\10F4D3"; }

.fad.fa-pills:after {
  content: "\10F484"; }

.fad.fa-pizza:after {
  content: "\10F817"; }

.fad.fa-pizza-slice:after {
  content: "\10F818"; }

.fad.fa-place-of-worship:after {
  content: "\10F67F"; }

.fad.fa-plane:after {
  content: "\10F072"; }

.fad.fa-plane-alt:after {
  content: "\10F3DE"; }

.fad.fa-plane-arrival:after {
  content: "\10F5AF"; }

.fad.fa-plane-departure:after {
  content: "\10F5B0"; }

.fad.fa-plane-slash:after {
  content: "\10E069"; }

.fad.fa-planet-moon:after {
  content: "\10E01F"; }

.fad.fa-planet-ringed:after {
  content: "\10E020"; }

.fad.fa-play:after {
  content: "\10F04B"; }

.fad.fa-play-circle:after {
  content: "\10F144"; }

.fad.fa-plug:after {
  content: "\10F1E6"; }

.fad.fa-plus:after {
  content: "\10F067"; }

.fad.fa-plus-circle:after {
  content: "\10F055"; }

.fad.fa-plus-hexagon:after {
  content: "\10F300"; }

.fad.fa-plus-octagon:after {
  content: "\10F301"; }

.fad.fa-plus-square:after {
  content: "\10F0FE"; }

.fad.fa-podcast:after {
  content: "\10F2CE"; }

.fad.fa-podium:after {
  content: "\10F680"; }

.fad.fa-podium-star:after {
  content: "\10F758"; }

.fad.fa-police-box:after {
  content: "\10E021"; }

.fad.fa-poll:after {
  content: "\10F681"; }

.fad.fa-poll-h:after {
  content: "\10F682"; }

.fad.fa-poll-people:after {
  content: "\10F759"; }

.fad.fa-poo:after {
  content: "\10F2FE"; }

.fad.fa-poo-storm:after {
  content: "\10F75A"; }

.fad.fa-poop:after {
  content: "\10F619"; }

.fad.fa-popcorn:after {
  content: "\10F819"; }

.fad.fa-portal-enter:after {
  content: "\10E022"; }

.fad.fa-portal-exit:after {
  content: "\10E023"; }

.fad.fa-portrait:after {
  content: "\10F3E0"; }

.fad.fa-pound-sign:after {
  content: "\10F154"; }

.fad.fa-power-off:after {
  content: "\10F011"; }

.fad.fa-pray:after {
  content: "\10F683"; }

.fad.fa-praying-hands:after {
  content: "\10F684"; }

.fad.fa-prescription:after {
  content: "\10F5B1"; }

.fad.fa-prescription-bottle:after {
  content: "\10F485"; }

.fad.fa-prescription-bottle-alt:after {
  content: "\10F486"; }

.fad.fa-presentation:after {
  content: "\10F685"; }

.fad.fa-print:after {
  content: "\10F02F"; }

.fad.fa-print-search:after {
  content: "\10F81A"; }

.fad.fa-print-slash:after {
  content: "\10F686"; }

.fad.fa-procedures:after {
  content: "\10F487"; }

.fad.fa-project-diagram:after {
  content: "\10F542"; }

.fad.fa-projector:after {
  content: "\10F8D6"; }

.fad.fa-pump-medical:after {
  content: "\10E06A"; }

.fad.fa-pump-soap:after {
  content: "\10E06B"; }

.fad.fa-pumpkin:after {
  content: "\10F707"; }

.fad.fa-puzzle-piece:after {
  content: "\10F12E"; }

.fad.fa-qrcode:after {
  content: "\10F029"; }

.fad.fa-question:after {
  content: "\10F128"; }

.fad.fa-question-circle:after {
  content: "\10F059"; }

.fad.fa-question-square:after {
  content: "\10F2FD"; }

.fad.fa-quidditch:after {
  content: "\10F458"; }

.fad.fa-quote-left:after {
  content: "\10F10D"; }

.fad.fa-quote-right:after {
  content: "\10F10E"; }

.fad.fa-quran:after {
  content: "\10F687"; }

.fad.fa-rabbit:after {
  content: "\10F708"; }

.fad.fa-rabbit-fast:after {
  content: "\10F709"; }

.fad.fa-racquet:after {
  content: "\10F45A"; }

.fad.fa-radar:after {
  content: "\10E024"; }

.fad.fa-radiation:after {
  content: "\10F7B9"; }

.fad.fa-radiation-alt:after {
  content: "\10F7BA"; }

.fad.fa-radio:after {
  content: "\10F8D7"; }

.fad.fa-radio-alt:after {
  content: "\10F8D8"; }

.fad.fa-rainbow:after {
  content: "\10F75B"; }

.fad.fa-raindrops:after {
  content: "\10F75C"; }

.fad.fa-ram:after {
  content: "\10F70A"; }

.fad.fa-ramp-loading:after {
  content: "\10F4D4"; }

.fad.fa-random:after {
  content: "\10F074"; }

.fad.fa-raygun:after {
  content: "\10E025"; }

.fad.fa-receipt:after {
  content: "\10F543"; }

.fad.fa-record-vinyl:after {
  content: "\10F8D9"; }

.fad.fa-rectangle-landscape:after {
  content: "\10F2FA"; }

.fad.fa-rectangle-portrait:after {
  content: "\10F2FB"; }

.fad.fa-rectangle-wide:after {
  content: "\10F2FC"; }

.fad.fa-recycle:after {
  content: "\10F1B8"; }

.fad.fa-redo:after {
  content: "\10F01E"; }

.fad.fa-redo-alt:after {
  content: "\10F2F9"; }

.fad.fa-refrigerator:after {
  content: "\10E026"; }

.fad.fa-registered:after {
  content: "\10F25D"; }

.fad.fa-remove-format:after {
  content: "\10F87D"; }

.fad.fa-repeat:after {
  content: "\10F363"; }

.fad.fa-repeat-1:after {
  content: "\10F365"; }

.fad.fa-repeat-1-alt:after {
  content: "\10F366"; }

.fad.fa-repeat-alt:after {
  content: "\10F364"; }

.fad.fa-reply:after {
  content: "\10F3E5"; }

.fad.fa-reply-all:after {
  content: "\10F122"; }

.fad.fa-republican:after {
  content: "\10F75E"; }

.fad.fa-restroom:after {
  content: "\10F7BD"; }

.fad.fa-retweet:after {
  content: "\10F079"; }

.fad.fa-retweet-alt:after {
  content: "\10F361"; }

.fad.fa-ribbon:after {
  content: "\10F4D6"; }

.fad.fa-ring:after {
  content: "\10F70B"; }

.fad.fa-rings-wedding:after {
  content: "\10F81B"; }

.fad.fa-road:after {
  content: "\10F018"; }

.fad.fa-robot:after {
  content: "\10F544"; }

.fad.fa-rocket:after {
  content: "\10F135"; }

.fad.fa-rocket-launch:after {
  content: "\10E027"; }

.fad.fa-route:after {
  content: "\10F4D7"; }

.fad.fa-route-highway:after {
  content: "\10F61A"; }

.fad.fa-route-interstate:after {
  content: "\10F61B"; }

.fad.fa-router:after {
  content: "\10F8DA"; }

.fad.fa-rss:after {
  content: "\10F09E"; }

.fad.fa-rss-square:after {
  content: "\10F143"; }

.fad.fa-ruble-sign:after {
  content: "\10F158"; }

.fad.fa-ruler:after {
  content: "\10F545"; }

.fad.fa-ruler-combined:after {
  content: "\10F546"; }

.fad.fa-ruler-horizontal:after {
  content: "\10F547"; }

.fad.fa-ruler-triangle:after {
  content: "\10F61C"; }

.fad.fa-ruler-vertical:after {
  content: "\10F548"; }

.fad.fa-running:after {
  content: "\10F70C"; }

.fad.fa-rupee-sign:after {
  content: "\10F156"; }

.fad.fa-rv:after {
  content: "\10F7BE"; }

.fad.fa-sack:after {
  content: "\10F81C"; }

.fad.fa-sack-dollar:after {
  content: "\10F81D"; }

.fad.fa-sad-cry:after {
  content: "\10F5B3"; }

.fad.fa-sad-tear:after {
  content: "\10F5B4"; }

.fad.fa-salad:after {
  content: "\10F81E"; }

.fad.fa-sandwich:after {
  content: "\10F81F"; }

.fad.fa-satellite:after {
  content: "\10F7BF"; }

.fad.fa-satellite-dish:after {
  content: "\10F7C0"; }

.fad.fa-sausage:after {
  content: "\10F820"; }

.fad.fa-save:after {
  content: "\10F0C7"; }

.fad.fa-sax-hot:after {
  content: "\10F8DB"; }

.fad.fa-saxophone:after {
  content: "\10F8DC"; }

.fad.fa-scalpel:after {
  content: "\10F61D"; }

.fad.fa-scalpel-path:after {
  content: "\10F61E"; }

.fad.fa-scanner:after {
  content: "\10F488"; }

.fad.fa-scanner-image:after {
  content: "\10F8F3"; }

.fad.fa-scanner-keyboard:after {
  content: "\10F489"; }

.fad.fa-scanner-touchscreen:after {
  content: "\10F48A"; }

.fad.fa-scarecrow:after {
  content: "\10F70D"; }

.fad.fa-scarf:after {
  content: "\10F7C1"; }

.fad.fa-school:after {
  content: "\10F549"; }

.fad.fa-screwdriver:after {
  content: "\10F54A"; }

.fad.fa-scroll:after {
  content: "\10F70E"; }

.fad.fa-scroll-old:after {
  content: "\10F70F"; }

.fad.fa-scrubber:after {
  content: "\10F2F8"; }

.fad.fa-scythe:after {
  content: "\10F710"; }

.fad.fa-sd-card:after {
  content: "\10F7C2"; }

.fad.fa-search:after {
  content: "\10F002"; }

.fad.fa-search-dollar:after {
  content: "\10F688"; }

.fad.fa-search-location:after {
  content: "\10F689"; }

.fad.fa-search-minus:after {
  content: "\10F010"; }

.fad.fa-search-plus:after {
  content: "\10F00E"; }

.fad.fa-seedling:after {
  content: "\10F4D8"; }

.fad.fa-send-back:after {
  content: "\10F87E"; }

.fad.fa-send-backward:after {
  content: "\10F87F"; }

.fad.fa-sensor:after {
  content: "\10E028"; }

.fad.fa-sensor-alert:after {
  content: "\10E029"; }

.fad.fa-sensor-fire:after {
  content: "\10E02A"; }

.fad.fa-sensor-on:after {
  content: "\10E02B"; }

.fad.fa-sensor-smoke:after {
  content: "\10E02C"; }

.fad.fa-server:after {
  content: "\10F233"; }

.fad.fa-shapes:after {
  content: "\10F61F"; }

.fad.fa-share:after {
  content: "\10F064"; }

.fad.fa-share-all:after {
  content: "\10F367"; }

.fad.fa-share-alt:after {
  content: "\10F1E0"; }

.fad.fa-share-alt-square:after {
  content: "\10F1E1"; }

.fad.fa-share-square:after {
  content: "\10F14D"; }

.fad.fa-sheep:after {
  content: "\10F711"; }

.fad.fa-shekel-sign:after {
  content: "\10F20B"; }

.fad.fa-shield:after {
  content: "\10F132"; }

.fad.fa-shield-alt:after {
  content: "\10F3ED"; }

.fad.fa-shield-check:after {
  content: "\10F2F7"; }

.fad.fa-shield-cross:after {
  content: "\10F712"; }

.fad.fa-shield-virus:after {
  content: "\10E06C"; }

.fad.fa-ship:after {
  content: "\10F21A"; }

.fad.fa-shipping-fast:after {
  content: "\10F48B"; }

.fad.fa-shipping-timed:after {
  content: "\10F48C"; }

.fad.fa-shish-kebab:after {
  content: "\10F821"; }

.fad.fa-shoe-prints:after {
  content: "\10F54B"; }

.fad.fa-shopping-bag:after {
  content: "\10F290"; }

.fad.fa-shopping-basket:after {
  content: "\10F291"; }

.fad.fa-shopping-cart:after {
  content: "\10F07A"; }

.fad.fa-shovel:after {
  content: "\10F713"; }

.fad.fa-shovel-snow:after {
  content: "\10F7C3"; }

.fad.fa-shower:after {
  content: "\10F2CC"; }

.fad.fa-shredder:after {
  content: "\10F68A"; }

.fad.fa-shuttle-van:after {
  content: "\10F5B6"; }

.fad.fa-shuttlecock:after {
  content: "\10F45B"; }

.fad.fa-sickle:after {
  content: "\10F822"; }

.fad.fa-sigma:after {
  content: "\10F68B"; }

.fad.fa-sign:after {
  content: "\10F4D9"; }

.fad.fa-sign-in:after {
  content: "\10F090"; }

.fad.fa-sign-in-alt:after {
  content: "\10F2F6"; }

.fad.fa-sign-language:after {
  content: "\10F2A7"; }

.fad.fa-sign-out:after {
  content: "\10F08B"; }

.fad.fa-sign-out-alt:after {
  content: "\10F2F5"; }

.fad.fa-signal:after {
  content: "\10F012"; }

.fad.fa-signal-1:after {
  content: "\10F68C"; }

.fad.fa-signal-2:after {
  content: "\10F68D"; }

.fad.fa-signal-3:after {
  content: "\10F68E"; }

.fad.fa-signal-4:after {
  content: "\10F68F"; }

.fad.fa-signal-alt:after {
  content: "\10F690"; }

.fad.fa-signal-alt-1:after {
  content: "\10F691"; }

.fad.fa-signal-alt-2:after {
  content: "\10F692"; }

.fad.fa-signal-alt-3:after {
  content: "\10F693"; }

.fad.fa-signal-alt-slash:after {
  content: "\10F694"; }

.fad.fa-signal-slash:after {
  content: "\10F695"; }

.fad.fa-signal-stream:after {
  content: "\10F8DD"; }

.fad.fa-signature:after {
  content: "\10F5B7"; }

.fad.fa-sim-card:after {
  content: "\10F7C4"; }

.fad.fa-sink:after {
  content: "\10E06D"; }

.fad.fa-siren:after {
  content: "\10E02D"; }

.fad.fa-siren-on:after {
  content: "\10E02E"; }

.fad.fa-sitemap:after {
  content: "\10F0E8"; }

.fad.fa-skating:after {
  content: "\10F7C5"; }

.fad.fa-skeleton:after {
  content: "\10F620"; }

.fad.fa-ski-jump:after {
  content: "\10F7C7"; }

.fad.fa-ski-lift:after {
  content: "\10F7C8"; }

.fad.fa-skiing:after {
  content: "\10F7C9"; }

.fad.fa-skiing-nordic:after {
  content: "\10F7CA"; }

.fad.fa-skull:after {
  content: "\10F54C"; }

.fad.fa-skull-cow:after {
  content: "\10F8DE"; }

.fad.fa-skull-crossbones:after {
  content: "\10F714"; }

.fad.fa-slash:after {
  content: "\10F715"; }

.fad.fa-sledding:after {
  content: "\10F7CB"; }

.fad.fa-sleigh:after {
  content: "\10F7CC"; }

.fad.fa-sliders-h:after {
  content: "\10F1DE"; }

.fad.fa-sliders-h-square:after {
  content: "\10F3F0"; }

.fad.fa-sliders-v:after {
  content: "\10F3F1"; }

.fad.fa-sliders-v-square:after {
  content: "\10F3F2"; }

.fad.fa-smile:after {
  content: "\10F118"; }

.fad.fa-smile-beam:after {
  content: "\10F5B8"; }

.fad.fa-smile-plus:after {
  content: "\10F5B9"; }

.fad.fa-smile-wink:after {
  content: "\10F4DA"; }

.fad.fa-smog:after {
  content: "\10F75F"; }

.fad.fa-smoke:after {
  content: "\10F760"; }

.fad.fa-smoking:after {
  content: "\10F48D"; }

.fad.fa-smoking-ban:after {
  content: "\10F54D"; }

.fad.fa-sms:after {
  content: "\10F7CD"; }

.fad.fa-snake:after {
  content: "\10F716"; }

.fad.fa-snooze:after {
  content: "\10F880"; }

.fad.fa-snow-blowing:after {
  content: "\10F761"; }

.fad.fa-snowboarding:after {
  content: "\10F7CE"; }

.fad.fa-snowflake:after {
  content: "\10F2DC"; }

.fad.fa-snowflakes:after {
  content: "\10F7CF"; }

.fad.fa-snowman:after {
  content: "\10F7D0"; }

.fad.fa-snowmobile:after {
  content: "\10F7D1"; }

.fad.fa-snowplow:after {
  content: "\10F7D2"; }

.fad.fa-soap:after {
  content: "\10E06E"; }

.fad.fa-socks:after {
  content: "\10F696"; }

.fad.fa-solar-panel:after {
  content: "\10F5BA"; }

.fad.fa-solar-system:after {
  content: "\10E02F"; }

.fad.fa-sort:after {
  content: "\10F0DC"; }

.fad.fa-sort-alpha-down:after {
  content: "\10F15D"; }

.fad.fa-sort-alpha-down-alt:after {
  content: "\10F881"; }

.fad.fa-sort-alpha-up:after {
  content: "\10F15E"; }

.fad.fa-sort-alpha-up-alt:after {
  content: "\10F882"; }

.fad.fa-sort-alt:after {
  content: "\10F883"; }

.fad.fa-sort-amount-down:after {
  content: "\10F160"; }

.fad.fa-sort-amount-down-alt:after {
  content: "\10F884"; }

.fad.fa-sort-amount-up:after {
  content: "\10F161"; }

.fad.fa-sort-amount-up-alt:after {
  content: "\10F885"; }

.fad.fa-sort-circle:after {
  content: "\10E030"; }

.fad.fa-sort-circle-down:after {
  content: "\10E031"; }

.fad.fa-sort-circle-up:after {
  content: "\10E032"; }

.fad.fa-sort-down:after {
  content: "\10F0DD"; }

.fad.fa-sort-numeric-down:after {
  content: "\10F162"; }

.fad.fa-sort-numeric-down-alt:after {
  content: "\10F886"; }

.fad.fa-sort-numeric-up:after {
  content: "\10F163"; }

.fad.fa-sort-numeric-up-alt:after {
  content: "\10F887"; }

.fad.fa-sort-shapes-down:after {
  content: "\10F888"; }

.fad.fa-sort-shapes-down-alt:after {
  content: "\10F889"; }

.fad.fa-sort-shapes-up:after {
  content: "\10F88A"; }

.fad.fa-sort-shapes-up-alt:after {
  content: "\10F88B"; }

.fad.fa-sort-size-down:after {
  content: "\10F88C"; }

.fad.fa-sort-size-down-alt:after {
  content: "\10F88D"; }

.fad.fa-sort-size-up:after {
  content: "\10F88E"; }

.fad.fa-sort-size-up-alt:after {
  content: "\10F88F"; }

.fad.fa-sort-up:after {
  content: "\10F0DE"; }

.fad.fa-soup:after {
  content: "\10F823"; }

.fad.fa-spa:after {
  content: "\10F5BB"; }

.fad.fa-space-shuttle:after {
  content: "\10F197"; }

.fad.fa-space-station-moon:after {
  content: "\10E033"; }

.fad.fa-space-station-moon-alt:after {
  content: "\10E034"; }

.fad.fa-spade:after {
  content: "\10F2F4"; }

.fad.fa-sparkles:after {
  content: "\10F890"; }

.fad.fa-speaker:after {
  content: "\10F8DF"; }

.fad.fa-speakers:after {
  content: "\10F8E0"; }

.fad.fa-spell-check:after {
  content: "\10F891"; }

.fad.fa-spider:after {
  content: "\10F717"; }

.fad.fa-spider-black-widow:after {
  content: "\10F718"; }

.fad.fa-spider-web:after {
  content: "\10F719"; }

.fad.fa-spinner:after {
  content: "\10F110"; }

.fad.fa-spinner-third:after {
  content: "\10F3F4"; }

.fad.fa-splotch:after {
  content: "\10F5BC"; }

.fad.fa-spray-can:after {
  content: "\10F5BD"; }

.fad.fa-sprinkler:after {
  content: "\10E035"; }

.fad.fa-square:after {
  content: "\10F0C8"; }

.fad.fa-square-full:after {
  content: "\10F45C"; }

.fad.fa-square-root:after {
  content: "\10F697"; }

.fad.fa-square-root-alt:after {
  content: "\10F698"; }

.fad.fa-squirrel:after {
  content: "\10F71A"; }

.fad.fa-staff:after {
  content: "\10F71B"; }

.fad.fa-stamp:after {
  content: "\10F5BF"; }

.fad.fa-star:after {
  content: "\10F005"; }

.fad.fa-star-and-crescent:after {
  content: "\10F699"; }

.fad.fa-star-christmas:after {
  content: "\10F7D4"; }

.fad.fa-star-exclamation:after {
  content: "\10F2F3"; }

.fad.fa-star-half:after {
  content: "\10F089"; }

.fad.fa-star-half-alt:after {
  content: "\10F5C0"; }

.fad.fa-star-of-david:after {
  content: "\10F69A"; }

.fad.fa-star-of-life:after {
  content: "\10F621"; }

.fad.fa-star-shooting:after {
  content: "\10E036"; }

.fad.fa-starfighter:after {
  content: "\10E037"; }

.fad.fa-starfighter-alt:after {
  content: "\10E038"; }

.fad.fa-stars:after {
  content: "\10F762"; }

.fad.fa-starship:after {
  content: "\10E039"; }

.fad.fa-starship-freighter:after {
  content: "\10E03A"; }

.fad.fa-steak:after {
  content: "\10F824"; }

.fad.fa-steering-wheel:after {
  content: "\10F622"; }

.fad.fa-step-backward:after {
  content: "\10F048"; }

.fad.fa-step-forward:after {
  content: "\10F051"; }

.fad.fa-stethoscope:after {
  content: "\10F0F1"; }

.fad.fa-sticky-note:after {
  content: "\10F249"; }

.fad.fa-stocking:after {
  content: "\10F7D5"; }

.fad.fa-stomach:after {
  content: "\10F623"; }

.fad.fa-stop:after {
  content: "\10F04D"; }

.fad.fa-stop-circle:after {
  content: "\10F28D"; }

.fad.fa-stopwatch:after {
  content: "\10F2F2"; }

.fad.fa-stopwatch-20:after {
  content: "\10E06F"; }

.fad.fa-store:after {
  content: "\10F54E"; }

.fad.fa-store-alt:after {
  content: "\10F54F"; }

.fad.fa-store-alt-slash:after {
  content: "\10E070"; }

.fad.fa-store-slash:after {
  content: "\10E071"; }

.fad.fa-stream:after {
  content: "\10F550"; }

.fad.fa-street-view:after {
  content: "\10F21D"; }

.fad.fa-stretcher:after {
  content: "\10F825"; }

.fad.fa-strikethrough:after {
  content: "\10F0CC"; }

.fad.fa-stroopwafel:after {
  content: "\10F551"; }

.fad.fa-subscript:after {
  content: "\10F12C"; }

.fad.fa-subway:after {
  content: "\10F239"; }

.fad.fa-suitcase:after {
  content: "\10F0F2"; }

.fad.fa-suitcase-rolling:after {
  content: "\10F5C1"; }

.fad.fa-sun:after {
  content: "\10F185"; }

.fad.fa-sun-cloud:after {
  content: "\10F763"; }

.fad.fa-sun-dust:after {
  content: "\10F764"; }

.fad.fa-sun-haze:after {
  content: "\10F765"; }

.fad.fa-sunglasses:after {
  content: "\10F892"; }

.fad.fa-sunrise:after {
  content: "\10F766"; }

.fad.fa-sunset:after {
  content: "\10F767"; }

.fad.fa-superscript:after {
  content: "\10F12B"; }

.fad.fa-surprise:after {
  content: "\10F5C2"; }

.fad.fa-swatchbook:after {
  content: "\10F5C3"; }

.fad.fa-swimmer:after {
  content: "\10F5C4"; }

.fad.fa-swimming-pool:after {
  content: "\10F5C5"; }

.fad.fa-sword:after {
  content: "\10F71C"; }

.fad.fa-sword-laser:after {
  content: "\10E03B"; }

.fad.fa-sword-laser-alt:after {
  content: "\10E03C"; }

.fad.fa-swords:after {
  content: "\10F71D"; }

.fad.fa-swords-laser:after {
  content: "\10E03D"; }

.fad.fa-synagogue:after {
  content: "\10F69B"; }

.fad.fa-sync:after {
  content: "\10F021"; }

.fad.fa-sync-alt:after {
  content: "\10F2F1"; }

.fad.fa-syringe:after {
  content: "\10F48E"; }

.fad.fa-table:after {
  content: "\10F0CE"; }

.fad.fa-table-tennis:after {
  content: "\10F45D"; }

.fad.fa-tablet:after {
  content: "\10F10A"; }

.fad.fa-tablet-alt:after {
  content: "\10F3FA"; }

.fad.fa-tablet-android:after {
  content: "\10F3FB"; }

.fad.fa-tablet-android-alt:after {
  content: "\10F3FC"; }

.fad.fa-tablet-rugged:after {
  content: "\10F48F"; }

.fad.fa-tablets:after {
  content: "\10F490"; }

.fad.fa-tachometer:after {
  content: "\10F0E4"; }

.fad.fa-tachometer-alt:after {
  content: "\10F3FD"; }

.fad.fa-tachometer-alt-average:after {
  content: "\10F624"; }

.fad.fa-tachometer-alt-fast:after {
  content: "\10F625"; }

.fad.fa-tachometer-alt-fastest:after {
  content: "\10F626"; }

.fad.fa-tachometer-alt-slow:after {
  content: "\10F627"; }

.fad.fa-tachometer-alt-slowest:after {
  content: "\10F628"; }

.fad.fa-tachometer-average:after {
  content: "\10F629"; }

.fad.fa-tachometer-fast:after {
  content: "\10F62A"; }

.fad.fa-tachometer-fastest:after {
  content: "\10F62B"; }

.fad.fa-tachometer-slow:after {
  content: "\10F62C"; }

.fad.fa-tachometer-slowest:after {
  content: "\10F62D"; }

.fad.fa-taco:after {
  content: "\10F826"; }

.fad.fa-tag:after {
  content: "\10F02B"; }

.fad.fa-tags:after {
  content: "\10F02C"; }

.fad.fa-tally:after {
  content: "\10F69C"; }

.fad.fa-tanakh:after {
  content: "\10F827"; }

.fad.fa-tape:after {
  content: "\10F4DB"; }

.fad.fa-tasks:after {
  content: "\10F0AE"; }

.fad.fa-tasks-alt:after {
  content: "\10F828"; }

.fad.fa-taxi:after {
  content: "\10F1BA"; }

.fad.fa-teeth:after {
  content: "\10F62E"; }

.fad.fa-teeth-open:after {
  content: "\10F62F"; }

.fad.fa-telescope:after {
  content: "\10E03E"; }

.fad.fa-temperature-down:after {
  content: "\10E03F"; }

.fad.fa-temperature-frigid:after {
  content: "\10F768"; }

.fad.fa-temperature-high:after {
  content: "\10F769"; }

.fad.fa-temperature-hot:after {
  content: "\10F76A"; }

.fad.fa-temperature-low:after {
  content: "\10F76B"; }

.fad.fa-temperature-up:after {
  content: "\10E040"; }

.fad.fa-tenge:after {
  content: "\10F7D7"; }

.fad.fa-tennis-ball:after {
  content: "\10F45E"; }

.fad.fa-terminal:after {
  content: "\10F120"; }

.fad.fa-text:after {
  content: "\10F893"; }

.fad.fa-text-height:after {
  content: "\10F034"; }

.fad.fa-text-size:after {
  content: "\10F894"; }

.fad.fa-text-width:after {
  content: "\10F035"; }

.fad.fa-th:after {
  content: "\10F00A"; }

.fad.fa-th-large:after {
  content: "\10F009"; }

.fad.fa-th-list:after {
  content: "\10F00B"; }

.fad.fa-theater-masks:after {
  content: "\10F630"; }

.fad.fa-thermometer:after {
  content: "\10F491"; }

.fad.fa-thermometer-empty:after {
  content: "\10F2CB"; }

.fad.fa-thermometer-full:after {
  content: "\10F2C7"; }

.fad.fa-thermometer-half:after {
  content: "\10F2C9"; }

.fad.fa-thermometer-quarter:after {
  content: "\10F2CA"; }

.fad.fa-thermometer-three-quarters:after {
  content: "\10F2C8"; }

.fad.fa-theta:after {
  content: "\10F69E"; }

.fad.fa-thumbs-down:after {
  content: "\10F165"; }

.fad.fa-thumbs-up:after {
  content: "\10F164"; }

.fad.fa-thumbtack:after {
  content: "\10F08D"; }

.fad.fa-thunderstorm:after {
  content: "\10F76C"; }

.fad.fa-thunderstorm-moon:after {
  content: "\10F76D"; }

.fad.fa-thunderstorm-sun:after {
  content: "\10F76E"; }

.fad.fa-ticket:after {
  content: "\10F145"; }

.fad.fa-ticket-alt:after {
  content: "\10F3FF"; }

.fad.fa-tilde:after {
  content: "\10F69F"; }

.fad.fa-times:after {
  content: "\10F00D"; }

.fad.fa-times-circle:after {
  content: "\10F057"; }

.fad.fa-times-hexagon:after {
  content: "\10F2EE"; }

.fad.fa-times-octagon:after {
  content: "\10F2F0"; }

.fad.fa-times-square:after {
  content: "\10F2D3"; }

.fad.fa-tint:after {
  content: "\10F043"; }

.fad.fa-tint-slash:after {
  content: "\10F5C7"; }

.fad.fa-tire:after {
  content: "\10F631"; }

.fad.fa-tire-flat:after {
  content: "\10F632"; }

.fad.fa-tire-pressure-warning:after {
  content: "\10F633"; }

.fad.fa-tire-rugged:after {
  content: "\10F634"; }

.fad.fa-tired:after {
  content: "\10F5C8"; }

.fad.fa-toggle-off:after {
  content: "\10F204"; }

.fad.fa-toggle-on:after {
  content: "\10F205"; }

.fad.fa-toilet:after {
  content: "\10F7D8"; }

.fad.fa-toilet-paper:after {
  content: "\10F71E"; }

.fad.fa-toilet-paper-alt:after {
  content: "\10F71F"; }

.fad.fa-toilet-paper-slash:after {
  content: "\10E072"; }

.fad.fa-tombstone:after {
  content: "\10F720"; }

.fad.fa-tombstone-alt:after {
  content: "\10F721"; }

.fad.fa-toolbox:after {
  content: "\10F552"; }

.fad.fa-tools:after {
  content: "\10F7D9"; }

.fad.fa-tooth:after {
  content: "\10F5C9"; }

.fad.fa-toothbrush:after {
  content: "\10F635"; }

.fad.fa-torah:after {
  content: "\10F6A0"; }

.fad.fa-torii-gate:after {
  content: "\10F6A1"; }

.fad.fa-tornado:after {
  content: "\10F76F"; }

.fad.fa-tractor:after {
  content: "\10F722"; }

.fad.fa-trademark:after {
  content: "\10F25C"; }

.fad.fa-traffic-cone:after {
  content: "\10F636"; }

.fad.fa-traffic-light:after {
  content: "\10F637"; }

.fad.fa-traffic-light-go:after {
  content: "\10F638"; }

.fad.fa-traffic-light-slow:after {
  content: "\10F639"; }

.fad.fa-traffic-light-stop:after {
  content: "\10F63A"; }

.fad.fa-trailer:after {
  content: "\10E041"; }

.fad.fa-train:after {
  content: "\10F238"; }

.fad.fa-tram:after {
  content: "\10F7DA"; }

.fad.fa-transgender:after {
  content: "\10F224"; }

.fad.fa-transgender-alt:after {
  content: "\10F225"; }

.fad.fa-transporter:after {
  content: "\10E042"; }

.fad.fa-transporter-1:after {
  content: "\10E043"; }

.fad.fa-transporter-2:after {
  content: "\10E044"; }

.fad.fa-transporter-3:after {
  content: "\10E045"; }

.fad.fa-transporter-empty:after {
  content: "\10E046"; }

.fad.fa-trash:after {
  content: "\10F1F8"; }

.fad.fa-trash-alt:after {
  content: "\10F2ED"; }

.fad.fa-trash-restore:after {
  content: "\10F829"; }

.fad.fa-trash-restore-alt:after {
  content: "\10F82A"; }

.fad.fa-trash-undo:after {
  content: "\10F895"; }

.fad.fa-trash-undo-alt:after {
  content: "\10F896"; }

.fad.fa-treasure-chest:after {
  content: "\10F723"; }

.fad.fa-tree:after {
  content: "\10F1BB"; }

.fad.fa-tree-alt:after {
  content: "\10F400"; }

.fad.fa-tree-christmas:after {
  content: "\10F7DB"; }

.fad.fa-tree-decorated:after {
  content: "\10F7DC"; }

.fad.fa-tree-large:after {
  content: "\10F7DD"; }

.fad.fa-tree-palm:after {
  content: "\10F82B"; }

.fad.fa-trees:after {
  content: "\10F724"; }

.fad.fa-triangle:after {
  content: "\10F2EC"; }

.fad.fa-triangle-music:after {
  content: "\10F8E2"; }

.fad.fa-trophy:after {
  content: "\10F091"; }

.fad.fa-trophy-alt:after {
  content: "\10F2EB"; }

.fad.fa-truck:after {
  content: "\10F0D1"; }

.fad.fa-truck-container:after {
  content: "\10F4DC"; }

.fad.fa-truck-couch:after {
  content: "\10F4DD"; }

.fad.fa-truck-loading:after {
  content: "\10F4DE"; }

.fad.fa-truck-monster:after {
  content: "\10F63B"; }

.fad.fa-truck-moving:after {
  content: "\10F4DF"; }

.fad.fa-truck-pickup:after {
  content: "\10F63C"; }

.fad.fa-truck-plow:after {
  content: "\10F7DE"; }

.fad.fa-truck-ramp:after {
  content: "\10F4E0"; }

.fad.fa-trumpet:after {
  content: "\10F8E3"; }

.fad.fa-tshirt:after {
  content: "\10F553"; }

.fad.fa-tty:after {
  content: "\10F1E4"; }

.fad.fa-turkey:after {
  content: "\10F725"; }

.fad.fa-turntable:after {
  content: "\10F8E4"; }

.fad.fa-turtle:after {
  content: "\10F726"; }

.fad.fa-tv:after {
  content: "\10F26C"; }

.fad.fa-tv-alt:after {
  content: "\10F8E5"; }

.fad.fa-tv-music:after {
  content: "\10F8E6"; }

.fad.fa-tv-retro:after {
  content: "\10F401"; }

.fad.fa-typewriter:after {
  content: "\10F8E7"; }

.fad.fa-ufo:after {
  content: "\10E047"; }

.fad.fa-ufo-beam:after {
  content: "\10E048"; }

.fad.fa-umbrella:after {
  content: "\10F0E9"; }

.fad.fa-umbrella-beach:after {
  content: "\10F5CA"; }

.fad.fa-underline:after {
  content: "\10F0CD"; }

.fad.fa-undo:after {
  content: "\10F0E2"; }

.fad.fa-undo-alt:after {
  content: "\10F2EA"; }

.fad.fa-unicorn:after {
  content: "\10F727"; }

.fad.fa-union:after {
  content: "\10F6A2"; }

.fad.fa-universal-access:after {
  content: "\10F29A"; }

.fad.fa-university:after {
  content: "\10F19C"; }

.fad.fa-unlink:after {
  content: "\10F127"; }

.fad.fa-unlock:after {
  content: "\10F09C"; }

.fad.fa-unlock-alt:after {
  content: "\10F13E"; }

.fad.fa-upload:after {
  content: "\10F093"; }

.fad.fa-usb-drive:after {
  content: "\10F8E9"; }

.fad.fa-usd-circle:after {
  content: "\10F2E8"; }

.fad.fa-usd-square:after {
  content: "\10F2E9"; }

.fad.fa-user:after {
  content: "\10F007"; }

.fad.fa-user-alien:after {
  content: "\10E04A"; }

.fad.fa-user-alt:after {
  content: "\10F406"; }

.fad.fa-user-alt-slash:after {
  content: "\10F4FA"; }

.fad.fa-user-astronaut:after {
  content: "\10F4FB"; }

.fad.fa-user-chart:after {
  content: "\10F6A3"; }

.fad.fa-user-check:after {
  content: "\10F4FC"; }

.fad.fa-user-circle:after {
  content: "\10F2BD"; }

.fad.fa-user-clock:after {
  content: "\10F4FD"; }

.fad.fa-user-cog:after {
  content: "\10F4FE"; }

.fad.fa-user-cowboy:after {
  content: "\10F8EA"; }

.fad.fa-user-crown:after {
  content: "\10F6A4"; }

.fad.fa-user-edit:after {
  content: "\10F4FF"; }

.fad.fa-user-friends:after {
  content: "\10F500"; }

.fad.fa-user-graduate:after {
  content: "\10F501"; }

.fad.fa-user-hard-hat:after {
  content: "\10F82C"; }

.fad.fa-user-headset:after {
  content: "\10F82D"; }

.fad.fa-user-injured:after {
  content: "\10F728"; }

.fad.fa-user-lock:after {
  content: "\10F502"; }

.fad.fa-user-md:after {
  content: "\10F0F0"; }

.fad.fa-user-md-chat:after {
  content: "\10F82E"; }

.fad.fa-user-minus:after {
  content: "\10F503"; }

.fad.fa-user-music:after {
  content: "\10F8EB"; }

.fad.fa-user-ninja:after {
  content: "\10F504"; }

.fad.fa-user-nurse:after {
  content: "\10F82F"; }

.fad.fa-user-plus:after {
  content: "\10F234"; }

.fad.fa-user-robot:after {
  content: "\10E04B"; }

.fad.fa-user-secret:after {
  content: "\10F21B"; }

.fad.fa-user-shield:after {
  content: "\10F505"; }

.fad.fa-user-slash:after {
  content: "\10F506"; }

.fad.fa-user-tag:after {
  content: "\10F507"; }

.fad.fa-user-tie:after {
  content: "\10F508"; }

.fad.fa-user-times:after {
  content: "\10F235"; }

.fad.fa-user-unlock:after {
  content: "\10E058"; }

.fad.fa-user-visor:after {
  content: "\10E04C"; }

.fad.fa-users:after {
  content: "\10F0C0"; }

.fad.fa-users-class:after {
  content: "\10F63D"; }

.fad.fa-users-cog:after {
  content: "\10F509"; }

.fad.fa-users-crown:after {
  content: "\10F6A5"; }

.fad.fa-users-medical:after {
  content: "\10F830"; }

.fad.fa-users-slash:after {
  content: "\10E073"; }

.fad.fa-utensil-fork:after {
  content: "\10F2E3"; }

.fad.fa-utensil-knife:after {
  content: "\10F2E4"; }

.fad.fa-utensil-spoon:after {
  content: "\10F2E5"; }

.fad.fa-utensils:after {
  content: "\10F2E7"; }

.fad.fa-utensils-alt:after {
  content: "\10F2E6"; }

.fad.fa-vacuum:after {
  content: "\10E04D"; }

.fad.fa-vacuum-robot:after {
  content: "\10E04E"; }

.fad.fa-value-absolute:after {
  content: "\10F6A6"; }

.fad.fa-vector-square:after {
  content: "\10F5CB"; }

.fad.fa-venus:after {
  content: "\10F221"; }

.fad.fa-venus-double:after {
  content: "\10F226"; }

.fad.fa-venus-mars:after {
  content: "\10F228"; }

.fad.fa-vest:after {
  content: "\10E085"; }

.fad.fa-vest-patches:after {
  content: "\10E086"; }

.fad.fa-vhs:after {
  content: "\10F8EC"; }

.fad.fa-vial:after {
  content: "\10F492"; }

.fad.fa-vials:after {
  content: "\10F493"; }

.fad.fa-video:after {
  content: "\10F03D"; }

.fad.fa-video-plus:after {
  content: "\10F4E1"; }

.fad.fa-video-slash:after {
  content: "\10F4E2"; }

.fad.fa-vihara:after {
  content: "\10F6A7"; }

.fad.fa-violin:after {
  content: "\10F8ED"; }

.fad.fa-virus:after {
  content: "\10E074"; }

.fad.fa-virus-slash:after {
  content: "\10E075"; }

.fad.fa-viruses:after {
  content: "\10E076"; }

.fad.fa-voicemail:after {
  content: "\10F897"; }

.fad.fa-volcano:after {
  content: "\10F770"; }

.fad.fa-volleyball-ball:after {
  content: "\10F45F"; }

.fad.fa-volume:after {
  content: "\10F6A8"; }

.fad.fa-volume-down:after {
  content: "\10F027"; }

.fad.fa-volume-mute:after {
  content: "\10F6A9"; }

.fad.fa-volume-off:after {
  content: "\10F026"; }

.fad.fa-volume-slash:after {
  content: "\10F2E2"; }

.fad.fa-volume-up:after {
  content: "\10F028"; }

.fad.fa-vote-nay:after {
  content: "\10F771"; }

.fad.fa-vote-yea:after {
  content: "\10F772"; }

.fad.fa-vr-cardboard:after {
  content: "\10F729"; }

.fad.fa-wagon-covered:after {
  content: "\10F8EE"; }

.fad.fa-walker:after {
  content: "\10F831"; }

.fad.fa-walkie-talkie:after {
  content: "\10F8EF"; }

.fad.fa-walking:after {
  content: "\10F554"; }

.fad.fa-wallet:after {
  content: "\10F555"; }

.fad.fa-wand:after {
  content: "\10F72A"; }

.fad.fa-wand-magic:after {
  content: "\10F72B"; }

.fad.fa-warehouse:after {
  content: "\10F494"; }

.fad.fa-warehouse-alt:after {
  content: "\10F495"; }

.fad.fa-washer:after {
  content: "\10F898"; }

.fad.fa-watch:after {
  content: "\10F2E1"; }

.fad.fa-watch-calculator:after {
  content: "\10F8F0"; }

.fad.fa-watch-fitness:after {
  content: "\10F63E"; }

.fad.fa-water:after {
  content: "\10F773"; }

.fad.fa-water-lower:after {
  content: "\10F774"; }

.fad.fa-water-rise:after {
  content: "\10F775"; }

.fad.fa-wave-sine:after {
  content: "\10F899"; }

.fad.fa-wave-square:after {
  content: "\10F83E"; }

.fad.fa-wave-triangle:after {
  content: "\10F89A"; }

.fad.fa-waveform:after {
  content: "\10F8F1"; }

.fad.fa-waveform-path:after {
  content: "\10F8F2"; }

.fad.fa-webcam:after {
  content: "\10F832"; }

.fad.fa-webcam-slash:after {
  content: "\10F833"; }

.fad.fa-weight:after {
  content: "\10F496"; }

.fad.fa-weight-hanging:after {
  content: "\10F5CD"; }

.fad.fa-whale:after {
  content: "\10F72C"; }

.fad.fa-wheat:after {
  content: "\10F72D"; }

.fad.fa-wheelchair:after {
  content: "\10F193"; }

.fad.fa-whistle:after {
  content: "\10F460"; }

.fad.fa-wifi:after {
  content: "\10F1EB"; }

.fad.fa-wifi-1:after {
  content: "\10F6AA"; }

.fad.fa-wifi-2:after {
  content: "\10F6AB"; }

.fad.fa-wifi-slash:after {
  content: "\10F6AC"; }

.fad.fa-wind:after {
  content: "\10F72E"; }

.fad.fa-wind-turbine:after {
  content: "\10F89B"; }

.fad.fa-wind-warning:after {
  content: "\10F776"; }

.fad.fa-window:after {
  content: "\10F40E"; }

.fad.fa-window-alt:after {
  content: "\10F40F"; }

.fad.fa-window-close:after {
  content: "\10F410"; }

.fad.fa-window-frame:after {
  content: "\10E04F"; }

.fad.fa-window-frame-open:after {
  content: "\10E050"; }

.fad.fa-window-maximize:after {
  content: "\10F2D0"; }

.fad.fa-window-minimize:after {
  content: "\10F2D1"; }

.fad.fa-window-restore:after {
  content: "\10F2D2"; }

.fad.fa-windsock:after {
  content: "\10F777"; }

.fad.fa-wine-bottle:after {
  content: "\10F72F"; }

.fad.fa-wine-glass:after {
  content: "\10F4E3"; }

.fad.fa-wine-glass-alt:after {
  content: "\10F5CE"; }

.fad.fa-won-sign:after {
  content: "\10F159"; }

.fad.fa-wreath:after {
  content: "\10F7E2"; }

.fad.fa-wrench:after {
  content: "\10F0AD"; }

.fad.fa-x-ray:after {
  content: "\10F497"; }

.fad.fa-yen-sign:after {
  content: "\10F157"; }

.fad.fa-yin-yang:after {
  content: "\10F6AD"; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(/static/media/fa-light-300.8a4a5bd9.eot);
  src: url(/static/media/fa-light-300.8a4a5bd9.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-light-300.b3344966.woff2) format("woff2"), url(/static/media/fa-light-300.5ce1b436.woff) format("woff"), url(/static/media/fa-light-300.1c697cb0.ttf) format("truetype"), url(/static/media/fa-light-300.b3d910b7.svg#fontawesome) format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/static/media/fa-regular-400.232215c7.eot);
  src: url(/static/media/fa-regular-400.232215c7.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-regular-400.a3d7d331.woff2) format("woff2"), url(/static/media/fa-regular-400.fb332093.woff) format("woff"), url(/static/media/fa-regular-400.9c6f2a1b.ttf) format("truetype"), url(/static/media/fa-regular-400.e70221c0.svg#fontawesome) format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(/static/media/fa-solid-900.40e46698.eot);
  src: url(/static/media/fa-solid-900.40e46698.eot?#iefix) format("embedded-opentype"), url(/static/media/fa-solid-900.dbf1fc91.woff2) format("woff2"), url(/static/media/fa-solid-900.9822daf6.woff) format("woff"), url(/static/media/fa-solid-900.149d83e1.ttf) format("truetype"), url(/static/media/fa-solid-900.601eb47a.svg#fontawesome) format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

